import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import { useHistory } from 'react-router-dom'
import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import getMapOptions from './getMapOptions'
import MapBordercolor from '../../utils/MapBordercolor'
function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}
const MapPharma = ({
  toolTipFormatter,
  activeCbsasData,
  statesData,
  cbsaData,
  mapOptions,
  showStateLabels,
  showCbsaLabels,
  enableTooltip,
  enableMapNavigation,
  enableMouseTracking,
  mapHeight = '70vh',
  mapWidth = '',
  mapTitleDisable,
  mapType,
  enableButtons = false,
  zoomLocation = '',
  chartRef,
  mapDataType,
  JsonData,
  stateJson,
  dashboardType,
}) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }
  const { displayDate } = useGeoState()

  const history = useHistory()
  let mapActiveCbsaData = null
  let mapStatesData = null
  let mapCbsaData = null
  let mapMsaData = null

  if (
    mapType === 'RSVMAP' ||
    mapType === 'RSVMAPMSA' ||
    mapType === 'RSVMAPCDO'
  ) {
    const statesDataForDisplayDate = Object.entries(statesData[displayDate])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1][mapOptions.value],
        state: state[0],
        //borderColor: 'white',
        borderColor: MapBordercolor(state[1]['forecast_level']),
        ...state[1],
      }
    })
  }
  if (
    (mapType === 'RSVMAPMSA' && cbsaData && !isEmptyObject(cbsaData)) ||
    (mapType === 'RSVMAPCDO' && cbsaData && !isEmptyObject(cbsaData))
  ) {
    const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])
    mapCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
      return {
        value: cbsa[1][mapOptions.value],
        state: cbsa[0].toString(),
        borderColor: MapBordercolor(cbsa[1]['forecast_level']),
        ...cbsa[1],
      }
    })
    mapActiveCbsaData = activeCbsasData.map((cbsa) => {
      return cbsa.toString()
    })
  }

  let title = mapOptions.mapTitle
  if (!(title === null || title === undefined)) {
    title = ''
  }

  const graphOptions = getMapOptions(
    mapOptions.colorAxis,
    mapOptions.legend,
    toolTipFormatter,
    mapActiveCbsaData,
    mapCbsaData,
    mapStatesData,
    mapMsaData,
    history,
    mapType,
    enableButtons,
    zoomLocation,
    mapDataType,
    JsonData,
    stateJson,
    dashboardType,
    title,
    mapOptions.mapSubTitle,
    showStateLabels,
    showCbsaLabels,
    enableTooltip,
    enableMapNavigation,
    enableMouseTracking
  )

  return mapOptions ? (
    <HighchartsReact
      containerProps={{
        style: {
          height: mapHeight,
          width: mapWidth,
          margin:'0 auto',
          position: 'relative',
          display: 'flex',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
      ref={chartRef}
    />
  ) : (
    <Spinner />
  )
}

export default MapPharma
