import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Tabs from '../../../components/Tabs'
import GeoProvider from '../../../components/GeoProvider'

import ModelInformation from './ModelInformation'

import ModelAccuracy from './ModelAccuracy'
import Spinner from '../../../components/Loader/Spinner'
// import dataDisplayOptions from './FluMap/dataDisplayOptions'
const ModelTabs = () => {
  const tabIndices = React.useMemo(() => ['#information', '#accuracy'], [])
  const [selectedTab, setSelectedTab] = React.useState(0)

  const history = useHistory()
  const location = useLocation()

  const onLoadTabs = React.useCallback(() => {
    for (const key in tabIndices) {
      if (location.hash === tabIndices[key]) setSelectedTab(+key)
    }
  }, [location.hash, tabIndices])

  const tabsChangeHandler = (index) => {
    setSelectedTab(index)
    history.push({ hash: tabIndices[index] })
  }

  React.useEffect(() => {
    onLoadTabs()
  }, [onLoadTabs])

  return (
    <Tabs selectedIndex={selectedTab} onChange={tabsChangeHandler}>
      <Tabs.Panel label="Model Information">
        {tabIndices[selectedTab] === '#information' ? (
          <ModelInformation />
        ) : (
          
          <Spinner/>
         
        )}

        {/* <GeoProvider
          startDate={startDate}
          endDate={endDate}
          displayDate={displayDate}
          mapOptions={dataDisplayOptions[0]['options'][0]}
        >
          <ModelInformation />
        </GeoProvider> */}
      </Tabs.Panel>
      <Tabs.Panel label="Model Accuracy">
      {tabIndices[selectedTab] === '#accuracy' ? (
          <ModelAccuracy />
        ) : (          
          <Spinner/>
         
        )}
        
      </Tabs.Panel>
    </Tabs>
  )
}

export default ModelTabs
