import React from 'react'
import Popover from '@optum/react-popover'
import Icon from '../Icon'
import IconInfo from '../IconInfo'

import '@optum/react-popover/styles.css'
import styles from './Tooltip.module.scss'

const Tooltip = ({
  children = null,
  placement = 'right',
  showIcon = true,
  iconHeight = '20',
  iconWidth = '20',
}) => {
  const [isVisible, setVisible] = React.useState(false)
  return (
    <Popover
      caret
      placement={placement}
      setVisible={setVisible}
      spacing={4}
      target={
        showIcon && (
          <IconInfo
            className={styles.icon}
            height={iconHeight}
            width={iconWidth}
            type="info_filled"
          />
        )
      }
      valign="top"
      visible={isVisible}
    >
      {children}
    </Popover>
  )
}

export default Tooltip
