import moment from 'moment'
function toolTipFormatter() {
  const date = moment(this.x).format('MMM D, YYYY')

  var toolTipHTML = `<h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px;">
    ${date}</h4>`

  this.points.forEach((point) => {
    toolTipHTML += `<h5 style="background-color: ${
      point.color
    }; color: #fff; padding: 5px;">${point.series.name.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    )}</h5>`

    toolTipHTML += `<h6 style="padding: 0 5px;">${point.y.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    )}</h6>`
  })

  return toolTipHTML
}

export default toolTipFormatter
