function toolTipFormatter() {
 
  // const pointName =
  // this.point.properties.cbsa_name === undefined
  //   ? ''
  //   : `/${this.point.properties.cbsa_name}`
  //    ${this.point.name.toUpperCase()}${pointName}
  // return `<div style="width:110%;background-color:transparent;">
  //   <div style="width:90%;
  //   background-color:transparent;
  //   color:#7d7d7d;padding-left:10px;
  //   padding-top:10px;padding-right:5px;
  //   display:inline;">Vulnerability Population </div>

  //   <div style="padding-top:10px;display: inline-block;padding-right:10px;">
  //   <span style="border-radius:50%;
  //   background-color:${this.color};
  //   display: inline-block;
  //   width: 10px;
  //   height: 10px"></span>&nbsp;
  //   </div>

  //   <div style="width:110%;background-color:back;color:black;
  //   font-weight:bold;font-size:14px;padding-right:10px;">
  //   <div style="width:90%;
  //   background-color:transparent;
  //   color:black;padding-left:10px;
  //   padding-top:10px;padding-right:5px;
  //   display:inline;">${this.point.name} </div>

  //   <div style="width:90%;padding-left: 10px;">
  //   <span style="color:black;
  //               font-weight:bold;font-size:14px;padding-right:10px;">
  // ${
  //   this.point.value === 0
  //     ? 'No Data'
  //     : this.point.value.toLocaleString(undefined, {
  //         maximumFractionDigits: 2,
  //       }) + '%'
  // }
  //               </span></div>
  return `<div style="width:110%;background-color:transparent;">
  <div style="width:90%;font-size:14px;
  background-color:transparent;
  color:#424242;padding-left:10px;
  padding-top:10px;padding-right:5px;
  display:inline;">${this.point.name.toUpperCase()} VULNERABLE POPULATION </div>

  <div style="padding-top:10px;display: inline-block;padding-right:10px;">
  <span style="border-radius:50%;
  background-color:${this.color}; 
  display: inline-block;
  width: 10px;
  height: 10px"></span>&nbsp;
  </div>

  <div style="width:110%;background-color:back;color:black;
  font-size:14px;padding-right:10px;">
  <div style="width:90%;
  background-color:transparent;
  color:black;padding-left:10px;font-weight:bold;
  padding-top:10px;padding-right:5px;
  display:inline;">             
   ${
     this.point.value === 0
       ? 'No Data'
       : this.point.value.toLocaleString(undefined, {
           maximumFractionDigits: 2,
         }) + '%'
   }</div>
   
   
    <div style="width:90%;
    padding-left:10px;padding-top:2px;
    font-size:12px;padding-right:10px;">
    </div>
    </div>`
}

export default toolTipFormatter
