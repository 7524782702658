import React from 'react'
import GeoProvider from '../../../components/GeoProvider'
import dataDisplayOptions from './dataDisplayOptions'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import styles from './FluSurveillance.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import { startDate, endDate, lastweekEndDate } from '../dates'
import FluSurveillance from './FluSurveillance'

const Surveillance = () => {
  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Flu Surveillance',
    indicator_name: 'Flu Surveillance',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          {/* <DefaultBreadcrumbs /> */}
          <div className={styles.body}>
            <GeoProvider
              lastweekEndDate={lastweekEndDate}
              indicatorName={dataDisplayOptions[0].value}
              locationName={'NAT'}
              fromDate={new Date(startDate)}
              toDate={new Date(endDate)}
              regionSelectedName={'NAT'}
              locationCode={'NAT'}
              locationType={'NAT'}
            >
              <FluSurveillance />
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Surveillance
