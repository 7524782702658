import React, { useState, useEffect } from 'react'

import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import LinkButton from '../LinkButton'
import ButtonList from '../ButtonList'

import TimelineSlider from './TimelineSlider'

import styles from './Timeline.module.scss'

const TimelineDisplay = ({
  statesData,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  relativeSlots = true,
  hrrEndDate,
}) => {
  const { hrrDataWeekEndDate, timelineIsPaused, setTimelineIsPaused } =
    useGeoState()
  const keys = Object.keys(statesData).sort()
  const values = Object.values(statesData)
  const daysData = values.map((weeklyData, index) => {
    return { index, date: keys[index], ...weeklyData }
  })

  const maxDayIndex = daysData.length - 1
  const day = daysData.filter((w) => w.date === hrrDataWeekEndDate)[0]

  const [curIndex, setCurIndex] = useState(day.index)
  // const handlePlayPauseClick = () => {
  //   if (curIndex === maxDayIndex) {
  //     setCurIndex(0)
  //   }
  //   setTimelineIsPaused(!timelineIsPaused)
  // }

  // const handleRewindClick = () => {
  //   if (curIndex === 0) {
  //     setCurIndex(maxDayIndex)
  //   } else {
  //     setCurIndex(curIndex - 1)
  //   }
  // }

  // const handleForwardClick = () => {
  //   if (curIndex === maxDayIndex) {
  //     setCurIndex(0)
  //   } else {
  //     setCurIndex(curIndex + 1)
  //   }
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!timelineIsPaused && curIndex < maxDayIndex) {
        setCurIndex((prevIndex) => prevIndex + 1)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineIsPaused])

  return (
    <div className={styles.base}>
      {/* <div className={styles.playPause}>
        <ButtonList className={styles.icons}>
          <>
            <LinkButton
              disabled={!timelineIsPaused}
              icon="rewind"
              iconSize="1.5rem"
              onClick={handleRewindClick}
              className={styles.linkButtons}
            ></LinkButton>
            <span className={`${styles.rewind} ${styles.iconText}`}>
              Rewind
            </span>
          </>
          <>
            <LinkButton
              icon={timelineIsPaused ? 'play' : 'pause'}
              iconSize="1.5rem"
              onClick={handlePlayPauseClick}
            ></LinkButton>
            <span className={`${styles.play} ${styles.iconText}`}>
              {timelineIsPaused ? 'Play' : 'Pause'}
            </span>
          </>
          <>
            <LinkButton
              disabled={!timelineIsPaused}
              icon="forward"
              iconSize="1.5rem"
              onClick={handleForwardClick}
            ></LinkButton>
            <span className={`${styles.forward} ${styles.iconText}`}>
              Forward
            </span>
          </>
        </ButtonList>
      </div> */}
      <div className={styles.slider}>
        <TimelineSlider
          days={daysData}
          latestPositionString={latestPositionString}
          slotString={slotString}
          slotTimeInSeconds={slotTimeInSeconds}
          markCounts={markCounts}
          curIndex={curIndex}
          setCurIndex={setCurIndex}
          relativeSlots={relativeSlots}
          hrrEndDate={hrrEndDate}
        />
      </div>
    </div>
  )
}

const TimelineHrr = ({
  statesData,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  loadError,
  relativeSlots = true,
  hrrEndDate,
}) => {
  const keys = Object.keys(statesData).sort()
  if (loadError) {
    return (
      <div className={styles.base}>
        <div className={styles.loading}>
          Error loading data to enable timeline.
        </div>
      </div>
    )
  }

  if (keys.length <= 1) {
    return (
      <div className={styles.base}>
        <div className={styles.loadingSpinner}>
          <Spinner />
        </div>
        <div className={styles.loading}>Loading data to enable timeline</div>
      </div>
    )
  } else {
    return (
      <TimelineDisplay
        statesData={statesData}
        latestPositionString={latestPositionString}
        slotString={slotString}
        slotTimeInSeconds={slotTimeInSeconds}
        markCounts={markCounts}
        relativeSlots={relativeSlots}
        hrrEndDate={hrrEndDate}
      />
    )
  }
}

export default TimelineHrr
