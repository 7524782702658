import React from "react";
import SelectInput from "../SelectInput";

import styles from "./SelectInputFilter.module.scss";

const SelectInputFilter = ({ column: { filterValue, setFilter }, items }) => (
  <SelectInput
    className={styles.base}
    items={items}
    multiple
    onChange={values => setFilter(values || [])}
    value={filterValue || []}
  />
);

SelectInputFilter.filter = (rows, id, filterValue) => {
  return filterValue.length > 0
    ? rows.filter(row =>
        row.values[id].split(", ").some(value => filterValue.includes(value))
      )
    : rows;
};

export default SelectInputFilter;
