import cdcMapLegendHorizontal from './cdcMapLegendHorizontal'
import legend from './legend'

const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'revised_accuracy',
        tagKey: 'revised_accuracy',
        tagValue: 'revised_accuracy',
        mapeValue: 'revised_mape',
        maeValue: 'revised_mae',
        rmseValue: 'revised_rmse',
        maseValue: 'revised_mase',
        label: 'Revised',
        colorAxis: cdcMapLegendHorizontal,
        mapTitle: 'CI Accuracy ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        //reversed: true,
        legend: {
          ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },
      {
        value: 'unrevised_accuracy',
        tagKey: 'unrevised_accuracy',
        tagValue: 'unrevised_accuracy',
        mapeValue: 'unrevised_mape',
        maeValue: 'unrevised_mae',
        rmseValue: 'unrevised_rmse',
        maseValue: 'unrevised_mase',
        label: 'UnRevised',
        colorAxis: cdcMapLegendHorizontal,
        mapTitle: 'CI Accuracy  ',
        mapSubTitle: undefined, // 'Source - UHG and CDC',
        // reversed: true,
        legend: {
          ...legend,
          title: {
            text: '',
          },
          //reverse: true,
          itemWidth: 100,
        },
      },
    ],
  },
]

export default dataDisplayOptions
