import React, { useState, useEffect } from 'react'
import styles from './StateHeader.module.scss'
import getVectorImage from '../../../utils/getVectorImage'
import { useGeoFetch } from '../../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../../CovidPharma/typeDisplayOptions'
import SelectList from '../../../components/SelectList'
import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Search from '../../../components/SearchPharma'

const HeaderDisplay = ({ headerType, dashNo, geo_id }) => {
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'covidHeader',
    geo_id, true
  )
  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        setgeoid(headerdata.response_data.geo_id)
        setistrue(headerdata.response_data.istrue)
        setPercentage(headerdata.response_data.percentage)
        setDatavalue(headerdata.response_data.datavalue)
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if (stateDataError || errorOccured) {
    return (
      <div>
        {errorSubject !== undefined
          ? errorSubject
          : 'Unable to get the data for header'}{' '}
        : {errorSubject}
      </div>
    )
  }

  return (
    <>
      <div className={headerType === 'NAT' ? styles.natDiv : styles.stateDiv}>
        <span className={styles.firstSpan}>
          {' '}
          {getgeoid !== undefined ? getgeoid : ''}
        </span>
        <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
        <span
          className={getistrue ? styles.stdatavalueup : styles.datavaluedown}
        >
          {' '}
          {getPercentage}%{' '}
        </span>
        <span className={styles.forthSpan}> ({getDatavalue})</span>
      </div>
    </>
  )
}

const Header = ({ headerType, headerNumber, geo_id }) => {
  const [searchCriteria, setSearchCriteria] = React.useState([])
  const [activeCbsa, setActiveCbsa] = useState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/COVID?geo_type=MSA', true
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          {/* <DefaultBreadcrumbs
            geo_id={geo_id}
            geo_type={headerType}
            dashboardType={'COVID'}
          /> */}
           <h5>COVID Incidence</h5>
          {/* <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'covid'}
            defaultLabel={'COVID Incidence'}
          /> */}
        </div>
        <div className={styles.pageHeaderRight}>
          <>
            <div className={styles.pageHeaderTextValue}>
              <HeaderDisplay
                headerType="NAT"
                dashNo={headerNumber}
                geo_id="NAT"
              ></HeaderDisplay>
              <HeaderDisplay
                headerType={headerType}
                dashNo={headerNumber}
                geo_id={geo_id}
              ></HeaderDisplay>
            </div>
            <div className={styles.pageHeaderSearch}>
              {activeCbsa !== undefined && (
                <Search
                  criteria={searchCriteria}
                  onChange={setSearchCriteria}
                  entity={'Covid'}
                  activeMetros={activeCbsa}
                />
              )}
            </div>
          </>
        </div>
      </div>
    </>
  )
}
export default Header
