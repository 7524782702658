import styles from './BaseLine.module.scss'
import Tooltip from '../Tooltip'
import Content from '../Content'

const BaseLine = () => (
  <div style={{ zIndex: 9999 }}>
    <Tooltip placement="top" iconHeight="15" iconWidth="15">
      <Content maxWidth="300px" fontSize="12px">
        <p>
          <span
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            Definition
          </span>
          <br></br>
          {
            'Location specific baseline number indicating the starting point for high levels (Mean+6*Standard Deviation) of flu2 ratio.'
          }
          <br></br>
          <span
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            How is this useful?
          </span>
          <br></br>
          {
            'Anything above this value is considered as high levels for the corresponding geo location.'
          }
          <br></br>
          <span
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            Duration
          </span>
          <br></br>
          {
            'Computed using off season time i.e., between 21st and 39th epiweek. '
          }
        </p>
      </Content>
    </Tooltip>
    <span className={styles.btn}>What is Baseline?</span>
  </div>
)

export default BaseLine
