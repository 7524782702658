import React, { useEffect, useState, useMemo } from 'react'

import Flex from '../../../../components/Flex'
import { useAuthState } from '../../../../components/AuthProvider'
import Content from '../../../../components/Content'
import DataTable, { DropDownListFilter } from '../../../../components/DataTableNew'
import Spinner from '../../../../components/Loader/Spinner'
import moment from 'moment'
import styles from './ViewActivityDetails.module.scss'
import CustomDataTable from '../CustomDataTable'
import styled from 'styled-components'
import { useGeoState,useGeoFetch } from '../../../../components/GeoProvider'
import GeoProvider from '../../../../components/GeoProvider'

const ViewActivityDetails = () => {
  const { fromDate, toDate } = useGeoState()
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  //const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  //const userApiUrl = `${clientConfigApiDomain}/admin/usersactivitydetails?endDate=${moment(fromDate).format('YYYY-MM-DD')}&startDate=${moment(toDate).format('YYYY-MM-DD') }`
  const [activitydetails, setActivityDetails] = useState([])
  const { data: satsData, error: satsDataError } = useGeoFetch(
    moment(fromDate).format('YYYY-MM-DD'),
    moment(toDate).format('YYYY-MM-DD'),
    'admin',
    `usersactivitydetails`
  )
  
  // useEffect(() => {
  //   if (satsData !== undefined) {

  //     setActivityDetails(satsData)
  //   }
  // }, [satsData])
  

useEffect(() => {
  if (satsData !== undefined) {    
    setActivityDetails(satsData)
  }
    if (!hasLoaded) {
      setIsLoading(true)     
      setIsLoading(false)
      setHasLoaded(true)
    }
  }, [
    
    setIsLoading,
    setHasLoaded,
    hasLoaded,
    satsData
    ,
  ])



  const { user } = useAuthState()




  const timeFormatCell = ({
    cell: {
      value,
      row: { original: selectedUser },
    },
  }) => {
    const moment = require('moment-timezone')

    return (
      <span>
        {moment(selectedUser.activity_date)
          .tz(user.preferences.timeZone)
          .format('L, LTS z')}
        {/* {moment(selectedUser.date_created).format('YYYY-MM-DD')} */}
      </span>
    )
  }

  const userCsvColumns = [
    {
      Header: 'Dashboard Name',
      accessor: 'dashboard_name',
    },
    {
      Header: 'User Login Type',
      accessor: 'login_type',
    },
    {
      Header: 'Name',
      accessor: 'activity_date',
    },

    {
      Header: 'User Name',
      accessor: 'user_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },

    {
      Header: 'Activity Date',
      accessor: 'activity_date',
    },
  ]

  const userColumns = [
    {
      Header: 'Dashboard Name',
      accessor: 'dashboard_name',
      sortType: (a, b) => {
        return `${a.values.dashboard_name} ? ${a.values.dashboard_name} : 'NA'`.toLowerCase() >
          `${b.values.dashboard_name} ? ${b.values.dashboard_name} : 'NA'`.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: 'User Login Type',
      accessor: 'login_type',
      sortType: (a, b) => {
        return a.values.login_type.toLowerCase() >
          b.values.login_type.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: 'Name',
      accessor: (row) => row.lastname + ', ' + row.firstname,
      sortType: (a, b) => {
        return `${a.values.lastname}, ${a.values.firstname}`.toLowerCase() >
          `${b.values.lastname}, ${b.values.firstname}`.toLowerCase()
          ? 1
          : -1
      },
    },

    {
      Header: 'User Name',
      accessor: 'user_name',
      sortType: (a, b) => {
        return `${a.values.user_name} ? ${a.values.user_name} : 'NA'`.toLowerCase() >
          `${b.values.user_name} ? ${b.values.user_name} : 'NA'`.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: (a, b) => {
        return a.values.email.toLowerCase() > b.values.email.toLowerCase()
          ? 1
          : -1
      },
    },

    {
      Header: 'Activity Date',
      accessor: 'activity_date',
      Cell: timeFormatCell,
      sortType: (a, b) => {
        return a.values.activity_date.toLowerCase() >
          b.values.activity_date.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: '',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
    },
  ]
//vijay
  // useEffect(() => {
  //   const getActivityDetails = async () => {
  //     const response = await fetch(`${userApiUrl}/`)
  //     const data = await response.json()
  //     setActivityDetails(data)
  //   }
  //   if (!hasLoaded) {
  //     setIsLoading(true)
  //     getActivityDetails()
  //     setIsLoading(false)
  //     setHasLoaded(true)
  //   }
  // }, [
  //   userApiUrl,
  //   setIsLoading,
  //   setHasLoaded,
  //   hasLoaded,
  //   user.clients,
  //   user.role,
  //   user.orgIds,
  // ])

  // const { data: userDetailsData, error: userDetailsDataError } = useGeoFetch(
  //   moment(fromDate).format('YYYY-MM-DD'),
  //   moment(toDate).format('YYYY-MM-DD'),
  //   'admin',
  //   'usersactivitydetails'
  // )
  // useEffect(() => {
  //   if (userDetailsData !== undefined) {
  //     setActivityDetails(userDetailsData)
  //   }
  // }, [userDetailsData])

  return (
    <Flex gutter={3}>
      {isLoading ? (
        <Spinner />
      ) : hasLoaded ? (
        <Flex.Item span={12}>
          <Content></Content>
          <div className={styles.base}>
        
            <div> 
            <GeoProvider>            
              <DataTable
                columns={userColumns}
                csvColumns={userCsvColumns}
                data={activitydetails}
                hasPagination
                hasFiltering
                hasSorting
                
              />
               
                </GeoProvider>
            </div>
          </div>
        </Flex.Item>
      ) : null}
    </Flex>
  )
}

export default ViewActivityDetails
