import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import { useHistory } from 'react-router-dom'
import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import getMapOptions from './getMapOptions'
const MapCollection = ({
  toolTipFormatter,
  statesData,
  providers,
  providersAll,
  mapOptions,
  showStateLabels,
  enableTooltip,
  enableMapNavigation,
  enableMouseTracking,
  mapHeight = '80vh',
  mapWidth = '',
  mapType,
  enableButtons = false,
  zoomLocation = '',
  chartRef,
  mapDataType,
}) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }

  const { displayDate } = useGeoState()

  let mapStatesData = null

  if (
    mapType === 'RSVMAP' ||
    mapType === 'RSVMAPMSA' ||
    mapType === 'RSVMAPCDO'
  ) {
    const statesDataForDisplayDate = Object.entries(statesData[displayDate])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1][mapOptions.value],
        state: state[0],
        color: '#E5E5E6',
        borderColor: '#7D7F81',
        //borderWidth: '1px',
        ...state[1],
      }
    })
  }

  let title = mapOptions.mapTitle
  if (!(title === null || title === undefined)) {
    title = ''
  }

  const graphOptions = getMapOptions(
    mapOptions.colorAxis,
    mapOptions.legend,
    mapStatesData,
    providers,
    providersAll,
    enableButtons,
    zoomLocation,
    showStateLabels,
    enableTooltip,
    enableMapNavigation,
    title,
    mapOptions.mapSubTitle
  )

  return mapOptions ? (
    <HighchartsReact
      containerProps={{
        style: {
          height: mapHeight,
          width: mapWidth,
          position: 'relative',
          display: 'flex',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
      ref={chartRef}
    />
  ) : (
    <Spinner />
  )
}

export default MapCollection
