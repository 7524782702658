import React from 'react'
import Links from './Links'
import FooterContent from './Content'
import Link from './Link'
import Logo from '../Logo'
// eslint-disable-next-line no-unused-vars
import { PRIVACY_POLICY, TERMS_OF_USE } from './constants'
import { FEEDBACK_LINK } from '../../utils/constants'

import styles from './Footer.module.scss'

const Footer = ({ appName, logoUrl, children = null }) => (  
  <footer className={styles.base}>   
  <div className={styles.disclaimer}>
    <p><span><b>Disclaimer: </b></span></p>
<div className={styles.contentFormat}>
   If you have or suspect that you have a medical problem or condition, please contact a qualified health care professional immediately. If you are in the United States and are experiencing a medical emergency, please call 911 or call for emergency medical help.
   <p className={styles.contentFormat}>
The content and forecasts offered are for informational purposes only, and nothing in the forecasts and related information should be considered, or used as a substitute for, medical advice, diagnosis or treatment.   Users should seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions. Optum and its affiliates and licensors make no representations or warranties of any kind, express or implied, with respect to these forecasts, any related data, and/or the techniques used to create such forecasts, including, but not limited to, any warranties of performance, merchantability, fitness for a particular purpose, title/non-infringement, and quality, accuracy and/or completeness of these forecasts and related information. Any interpretation or use of these forecasts and related information is solely and exclusively at your own discretion and risk, and Optum and its affiliates and licensors will have no liability for any damages or claims relating to such interpretation or use.
</p>
    </div></div>
    <div className={styles.copyright}>      
      <div>
        <a className={styles.logo} href={logoUrl} >
          <Logo color={'#FFFFFF'} />
        </a>
      </div>

      <ul>
        <li>
          <a href={FEEDBACK_LINK} target="_blank" rel="noopener noreferrer">
            Feedback
          </a>
        </li>
        <li>
          <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
        </li>

        <li>
          <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
    <br />
    <div className={styles.copyrightdes}>
      &copy; 2021 UnitedHealth Group. All rights reserved.
    </div>
  </footer>
)

Footer.Links = Links
Footer.Content = FooterContent
Footer.Link = Link

export default Footer
