
const MapBordercolor = (level) => {  
  if (level === undefined) {
    return "white"
  } else if (level === 3) { return "#C39F00" }
  // else if (level === 4) { return "#FF1A1A" }

  else {
    return "white"
  }
}

export default MapBordercolor
