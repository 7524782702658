import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import ErrorMessage from '../../../../components/Layout/ErrorMessageNew'
import DisplayDateFluNew from '../../../../components/DisplayDateFlu/DisplayDateFluNew'
import Spinner from '../../../../components/Loader/Spinner'
import MapOptions from '../../../../components/MapOptions'
import EmptyMap from '../../../../components/Map/emptyMap'
import Timeline from '../../../../components/Timeline'
import Map from '../../../../components/Map'
import regionDisplayOptions from './regionDisplayOptions'
import dataDisplayOptions from './dataDisplayOptions'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './cdcMapLegendVertical'
import StateStats from './StateStats'
import CbsaStats from './CbsaStats'

import { lastDisplayDate } from '../../dates'
import { lastDisplayDateNew } from '../../dates'

import styles from './FluGeo.module.scss'
import FluDetailedViewComponent from './FluDetailedViewComponent'
import FluMsaApi from './FluMsaApi'

const FluGeo = () => {
  const entity = 'flu'
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'

  const location = useLocation()
  const hash = location.hash

  const [states, setStates] = useState()
  const [metros, setMetros] = useState()
  
  const [getStatesCombinedData, setStatesCombinedData] = useState()
  const [getActiveCBSA, setActiveCBSA] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const [getCbsaJsonFile, setCbsaJsonFile] = useState();
  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);
   
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  // State Data
  const { data: statesTodayData, error: stateTodayDataError } = useGeoFetch(
    lastDisplayDateNew,
    lastDisplayDateNew,
    entity,
    'state'
  )

  useEffect(() => {
    if (statesTodayData !== null && statesTodayData !== undefined) {
      if (statesTodayData.response_code === 200) {
        setStates(statesTodayData.response_data)
      } else {
        setErrorCode(statesTodayData.response_code)
        setErrorMessage(statesTodayData.response_message)
        setErrorSubject(statesTodayData.response_subject)
        seterrorOccured(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesTodayData])

  const { data: statesData, error: stateDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'state'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  // // Metro Area Data
  // const { data: cbsaTodayData, error: cbsaTodayDataError } = useGeoFetch(
  //   lastDisplayDate,
  //   lastDisplayDate,
  //   entity,
  //   'msa'
  // )

  // useEffect(() => {
  //   if (cbsaTodayData !== undefined) {
  //     if (cbsaTodayData.response_code === 200) {
  //       setMetros(cbsaTodayData.response_data)
  //     } else {
  //       setErrorCode(cbsaTodayData.response_code)
  //       setErrorMessage(cbsaTodayData.response_message)
  //       setErrorSubject(cbsaTodayData.response_subject)
  //       seterrorOccured(true)
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cbsaTodayData])

  // const { data: cbsaData, error: cbsaDataError } = useGeoFetch(
  //   dataStartDate,
  //   dataEndDate,
  //   entity,
  //   'msa'
  // )

  // useEffect(() => {
  //   if (cbsaData !== null && cbsaData !== undefined) {
  //     if (cbsaData.response_code === 200) {
  //       setMetros(cbsaData.response_data)
  //     } else {
  //       setErrorCode(cbsaData.response_code)
  //       setErrorMessage(cbsaData.response_message)
  //       setErrorSubject(cbsaData.response_subject)
  //       seterrorOccured(true)
  //     }
  //   }
  //   //setMetros(cbsaData)
  // }, [cbsaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'activecbsa'
  )
  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (activeCbsasData.response_code === 200) {
        setActiveCBSA(activeCbsasData.response_data)
      } else {
        setErrorCode(activeCbsasData.response_code)
        setErrorMessage(activeCbsasData.response_message)
        setErrorSubject(activeCbsasData.response_subject)
        seterrorOccured(true)
      }
    }
    //setMetros(cbsaData)
  }, [activeCbsasData])
  // State Combined data for right side graph view
  const { data: statesCombinedData, error: statesCombinedDataError } =
    useGeoFetch(dataStartDate, dataEndDate, entity, 'state?seasons=all')
  useEffect(() => {
    if (statesCombinedData !== null && statesCombinedData !== undefined) {
      if (statesCombinedData.response_code === 200) {
        setStatesCombinedData(statesCombinedData.response_data)
      } else {
        setErrorCode(statesCombinedData.response_code)
        setErrorMessage(statesCombinedData.response_message)
        setErrorSubject(statesCombinedData.response_subject)
        seterrorOccured(true)
      }
    }
    //setMetros(cbsaData)
  }, [statesCombinedData])
  // metro Combined data for right side graph view


  if (
    stateTodayDataError ||    
    activeCbsasDataError ||
    statesCombinedDataError ||    
    errorOccured
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  const setFluMsaData = (flumsa) => {
    setMetros(flumsa);
  };
  return (
   
    <div className={`${styles.base}`}>
      <div>
        <MapOptions
          regionDisplayOptions={regionDisplayOptions}
          dataDisplayOptions={dataDisplayOptions}
          mapType="FLUMAP"
        />
      </div>
      <div className={`${styles.datePanel}`}>
        <h2>Influenza Level</h2>
        <span>
          Actual and forecasted influenza levels based on claims data.
        </span>
      </div>

      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {states !== undefined ? (
                <StateStats stateData={states} mapOptions={mapOptions} />
              ) : (
                <Spinner />
              )}
            </>
          )}

          {regionSelected === 'metro' && (
            <>
              <FluMsaApi cbsaMetroData={setFluMsaData} />
              {metros !== undefined ? (
                <CbsaStats cbsaJson={getCbsaJsonFile} cbsaData={metros} mapOptions={mapOptions} />
              ) : (
                <Spinner />
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {hash && hash !== '#map' && getCbsaJsonFile && (
          <div className={styles.mapPopup}>
            <FluDetailedViewComponent             
              statesData={getStatesCombinedData}
              hash={hash}
              stateJson={getStateJsonFile}
              cbsaJson={getCbsaJsonFile}
            />
          </div>
        )}
        {/* {'enabled' in mapOptions.legend &&
          mapOptions.legend.enabled === false && <MapLegend />} */}
        {regionSelected === 'state' && (
          <>
            {states !== undefined && getStateJsonFile? (
              <>
                <MapLegend />
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={states}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="FLUMAP"
                  enableButtons={true}
                  stateJson={getStateJsonFile}
                />
              </>
            ) : (
              <EmptyMap stateJson={getStateJsonFile}/>
            )}
          </>
        )}
        {regionSelected === 'metro' && (
          <>
            {metros !== undefined && getActiveCBSA !== undefined && getStateJsonFile ? (
              <>
                <MapLegend />
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={getActiveCBSA}
                  statesData={states}
                  cbsaData={metros}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="FLUMAP"
                  enableButtons={true}
                  stateJson={getStateJsonFile}
                  cbsaJson={getCbsaJsonFile}
                />
              </>
            ) : (
              <EmptyMap stateJson={getStateJsonFile}/>
            )}
          </>
        )}
      </div>

      <div className={`${styles.timelinePanel}`}>
        {states !== undefined  && (
          <DisplayDateFluNew
            showWeek={false}
            latestPositionString={latestPositionString}
            slotString={slotString}
            slotTimeInSeconds={slotTimeInSeconds}
          />
        )}
        {states !== undefined  && (
          <Timeline
            statesData={states}
            latestPositionString={latestPositionString}
            slotString={slotString}
            slotTimeInSeconds={slotTimeInSeconds}
            markCounts={11}
            loadError={stateDataError }
          />
        )}
      </div>
    </div>
    
  )
}


export default FluGeo
