import React from 'react'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import styles from './RiskCalculator.module.scss'
import DashboardHeader from './DashboardHeader'
import RiskFilter from './RiskFilter'
import GraphPanel from './GraphPanel'
import { startDate, endDate, displayDate } from './dates'

// import { startDate, endDate, displayDate } from './dates'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
const RiskCalculator = () => {
  const { user } = useAuthState()
  var url = window.location.href
  var name = ''
  if (url.indexOf('RiskCalculator') !== -1) name = 'Cardiac Risk Calculator'  
    

  var logObject = {
    dashboard_url: url,
    dashboard_name: name,
    indicator_name: name,
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  return (
    <Layout>
      <Content fontSize={'14px'}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.content}>
              <DashboardHeader />
              <p className={styles.lineHeading}>Select Patient Profile</p>
              <hr style={{ height: '1px', background: 'grey' }} />
              
              <GeoProvider
                startDate={startDate}
                endDate={endDate}
                displayDate={displayDate}
                getRiskResult={0}
              >
                <RiskFilter  />
                <GraphPanel  />
              </GeoProvider>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default RiskCalculator
