import React from 'react'
import { useHistory } from 'react-router-dom'

//import usMsaAll from '../../../components/Map/us_hrr_values.json1'
import { useGeoState } from '../../../components/GeoProvider'
import LinkButton from '../../../components/LinkButton'

// import statesMap from '../../../utils/stateMap'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

const CbsaStat = ({ cbsa, mapOptions }) => {
  const history = useHistory()




  // const states = statesMap()
  return (
    <div className={statStyles.base}>
      <div
        style={{
          display: 'inline-flex',
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: '#424242',
          fontFamily: 'graphik',
          fontSize: '18px',
        }}
      >
        <LinkButton
          onClick={() => {
            history.push(`/Forecasts/hrrmap#hrr-${cbsa.identifier}`)
          }}
          title={cbsa.geo_name + ',' + cbsa.geo_parent_code}
          style={{ fontSize: '18px', color: '#424242', fontFamily: 'graphik' }}
        >
          {cbsa.name.length > 20
            ? cbsa.geo_name.substring(0, 20) + '...'
            : cbsa.geo_name + ', ' + cbsa.geo_parent_code}
        </LinkButton>
      </div>
      <div
        style={{
          display: 'inline-flex',
          width: '25%',
          justifyContent: 'flex-end',
          color: '#424242',
          fontFamily: 'graphik',
          fontSize: '14px',
        }}
        title={mapOptions.label}
      >
        {cbsa[mapOptions.value].toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}
      </div>
    </div>
  )
}

const HrrStats = ({ hrrMapJson, cbsaData, mapOptions }) => {
  const { searchFilter, setSearchFilter, hrrDataWeekEndDate } = useGeoState()
  const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
  const cbsaDataForDisplayDate = Object.entries(cbsaData[hrrDataWeekEndDate]) // Object.entries(cbsaData[Object.keys(cbsaData)[0]])
  const mappedCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
    const filteredCbsa = hrrMapJson.features.filter((c) => {
      return c.properties.HRRNUM.toString() === cbsa[0].toString()
    })

    // This will only take the name out from the name followed by state
    // Example: Minneapolis-St. Paul-Bloomington, MN-WI => Minneapolis-St. Paul-Bloomington

    const cityName =
      filteredCbsa[0] && filteredCbsa[0].properties.HRRCITY.split(',')[0]

    // TODO: move the name conversion to <CbsaStat/>
    return { ...cbsa[1], name: cityName, identifier: cbsa[0] }
  })

  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }

  let filteredCbsaData = mappedCbsaData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredCbsaData = mappedCbsaData.filter((cbsa) => {
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredCbsaData = filteredCbsaData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })
  function showSearch() {
    btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%', //'95%',
            justifyContent: 'flex-start',
            padding: '10px 0px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'graphik',
                    fontSize: '21px',
                    fontWeight: '600',
                  }}
                >
                  Hospital Referral Regions
                </h5>
              </span>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '30%',
                justifyContent: 'right',
              }}
            >
              <span onClick={showSearch}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3584 10.8862L18 17.01L17.0701 18L10.3724 11.7859C9.26916 12.7439 8.21615 13.0355 6.64841 13.0355C3.10647 13.0355 0.203247 10.1323 0.203247 6.59032C0.203247 3.04838 3.10647 0.145157 6.64841 0.145157C10.1903 0.145157 13.0936 3.04838 13.0936 6.59032C13.0936 8.36129 12.5197 9.7249 11.3584 10.8862ZM6.78389 1.45483C4.03637 1.45483 1.78389 3.70175 1.78389 6.44249C1.78389 9.20792 4.03637 11.4548 6.78389 11.4548C9.53142 11.4548 11.7839 9.18323 11.7839 6.44249C11.7839 3.70175 9.53142 1.45483 6.78389 1.45483Z"
                    fill="#E40046"
                  />
                </svg>
              </span>
            </div>
          </div>

          {btnSearchLocation ? (
            <form>
              <input
                type="text"
                onChange={handleChange}
                placeholder="Filter HRR"
              />
            </form>
          ) : // <h1>Yes</h1>
          null}
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #424242',
            borderBottom: '1px solid #424242',
            marginTop: '5px',
          }}
        >
          <div
            style={{ display: 'inline-flex', width: '60%', padding: 'left' }}
          >
            <h5
              style={{
                fontFamily: 'graphik',
                fontSize: '14px',
                justifyContent: 'flex-start',
                fontWeight: '600',
                color: '#424242;',
              }}
            >
              Area Name
            </h5>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '35%',
              justifyContent: 'flex-end',
              fontFamily: 'graphik',
            }}
          >
            <h5
              style={{
                fontFamily: 'graphik',
                fontSize: '14px',
                justifyContent: 'flex-start',
                fontWeight: '600',
                color: '#424242;',
              }}
            >
              {mapOptions.label}
            </h5>
          </div>

          {/* <div style={{ display: 'inline-flex', width: '10%' }}>
            <h5>Trend</h5>
          </div> */}
        </div>
      </div>
      <div style={{ overflowY: 'scroll', height: '100%' }}>
        {filteredCbsaData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((cbsa) => {
            return (
              <CbsaStat
                cbsa={cbsa}
                key={cbsa.identifier}
                mapOptions={mapOptions}
              />
            )
          })}
      </div>
    </div>
  )
}

export default HrrStats
