import React from 'react'
import moment from 'moment-timezone'
import { Link, useParams,useHistory } from 'react-router-dom'
import { useAuthState } from '../../../../components/AuthProvider'
import Flex from '../../../../components/Flex'
import Content from '../../../../components/Content'
import { useFetch } from '../../../../components/useFetch'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import Spinner from '../../../../components/Loader/Spinner'
import Icon from '../../../../components/Icon'
import styles from './ViewSystemNotification.module.scss'

const ViewSystemNotification = () => {
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const systemNotificationApiUrl = `${clientConfigApiDomain}/systemnotifications`

  const { id } = useParams()
  const { user } = useAuthState()
  const history = useHistory()
  const [{ data, isLoading, hasLoaded }, fetch] = useFetch()

  React.useEffect(() => {
    fetch({
      url: `${systemNotificationApiUrl}/${id}/`,
    })
  }, [id, fetch, systemNotificationApiUrl])

  let loadedBreadcrumbs = ''
  if (isLoading) {
    loadedBreadcrumbs = <Spinner />
  } else if (hasLoaded) {
    loadedBreadcrumbs = (
      <Breadcrumbs>
        <Breadcrumbs.Item render={() => <Link to="/">Home</Link>} />
        <Breadcrumbs.Item
          render={() => <Link to="/admin">Administration</Link>}
        />
        <Breadcrumbs.Item
          render={() => (
            <Link to="/admin/systemnotifications">System Notifications</Link>
          )}
        />
        <Breadcrumbs.Item
          last
          render={() => `System Notification: ${data.id}`}
        />
      </Breadcrumbs>
    )
  }

  return (
    <div>
        {isLoading && user ? (
          <Spinner />
        ) : hasLoaded ? (
          <div className={styles.base}>
            <div className={styles.header}>
              {/* {loadedBreadcrumbs} */}
              <div className={styles.content}>
                <div style={{display:'flex'}}>
                <span>System Notification
                  
                  </span>
                  <button
                noValidate
                style={{ marginLeft: '35%',cursor:'pointer' }}
                onClick={() => {
                  history.push(`/admin/systemnotifications`)
                }}
              >
                <Icon type="remove" size={'1.5rem'} />
              </button>
              </div>
              </div>
            </div>
            <div className={styles.body}>
              <Flex gutter={3}>
                <Flex.Item span={12}>
                  <div className={styles.base}>
                    <div className={styles.notificationDetails}>
                      <div>
                        <h4>System Notification ID</h4>
                        <span>{data.id}</span>

                       
                      </div>
                      <div>
                        <h4>Message</h4>
                        <span>{data.message}</span>
                      </div>
                      <div>
                        <h4>Start Date</h4>
                        <span>
                          {moment(data.startDate).format('YYYY-MM-DD')}
                        </span>
                      </div>
                      <div>
                        <h4>End Date</h4>
                        <span>{moment(data.endDate).format('YYYY-MM-DD')}</span>
                      </div>
                      <div>
                        <h4>Created By</h4>
                        <span> {data.createdBy} </span>
                      </div>
                      <div>
                        <h4>Date Created</h4>
                        <span>
                          {moment(data.createdAt)
                            .tz(user.preferences.timeZone)
                            .format('L, LTS z')}
                        </span>
                      </div>
                      <div>
                        <h4>Updated By</h4>
                        <span>{data.updatedBy}</span>
                      </div>
                      <div>
                        <h4>Date Updated</h4>
                        <span>
                          {moment(data.updatedAt)
                            .tz(user.preferences.timeZone)
                            .format('L, LTS z')}
                        </span>
                      </div>
                    </div>
                  </div>
                </Flex.Item>
                {/* <Flex.Item span={12}>
                  <Button
                    className={styles.margin}
                    onClick={() => {
                      history.push(`/admin/systemnotifications/${id}/edit`)
                    }}
                    primary
                    showArrow
                  >
                    Edit
                  </Button>
                </Flex.Item> */}
              </Flex>
            </div>
          </div>
        ) : null}
      </div>
  )
}

export default ViewSystemNotification
