import React from 'react'

import { useGeoFetch } from '../GeoProvider'
import ErrorMessage from '../Layout/ErrorMessage'
import Spinner from '../Loader/Spinner'

import PieChartDisplay from './PieChartDisplay'

const PieChart = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  dataDisplayOptions,
  chartName,
  pieFor,
  height,
  filterId,
  fluFolder, fluseason, month, lob , type, state,
  countPatient,
  
}) => {
  const { data, error } = useGeoFetch(startDate, endDate, entity,    
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`)
  if (error) {
    return <ErrorMessage />
  }

  return data ? (
    <PieChartDisplay
      toolTipFormatter={toolTipFormatter}
      entity={entity}
      location={location}
      locationDisplay={locationDisplay}
      startDate={startDate}
      endDate={endDate}
      options={options}
      data={data}
      dataDisplayOptions={dataDisplayOptions}
      chartName={chartName}
      pieFor={pieFor}
      height={height}
      countPatient={countPatient}
     
    />
  ) : (
    <Spinner />
  )
}

export default PieChart
