import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import usStatesAll from './usStatesAll'

import styles from './EmptyMap.module.scss'

const EmptyMap = ({ mapHeight = '70vh', stateJson }) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }

  const states = usStatesAll.map((state) => {
    return {
      value: -1,
      state: state.value,
    }
  })

  const graphOptions = {
    title: { text: 'Loading Map...', style: { color: 'lightgray' } },
    chart: {
      backgroundColor: 'none',
      displayErrors: false,
    },
    legend: { enabled: false },
    mapNavigation: { enabled: false },
    credits: { enabled: false },
    colorAxis: {
      dataClasses: [{ to: 0, color: 'white', name: 'NA' }],
    },
    tooltip: { enabled: false },
    series: [
      {
        mapData: stateJson,
        data: states,
        joinBy: ['hc-a2', 'state'],
        borderColor: 'lightgray',
        borderWidth: 1,
        shadow: false,
        cursor: 'wait',
      },
    ],
    mapline: {
      showInLegend: false,
      enableMouseTracking: false,
    },
  }

  return (
    <div className={styles.base}>
      <HighchartsReact
        containerProps={{
          style: {
            height: mapHeight,
            width: '50vh',
            position: 'relative',
            display: 'flex',
          },
        }}
        constructorType="mapChart"
        highcharts={Highcharts}
        options={graphOptions}
        allowChartUpdate
        updateArgs={[true, true, false]}
      />
    </div>
  )
}

export default EmptyMap
