const FluDefaults = {
  AK: { forecast_level: 1, forecast_value: '0.226' },
  AL: { forecast_level: 1, forecast_value: '0.166' },
  AR: { forecast_level: 1, forecast_value: '0.208' },
  AZ: { forecast_level: 1, forecast_value: '0.222' },
  CA: { forecast_level: 1, forecast_value: '0.123' },
  CO: { forecast_level: 1, forecast_value: '0.127' },
  CT: { forecast_level: 1, forecast_value: '0.051' },
  DC: { forecast_level: 1, forecast_value: '0.055' },
  DE: { forecast_level: 1, forecast_value: '0.133' },
  FL: { forecast_level: 1, forecast_value: '0.203' },
  GA: { forecast_level: 1, forecast_value: '0.180' },
  HI: { forecast_level: 1, forecast_value: '0.151' },
  IA: { forecast_level: 1, forecast_value: '0.107' },
  ID: { forecast_level: 1, forecast_value: '0.194' },
  IL: { forecast_level: 1, forecast_value: '0.192' },
  IN: { forecast_level: 1, forecast_value: '0.214' },
  KS: { forecast_level: 1, forecast_value: '0.105' },
  KY: { forecast_level: 1, forecast_value: '0.178' },
  LA: { forecast_level: 2, forecast_value: '0.323' },
  MA: { forecast_level: 1, forecast_value: '0.083' },
  MD: { forecast_level: 1, forecast_value: '0.102' },
  ME: { forecast_level: 1, forecast_value: '0.067' },
  MI: { forecast_level: 1, forecast_value: '0.128' },
  MN: { forecast_level: 1, forecast_value: '0.181' },
  MO: { forecast_level: 1, forecast_value: '0.197' },
  MS: { forecast_level: 1, forecast_value: '0.212' },
  MT: { forecast_level: 1, forecast_value: '0.183' },
  NC: { forecast_level: 1, forecast_value: '0.179' },
  ND: { forecast_level: 1, forecast_value: '0.191' },
  NE: { forecast_level: 1, forecast_value: '0.091' },
  NH: { forecast_level: 1, forecast_value: '0.100' },
  NJ: { forecast_level: 1, forecast_value: '0.115' },
  NM: { forecast_level: 1, forecast_value: '0.208' },
  NV: { forecast_level: 1, forecast_value: '0.135' },
  NY: { forecast_level: 1, forecast_value: '0.095' },
  OH: { forecast_level: 1, forecast_value: '0.127' },
  OK: { forecast_level: 1, forecast_value: '0.255' },
  OR: { forecast_level: 1, forecast_value: '0.090' },
  PA: { forecast_level: 1, forecast_value: '0.207' },
  RI: { forecast_level: 1, forecast_value: '0.084' },
  SC: { forecast_level: 1, forecast_value: '0.209' },
  SD: { forecast_level: 1, forecast_value: '0.137' },
  TN: { forecast_level: 1, forecast_value: '0.201' },
  TX: { forecast_level: 1, forecast_value: '0.221' },
  UT: { forecast_level: 4, forecast_value: '0.409' },
  VA: { forecast_level: 1, forecast_value: '0.134' },
  VT: { forecast_level: 1, forecast_value: '0.192' },
  WA: { forecast_level: 1, forecast_value: '0.101' },
  WI: { forecast_level: 1, forecast_value: '0.096' },
  WV: { forecast_level: 1, forecast_value: '0.150' },
  WY: { forecast_level: 1, forecast_value: '0.078' },
}

export default FluDefaults
