import React from "react";
import TextBox from "../TextBox";

import styles from "./TextBoxFilter.module.scss";

const TextBoxFilter = ({
  column: { filterValue, preFilteredRows, setFilter }
}) => (
  <TextBox
    className={styles.base}
    onChange={event => {
      setFilter(event.target.value || undefined); // Set undefined to remove the filter entirely
    }}
    placeholder={`Search ${preFilteredRows.length} records...`}
    value={filterValue || ""}
  />
);

export default TextBoxFilter;
