import React from 'react'
import Container from '../Container'
import Item from './Item'

import styles from './Navigation.module.scss'

import { color } from 'highcharts'

const padding = { xs: [0, 2], md: [0, 3], lg: [0, 4], xl: [0, 5] }

const Navigation = ({ children = null }) => (
  <nav className={styles.base}>
    <Container className={styles.containerPadding}>
      <ul className={styles.items}>{children}</ul>
    </Container>
  </nav>
)

Navigation.Item = Item

export default Navigation
