import React from 'react'

import LineChartDisplay from './LineChartDisplay'

const DateLineChart = ({
  // entity,
  // location,
  // locationDisplay,
  // startDate,
  // endDate,
  // options,
  // toolTipFormatter,
  dataDisplayOptions,
  height,
  width,
  type,
  data,
  yaxisName,
}) => {
  // const { data, error } = useGeoFetch('2021-10-02', '2023-08-02', 'flu', 'flu')

  // if (error) {
  //   return <ErrorMessage />
  // }

  return data ? (
    <LineChartDisplay
      // toolTipFormatter={toolTipFormatter}
      // entity={entity}
      // location={location}
      // locationDisplay={locationDisplay}
      // startDate={startDate}
      // endDate={endDate}
      // options={options}
      data={data}
      height={height}
      width={width}
      type={type}
      dataDisplayOptions={dataDisplayOptions}
      yaxisName={yaxisName}
    />
  ) : (
    // <Spinner />
    <div></div>
  )
}

export default DateLineChart
