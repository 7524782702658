function toolTipFormatter() {
  let seriesName = 'Insufficient Data'
  if (this.point.value >= 1 && this.point.value < 4) seriesName = 'Minimal'
  if (this.point.value >= 4 && this.point.value < 6) seriesName = 'Low'
  if (this.point.value >= 6 && this.point.value < 8) seriesName = 'Moderate'
  if (this.point.value >= 8 && this.point.value < 11) seriesName = 'High'
  if (this.point.value >= 11) seriesName = 'Very High'

  const pointName =
    this.point.properties.cbsa_name === undefined
      ? ''
      : `/${this.point.properties.cbsa_name}`

  return `
    <h4 style="background-color: #000; color: #fff; padding: 10px;">${
      this.point.name
    }${pointName}</h4>
    <table style="padding: 0 10px 10px; width: 100%">
        <tr>
            <th>Flu Activity Level: </th>
            <td>${seriesName}</td>
        </tr>
        <tr>
            <th>Flu Index: </th>
            <td>
              ${this.point.forecast_value.toLocaleString(undefined, {
                minimumFractionDigits: 4,
              })}
            </td>
        </tr>
    </table>`
}

export default toolTipFormatter
