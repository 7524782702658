import React from 'react'
import dayjs from 'dayjs'
import Tag from '../../../components/Tag'
import RiskIcon from '../../../components/RiskIcon'
import moment from 'moment'
import styles from './KpisValues.module.scss'
import countingMethod from '../../../utils/getCountingMethod'

const KpisValues = (data) => {
  const fludate = dayjs().day(-1).format('YYYY-MM-DD')
  let covidData = null
  let fluData = null
  let hrrData = null
  var mapType = null
  if (data.dataValues !== undefined) {
    mapType = data.mapType
    if (mapType === 'COVID') {      
     var coviddate1 = Object.keys(data.dataValues)[0]
      var datavalues = Object.entries(data.dataValues[coviddate1])
      covidData = datavalues.map((stats) => {
        return stats[1]
      })
    }
  }
  if (mapType === 'FLU') {
    var datavalues1 = Object.entries(data.dataValues[fludate])
    fluData = datavalues1.map((flustats) => {
      return flustats[1]
    })
  }
  if (mapType === 'HRR') {    
    var datavalues2 = Object.entries(data.dataValues[Object.keys(data.dataValues)[0]])      

    hrrData = datavalues2.map((hrrstats) => {
      return hrrstats[1]
    })
  }

  return mapType === 'FLU' ? (
    <div className={styles.kpisDiv}>
      <div className={styles.kpisDivLeft}>
        <span>Flu Activity County Wide </span>
        <br></br>
        <span>
          <span className={styles.val}>
            <Tag dataValue={fluData[3]} showNumbers={true} />
          </span>
        </span>
      </div>
      <div className={styles.kpisDivLeft}>
        <span>95% Confidence Interval Accuracy</span>
        <br></br>
        <span>
          <span className={styles.val}>
            {fluData[3].forecast_ci_revised_95_overall.toFixed(2)}%
          </span>{' '}
          (All States Avg)
        </span>
      </div>
    </div>
  ) : mapType === 'COVID' ? (
    <div>
      <div className={styles.kpisDiv}>
      <div className={styles.kpisDivLeft}>
        <span>Covid Activity County Wide </span>
        <br></br>
        <span>
          <span className={styles.val}>
            <Tag dataValue={covidData[0]} showNumbers={true} />
          </span>
        </span>
      </div>
      
      </div> 
    </div>
  ) : (
    <div className={styles.kpisDiv}>
      <div className={styles.kpisDivLeft}>
        <span>Weekly Hospitalization</span>
        <br></br>
        <span>
          <span className={styles.val}>
            {' '}
            {countingMethod(hrrData[0].forecast, 'fixed')}
          </span>{' '}
          <span>
            <RiskIcon size="12px" dataValue={hrrData[0].forecast_percentage} />{' '}
            {Math.abs(hrrData[0].forecast_percentage)}%{'  '}{' '}
          </span>{' '}
          from{' '}
          <span>
            {moment(hrrData[0].last_start_date).format('MMM DD, YYYY')}
          </span>
        </span>
      </div>
    </div>
  )
}
export default KpisValues
