import React from 'react'

import './Spinner.scss'

const Spinner = () => {
  return (
    <>
      <span className="spinner" />
      <div>Loading</div>
    </>
  )
}
export default Spinner
