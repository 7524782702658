function mapEvent(mapType, options, history, dashboardType) {
  let stateDash = dashboardType + 'StateDashboard'
  let msaDash = dashboardType + 'MSADashboard'
  let hash = undefined
  if (mapType !== undefined && mapType === 'RSVMAP' && 'state' in options) {
    hash = `/${stateDash}/STATE/${options.state}`
    history.push(hash)
  } else if (
    mapType === 'RSVMAPMSA' &&
    'state' in options &&
    options.geo_parent !== 'US'
  ) {
    hash = `/${msaDash}/${options.geo_parent}/MSA/${options.state}`
    history.push(hash)
  } else if (
    mapType === 'RSVMAPMSA' &&
    'state' in options &&
    options.geo_parent === 'US'
  ) {
    window.location.href = `/${stateDash}/STATE/${options.state}`
  } else if (
    mapType === 'RSVMAPCDO' &&
    'state' in options &&
    options.geo_parent !== 'US'
  ) {
    window.location.href = `/${msaDash}/${options.geo_parent}/MSA/${options.state}`
  } else if (
    mapType === 'RSVMAPCDO' &&
    'state' in options &&
    options.geo_parent === 'US'
  ) {
    window.location.href = `/${stateDash}/STATE/${options.state}`
  }
}

export default mapEvent
