const HRRDefaults = {
  1: {
    geo_id: '1',
    geo_type: 'HRR',
    geo_name: 'Birmingham',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 335.610426834656,
    forecast_lo_95: 158.715159505867,
    forecast_hi_95: 512.505694163445,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  2: {
    geo_id: '2',
    geo_type: 'HRR',
    geo_name: 'Dothan',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.0613822606562,
    forecast_lo_95: 0.0,
    forecast_hi_95: 82.9396977825738,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  5: {
    geo_id: '5',
    geo_type: 'HRR',
    geo_name: 'Huntsville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 131.396208622559,
    forecast_lo_95: 77.7640855294264,
    forecast_hi_95: 185.028331715691,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  6: {
    geo_id: '6',
    geo_type: 'HRR',
    geo_name: 'Mobile',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 86.6587279744699,
    forecast_lo_95: 0.0,
    forecast_hi_95: 176.820877309115,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  7: {
    geo_id: '7',
    geo_type: 'HRR',
    geo_name: 'Montgomery',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 34.2383741526714,
    forecast_lo_95: 0.0,
    forecast_hi_95: 100.896903359147,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  9: {
    geo_id: '9',
    geo_type: 'HRR',
    geo_name: 'Tuscaloosa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 17.8646167951043,
    forecast_lo_95: 0.0,
    forecast_hi_95: 37.0155568186497,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alabama',
    geo_parent_code: 'AL',
  },
  10: {
    geo_id: '10',
    geo_type: 'HRR',
    geo_name: 'Anchorage',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 53.9562614606823,
    forecast_lo_95: 21.2174842922375,
    forecast_hi_95: 86.6950386291272,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Alaska',
    geo_parent_code: 'AK',
  },
  11: {
    geo_id: '11',
    geo_type: 'HRR',
    geo_name: 'Mesa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 84.1326779474074,
    forecast_lo_95: 0.0,
    forecast_hi_95: 170.992830755443,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arizona',
    geo_parent_code: 'AZ',
  },
  12: {
    geo_id: '12',
    geo_type: 'HRR',
    geo_name: 'Phoenix',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 172.323743244025,
    forecast_lo_95: 0.0,
    forecast_hi_95: 375.992513457968,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arizona',
    geo_parent_code: 'AZ',
  },
  14: {
    geo_id: '14',
    geo_type: 'HRR',
    geo_name: 'Sun City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 29.5548895670482,
    forecast_lo_95: 0.0,
    forecast_hi_95: 63.2185877891129,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arizona',
    geo_parent_code: 'AZ',
  },
  15: {
    geo_id: '15',
    geo_type: 'HRR',
    geo_name: 'Tucson',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 54.5302929795183,
    forecast_lo_95: 0.0,
    forecast_hi_95: 118.199100936245,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arizona',
    geo_parent_code: 'AZ',
  },
  16: {
    geo_id: '16',
    geo_type: 'HRR',
    geo_name: 'Fort Smith',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 45.0561341778599,
    forecast_lo_95: 0.0,
    forecast_hi_95: 94.5253001168278,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arkansas',
    geo_parent_code: 'AR',
  },
  18: {
    geo_id: '18',
    geo_type: 'HRR',
    geo_name: 'Jonesboro',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 44.1809847704592,
    forecast_lo_95: 8.27856124668818,
    forecast_hi_95: 80.0834082942302,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arkansas',
    geo_parent_code: 'AR',
  },
  19: {
    geo_id: '19',
    geo_type: 'HRR',
    geo_name: 'Little Rock',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 187.400470303156,
    forecast_lo_95: 64.2900317797746,
    forecast_hi_95: 310.510908826537,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arkansas',
    geo_parent_code: 'AR',
  },
  21: {
    geo_id: '21',
    geo_type: 'HRR',
    geo_name: 'Springdale',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 26.8008769390509,
    forecast_lo_95: 0.0,
    forecast_hi_95: 64.4342433713628,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arkansas',
    geo_parent_code: 'AR',
  },
  22: {
    geo_id: '22',
    geo_type: 'HRR',
    geo_name: 'Texarkana',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 14.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 45.7796578444251,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Arkansas',
    geo_parent_code: 'AR',
  },
  23: {
    geo_id: '23',
    geo_type: 'HRR',
    geo_name: 'Orange County',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 244.720428419708,
    forecast_lo_95: 52.4599599474735,
    forecast_hi_95: 436.980896891941,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  25: {
    geo_id: '25',
    geo_type: 'HRR',
    geo_name: 'Bakersfield',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 130.677908548686,
    forecast_lo_95: 47.2543666967869,
    forecast_hi_95: 214.101450400586,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  31: {
    geo_id: '31',
    geo_type: 'HRR',
    geo_name: 'Chico',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 22.8288208088518,
    forecast_lo_95: 0.0,
    forecast_hi_95: 57.0287285102198,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  33: {
    geo_id: '33',
    geo_type: 'HRR',
    geo_name: 'Contra Costa County',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 96.9437607687155,
    forecast_lo_95: 35.8566925676499,
    forecast_hi_95: 158.030828969781,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  43: {
    geo_id: '43',
    geo_type: 'HRR',
    geo_name: 'Fresno',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 152.595209720765,
    forecast_lo_95: 74.3620477047978,
    forecast_hi_95: 230.828371736732,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  56: {
    geo_id: '56',
    geo_type: 'HRR',
    geo_name: 'Los Angeles',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 771.30642555348,
    forecast_lo_95: 307.895125446608,
    forecast_hi_95: 1234.71772566035,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  58: {
    geo_id: '58',
    geo_type: 'HRR',
    geo_name: 'Modesto',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 95.2743239333774,
    forecast_lo_95: 47.3305499786209,
    forecast_hi_95: 143.218097888134,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  62: {
    geo_id: '62',
    geo_type: 'HRR',
    geo_name: 'Napa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 21.1677513439124,
    forecast_lo_95: 7.03542639224307,
    forecast_hi_95: 35.3000762955817,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  65: {
    geo_id: '65',
    geo_type: 'HRR',
    geo_name: 'Alameda County',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 111.134972382573,
    forecast_lo_95: 47.940216528437,
    forecast_hi_95: 174.32972823671,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  69: {
    geo_id: '69',
    geo_type: 'HRR',
    geo_name: 'Palm Springs/Rancho Mira',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 12.4011523885827,
    forecast_lo_95: 0.0,
    forecast_hi_95: 50.8672239412804,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  73: {
    geo_id: '73',
    geo_type: 'HRR',
    geo_name: 'Redding',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 37.9549079553963,
    forecast_lo_95: 2.85104762223799,
    forecast_hi_95: 73.0587682885545,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  77: {
    geo_id: '77',
    geo_type: 'HRR',
    geo_name: 'Sacramento',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 291.739806470492,
    forecast_lo_95: 181.523618526397,
    forecast_hi_95: 401.955994414587,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  78: {
    geo_id: '78',
    geo_type: 'HRR',
    geo_name: 'Salinas',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 27.2049143042081,
    forecast_lo_95: 4.08097995718884,
    forecast_hi_95: 50.3288486512274,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  79: {
    geo_id: '79',
    geo_type: 'HRR',
    geo_name: 'San Bernardino',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 174.24149626124,
    forecast_lo_95: 0.0,
    forecast_hi_95: 352.386084452007,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  80: {
    geo_id: '80',
    geo_type: 'HRR',
    geo_name: 'San Diego',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 302.82404093613,
    forecast_lo_95: 149.391889058203,
    forecast_hi_95: 456.256192814056,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  81: {
    geo_id: '81',
    geo_type: 'HRR',
    geo_name: 'San Francisco',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 108.24932930813,
    forecast_lo_95: 62.5629634024737,
    forecast_hi_95: 153.935695213786,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  82: {
    geo_id: '82',
    geo_type: 'HRR',
    geo_name: 'San Jose',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 131.727536987461,
    forecast_lo_95: 71.5723364204725,
    forecast_hi_95: 191.88273755445,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  83: {
    geo_id: '83',
    geo_type: 'HRR',
    geo_name: 'San Luis Obispo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 11.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 24.2446035399404,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  85: {
    geo_id: '85',
    geo_type: 'HRR',
    geo_name: 'San Mateo County',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 83.1958113911541,
    forecast_lo_95: 32.8777021922831,
    forecast_hi_95: 133.513920590025,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  86: {
    geo_id: '86',
    geo_type: 'HRR',
    geo_name: 'Santa Barbara',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 44.3194859529636,
    forecast_lo_95: 17.8054385662326,
    forecast_hi_95: 70.8335333396946,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  87: {
    geo_id: '87',
    geo_type: 'HRR',
    geo_name: 'Santa Cruz',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 9.65285364513645,
    forecast_lo_95: 0.0,
    forecast_hi_95: 25.3864726995161,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  89: {
    geo_id: '89',
    geo_type: 'HRR',
    geo_name: 'Santa Rosa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 56.0,
    forecast_lo_95: 33.9168208544269,
    forecast_hi_95: 78.0831791455732,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  91: {
    geo_id: '91',
    geo_type: 'HRR',
    geo_name: 'Stockton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 54.5236819318903,
    forecast_lo_95: 0.0,
    forecast_hi_95: 110.704587793794,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  96: {
    geo_id: '96',
    geo_type: 'HRR',
    geo_name: 'Ventura',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 85.8554210249621,
    forecast_lo_95: 26.0943567805394,
    forecast_hi_95: 145.616485269385,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'California',
    geo_parent_code: 'CA',
  },
  101: {
    geo_id: '101',
    geo_type: 'HRR',
    geo_name: 'Boulder',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 24.1479759624362,
    forecast_lo_95: 0.0,
    forecast_hi_95: 61.0229267563781,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  102: {
    geo_id: '102',
    geo_type: 'HRR',
    geo_name: 'Colorado Springs',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 45.9943044721646,
    forecast_lo_95: 0.0,
    forecast_hi_95: 92.0401951904071,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  103: {
    geo_id: '103',
    geo_type: 'HRR',
    geo_name: 'Denver',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 153.932211014754,
    forecast_lo_95: 0.0,
    forecast_hi_95: 327.74557517733,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  104: {
    geo_id: '104',
    geo_type: 'HRR',
    geo_name: 'Fort Collins',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 31.7354241541657,
    forecast_lo_95: 2.3356885225564,
    forecast_hi_95: 61.135159785775,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  105: {
    geo_id: '105',
    geo_type: 'HRR',
    geo_name: 'Grand Junction',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.7339547976991,
    forecast_lo_95: 0.0,
    forecast_hi_95: 59.6334597682807,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  106: {
    geo_id: '106',
    geo_type: 'HRR',
    geo_name: 'Greeley',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 12.1080593996667,
    forecast_lo_95: 0.0,
    forecast_hi_95: 42.9509549022585,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  107: {
    geo_id: '107',
    geo_type: 'HRR',
    geo_name: 'Pueblo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 10.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 36.3997010275295,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Colorado',
    geo_parent_code: 'CO',
  },
  109: {
    geo_id: '109',
    geo_type: 'HRR',
    geo_name: 'Bridgeport',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 53.9762982900262,
    forecast_lo_95: 0.0,
    forecast_hi_95: 119.054854985272,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Connecticut',
    geo_parent_code: 'CT',
  },
  110: {
    geo_id: '110',
    geo_type: 'HRR',
    geo_name: 'Hartford',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 154.508072009027,
    forecast_lo_95: 79.499259598203,
    forecast_hi_95: 229.516884419851,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Connecticut',
    geo_parent_code: 'CT',
  },
  111: {
    geo_id: '111',
    geo_type: 'HRR',
    geo_name: 'New Haven',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 111.594873145045,
    forecast_lo_95: 10.0864931953292,
    forecast_hi_95: 213.10325309476,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Connecticut',
    geo_parent_code: 'CT',
  },
  112: {
    geo_id: '112',
    geo_type: 'HRR',
    geo_name: 'Wilmington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 73.5627042962896,
    forecast_lo_95: 3.48789128921997,
    forecast_hi_95: 143.637517303359,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Delaware',
    geo_parent_code: 'DE',
  },
  113: {
    geo_id: '113',
    geo_type: 'HRR',
    geo_name: 'Washington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 211.565906358463,
    forecast_lo_95: 49.8167399622642,
    forecast_hi_95: 373.315072754661,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'District of Columbia',
    geo_parent_code: 'DC',
  },
  115: {
    geo_id: '115',
    geo_type: 'HRR',
    geo_name: 'Bradenton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 23.5420713605678,
    forecast_lo_95: 0.0,
    forecast_hi_95: 56.322521024295,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  116: {
    geo_id: '116',
    geo_type: 'HRR',
    geo_name: 'Clearwater',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 142.345341739632,
    forecast_lo_95: 67.7728255911912,
    forecast_hi_95: 216.917857888073,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  118: {
    geo_id: '118',
    geo_type: 'HRR',
    geo_name: 'Fort Lauderdale',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 318.930884563145,
    forecast_lo_95: 55.7447671355752,
    forecast_hi_95: 582.117001990715,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  119: {
    geo_id: '119',
    geo_type: 'HRR',
    geo_name: 'Fort Myers',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 50.8271967426746,
    forecast_lo_95: 0.0,
    forecast_hi_95: 148.059117114278,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  120: {
    geo_id: '120',
    geo_type: 'HRR',
    geo_name: 'Gainesville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 123.110420699469,
    forecast_lo_95: 57.4656637385929,
    forecast_hi_95: 189.435617435002,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  122: {
    geo_id: '122',
    geo_type: 'HRR',
    geo_name: 'Hudson',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 95.34055640737,
    forecast_lo_95: 29.9541762846895,
    forecast_hi_95: 160.72693653005,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  123: {
    geo_id: '123',
    geo_type: 'HRR',
    geo_name: 'Jacksonville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 225.279107725646,
    forecast_lo_95: 35.7471695798609,
    forecast_hi_95: 414.811045871431,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  124: {
    geo_id: '124',
    geo_type: 'HRR',
    geo_name: 'Lakeland',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 50.021220576647,
    forecast_lo_95: 0.0,
    forecast_hi_95: 114.433478662337,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  127: {
    geo_id: '127',
    geo_type: 'HRR',
    geo_name: 'Miami',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 475.311960790731,
    forecast_lo_95: 190.501331031268,
    forecast_hi_95: 760.122590550194,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  129: {
    geo_id: '129',
    geo_type: 'HRR',
    geo_name: 'Ocala',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 72.6822988695888,
    forecast_lo_95: 21.0568890844755,
    forecast_hi_95: 124.307708654702,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  130: {
    geo_id: '130',
    geo_type: 'HRR',
    geo_name: 'Orlando',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 451.841937507923,
    forecast_lo_95: 178.169397957377,
    forecast_hi_95: 725.514477058468,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  131: {
    geo_id: '131',
    geo_type: 'HRR',
    geo_name: 'Ormond Beach',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 43.9900032695285,
    forecast_lo_95: 9.61363493188867,
    forecast_hi_95: 78.3663716071684,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  133: {
    geo_id: '133',
    geo_type: 'HRR',
    geo_name: 'Panama City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 7.02920864354817,
    forecast_lo_95: 0.0,
    forecast_hi_95: 37.4725074828187,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  134: {
    geo_id: '134',
    geo_type: 'HRR',
    geo_name: 'Pensacola',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 100.434109223216,
    forecast_lo_95: 14.1439504072493,
    forecast_hi_95: 186.724268039183,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  137: {
    geo_id: '137',
    geo_type: 'HRR',
    geo_name: 'Sarasota',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 90.688616206295,
    forecast_lo_95: 47.5423563137415,
    forecast_hi_95: 133.834876098849,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  139: {
    geo_id: '139',
    geo_type: 'HRR',
    geo_name: 'St. Petersburg',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 76.171711043327,
    forecast_lo_95: 22.3534571997994,
    forecast_hi_95: 129.989964886855,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  140: {
    geo_id: '140',
    geo_type: 'HRR',
    geo_name: 'Tallahassee',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 60.0934306158067,
    forecast_lo_95: 7.02132694678463,
    forecast_hi_95: 142.230760961682,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  141: {
    geo_id: '141',
    geo_type: 'HRR',
    geo_name: 'Tampa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 249.193385193992,
    forecast_lo_95: 95.657289054582,
    forecast_hi_95: 402.729481333403,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Florida',
    geo_parent_code: 'FL',
  },
  142: {
    geo_id: '142',
    geo_type: 'HRR',
    geo_name: 'Albany',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 21.6271942972664,
    forecast_lo_95: 0.0,
    forecast_hi_95: 57.1045866695334,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  144: {
    geo_id: '144',
    geo_type: 'HRR',
    geo_name: 'Atlanta',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 573.054693583441,
    forecast_lo_95: 157.830960575852,
    forecast_hi_95: 988.278426591029,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  145: {
    geo_id: '145',
    geo_type: 'HRR',
    geo_name: 'Augusta',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 54.5829382538331,
    forecast_lo_95: 0.0,
    forecast_hi_95: 204.333846097181,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  146: {
    geo_id: '146',
    geo_type: 'HRR',
    geo_name: 'Columbus',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 36.5217329163346,
    forecast_lo_95: 0.0,
    forecast_hi_95: 76.2283141643217,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  147: {
    geo_id: '147',
    geo_type: 'HRR',
    geo_name: 'Macon',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 104.563194294028,
    forecast_lo_95: 18.6365290518578,
    forecast_hi_95: 190.489859536198,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  148: {
    geo_id: '148',
    geo_type: 'HRR',
    geo_name: 'Rome',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.0948765853388,
    forecast_lo_95: 8.38466432620016,
    forecast_hi_95: 89.8050888444773,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  149: {
    geo_id: '149',
    geo_type: 'HRR',
    geo_name: 'Savannah',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.2731114908641,
    forecast_lo_95: 0.0,
    forecast_hi_95: 120.008816468625,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Georgia',
    geo_parent_code: 'GA',
  },
  150: {
    geo_id: '150',
    geo_type: 'HRR',
    geo_name: 'Honolulu',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 158.539591938279,
    forecast_lo_95: 92.1342789080201,
    forecast_hi_95: 224.944904968539,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Hawaii',
    geo_parent_code: 'HI',
  },
  151: {
    geo_id: '151',
    geo_type: 'HRR',
    geo_name: 'Boise',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 119.804833863778,
    forecast_lo_95: 65.6551636905561,
    forecast_hi_95: 173.954504037,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Idaho',
    geo_parent_code: 'ID',
  },
  152: {
    geo_id: '152',
    geo_type: 'HRR',
    geo_name: 'Idaho Falls',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 11.1488058712005,
    forecast_lo_95: 0.0,
    forecast_hi_95: 29.6935802004992,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Idaho',
    geo_parent_code: 'ID',
  },
  154: {
    geo_id: '154',
    geo_type: 'HRR',
    geo_name: 'Aurora',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 19.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 53.4985279244658,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  155: {
    geo_id: '155',
    geo_type: 'HRR',
    geo_name: 'Blue Island',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 86.3967373208948,
    forecast_lo_95: 22.8615607803582,
    forecast_hi_95: 149.931913861431,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  156: {
    geo_id: '156',
    geo_type: 'HRR',
    geo_name: 'Chicago',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 225.762798806788,
    forecast_lo_95: 39.2722421084101,
    forecast_hi_95: 412.253355505167,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  158: {
    geo_id: '158',
    geo_type: 'HRR',
    geo_name: 'Elgin',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 126.322728585995,
    forecast_lo_95: 74.0392135537839,
    forecast_hi_95: 178.606243618208,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  161: {
    geo_id: '161',
    geo_type: 'HRR',
    geo_name: 'Evanston',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 78.3666448163114,
    forecast_lo_95: 11.4478129315835,
    forecast_hi_95: 145.285476701039,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  163: {
    geo_id: '163',
    geo_type: 'HRR',
    geo_name: 'Hinsdale',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 71.5812967254964,
    forecast_lo_95: 23.6752439651174,
    forecast_hi_95: 118.742831222631,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  164: {
    geo_id: '164',
    geo_type: 'HRR',
    geo_name: 'Joliet',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 60.4564704843407,
    forecast_lo_95: 8.60949032375598,
    forecast_hi_95: 112.303450644925,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  166: {
    geo_id: '166',
    geo_type: 'HRR',
    geo_name: 'Melrose Park',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 113.076601513186,
    forecast_lo_95: 0.0,
    forecast_hi_95: 307.868809987334,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  170: {
    geo_id: '170',
    geo_type: 'HRR',
    geo_name: 'Peoria',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 70.5530866787988,
    forecast_lo_95: 15.9470034169888,
    forecast_hi_95: 125.159169940609,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  171: {
    geo_id: '171',
    geo_type: 'HRR',
    geo_name: 'Rockford',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 80.6237405745719,
    forecast_lo_95: 28.7728484827309,
    forecast_hi_95: 132.474632666413,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  172: {
    geo_id: '172',
    geo_type: 'HRR',
    geo_name: 'Springfield',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 86.8447897833287,
    forecast_lo_95: 20.675672428209,
    forecast_hi_95: 153.013907138448,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  173: {
    geo_id: '173',
    geo_type: 'HRR',
    geo_name: 'Urbana',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 58.0354092103994,
    forecast_lo_95: 18.6437463052919,
    forecast_hi_95: 97.4270721155068,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  175: {
    geo_id: '175',
    geo_type: 'HRR',
    geo_name: 'Bloomington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 11.8714623500518,
    forecast_lo_95: 0.0,
    forecast_hi_95: 31.8262562622506,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Illinois',
    geo_parent_code: 'IL',
  },
  179: {
    geo_id: '179',
    geo_type: 'HRR',
    geo_name: 'Evansville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 95.8854178463793,
    forecast_lo_95: 7.02132694678463,
    forecast_hi_95: 200.377513294396,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  180: {
    geo_id: '180',
    geo_type: 'HRR',
    geo_name: 'Fort Wayne',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 87.8248366052561,
    forecast_lo_95: 19.4295416716467,
    forecast_hi_95: 156.220131538866,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  181: {
    geo_id: '181',
    geo_type: 'HRR',
    geo_name: 'Gary',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 32.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 71.935261461528,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  183: {
    geo_id: '183',
    geo_type: 'HRR',
    geo_name: 'Indianapolis',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 202.97199271411,
    forecast_lo_95: 6.68951706658754,
    forecast_hi_95: 399.254468361633,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  184: {
    geo_id: '184',
    geo_type: 'HRR',
    geo_name: 'Lafayette',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 25.9247238597815,
    forecast_lo_95: 2.42585281449064,
    forecast_hi_95: 49.4235949050723,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  185: {
    geo_id: '185',
    geo_type: 'HRR',
    geo_name: 'Muncie',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 22.6987993890648,
    forecast_lo_95: 0.999918231550957,
    forecast_hi_95: 44.3976805465786,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  186: {
    geo_id: '186',
    geo_type: 'HRR',
    geo_name: 'Munster',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 18.5877256123181,
    forecast_lo_95: 0.0,
    forecast_hi_95: 50.3309021523105,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  187: {
    geo_id: '187',
    geo_type: 'HRR',
    geo_name: 'South Bend',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 62.8211271692174,
    forecast_lo_95: 20.7591433965545,
    forecast_hi_95: 104.88311094188,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  188: {
    geo_id: '188',
    geo_type: 'HRR',
    geo_name: 'Terre Haute',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 26.5778512772072,
    forecast_lo_95: 9.1521398334729,
    forecast_hi_95: 44.0035627209414,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Indiana',
    geo_parent_code: 'IN',
  },
  190: {
    geo_id: '190',
    geo_type: 'HRR',
    geo_name: 'Cedar Rapids',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 35.7865955041366,
    forecast_lo_95: 10.3112409445191,
    forecast_hi_95: 61.2619500637541,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  191: {
    geo_id: '191',
    geo_type: 'HRR',
    geo_name: 'Davenport',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 43.6968109519158,
    forecast_lo_95: 7.66674948414536,
    forecast_hi_95: 79.7268724196861,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  192: {
    geo_id: '192',
    geo_type: 'HRR',
    geo_name: 'Des Moines',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 111.943333743019,
    forecast_lo_95: 42.4913962755094,
    forecast_hi_95: 181.395271210528,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  193: {
    geo_id: '193',
    geo_type: 'HRR',
    geo_name: 'Dubuque',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 23.4132832124922,
    forecast_lo_95: 5.71461112413463,
    forecast_hi_95: 41.1119553008498,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  194: {
    geo_id: '194',
    geo_type: 'HRR',
    geo_name: 'Iowa City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 55.1373764388123,
    forecast_lo_95: 26.3513650370685,
    forecast_hi_95: 83.9233878405562,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  195: {
    geo_id: '195',
    geo_type: 'HRR',
    geo_name: 'Mason City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 20.0,
    forecast_lo_95: 2.21360646904814,
    forecast_hi_95: 37.7863935309519,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  196: {
    geo_id: '196',
    geo_type: 'HRR',
    geo_name: 'Sioux City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 30.4145555111777,
    forecast_lo_95: 3.20047086539662,
    forecast_hi_95: 57.6286401569587,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  197: {
    geo_id: '197',
    geo_type: 'HRR',
    geo_name: 'Waterloo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 32.2098786312413,
    forecast_lo_95: 10.3803642032612,
    forecast_hi_95: 54.0393930592215,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Iowa',
    geo_parent_code: 'IA',
  },
  200: {
    geo_id: '200',
    geo_type: 'HRR',
    geo_name: 'Topeka',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 41.7181850941367,
    forecast_lo_95: 4.59266973803498,
    forecast_hi_95: 78.8437004502384,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kansas',
    geo_parent_code: 'KS',
  },
  201: {
    geo_id: '201',
    geo_type: 'HRR',
    geo_name: 'Wichita',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 113.131551030572,
    forecast_lo_95: 19.9177034099921,
    forecast_hi_95: 206.345398651153,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kansas',
    geo_parent_code: 'KS',
  },
  203: {
    geo_id: '203',
    geo_type: 'HRR',
    geo_name: 'Covington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 67.084150006036,
    forecast_lo_95: 33.9496420365037,
    forecast_hi_95: 100.218657975568,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kentucky',
    geo_parent_code: 'KY',
  },
  204: {
    geo_id: '204',
    geo_type: 'HRR',
    geo_name: 'Lexington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 430.719291191652,
    forecast_lo_95: 57.742126362864,
    forecast_hi_95: 803.69645602044,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kentucky',
    geo_parent_code: 'KY',
  },
  205: {
    geo_id: '205',
    geo_type: 'HRR',
    geo_name: 'Louisville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 219.727789639478,
    forecast_lo_95: 39.5250302521267,
    forecast_hi_95: 399.93054902683,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kentucky',
    geo_parent_code: 'KY',
  },
  207: {
    geo_id: '207',
    geo_type: 'HRR',
    geo_name: 'Owensboro',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 24.0,
    forecast_lo_95: 6.9907392568075,
    forecast_hi_95: 40.978933118426,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kentucky',
    geo_parent_code: 'KY',
  },
  208: {
    geo_id: '208',
    geo_type: 'HRR',
    geo_name: 'Paducah',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 36.0832847455056,
    forecast_lo_95: 0.0,
    forecast_hi_95: 111.414994293244,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Kentucky',
    geo_parent_code: 'KY',
  },
  209: {
    geo_id: '209',
    geo_type: 'HRR',
    geo_name: 'Alexandria',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 47.771803002327,
    forecast_lo_95: 9.38949504087882,
    forecast_hi_95: 87.3744923315308,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  210: {
    geo_id: '210',
    geo_type: 'HRR',
    geo_name: 'Baton Rouge',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 33.6517353910575,
    forecast_lo_95: 0.0,
    forecast_hi_95: 88.6080626465324,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  212: {
    geo_id: '212',
    geo_type: 'HRR',
    geo_name: 'Houma',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 16.1964955515327,
    forecast_lo_95: 0.0,
    forecast_hi_95: 50.010973017749,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  213: {
    geo_id: '213',
    geo_type: 'HRR',
    geo_name: 'Lafayette',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 54.2507173233154,
    forecast_lo_95: 5.70317250615888,
    forecast_hi_95: 102.798262140472,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  214: {
    geo_id: '214',
    geo_type: 'HRR',
    geo_name: 'Lake Charles',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 22.0826056245346,
    forecast_lo_95: 0.0,
    forecast_hi_95: 51.4709272222713,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  216: {
    geo_id: '216',
    geo_type: 'HRR',
    geo_name: 'Metairie',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 59.8024372565347,
    forecast_lo_95: 10.3475572778742,
    forecast_hi_95: 109.257317235195,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  217: {
    geo_id: '217',
    geo_type: 'HRR',
    geo_name: 'Monroe',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 55.7665959896294,
    forecast_lo_95: 1.72358905188316,
    forecast_hi_95: 109.809602927376,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  218: {
    geo_id: '218',
    geo_type: 'HRR',
    geo_name: 'New Orleans',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 47.3599549285367,
    forecast_lo_95: 0.0,
    forecast_hi_95: 127.044769757637,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  219: {
    geo_id: '219',
    geo_type: 'HRR',
    geo_name: 'Shreveport',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 96.2939521858369,
    forecast_lo_95: 32.1600993066838,
    forecast_hi_95: 160.42780506499,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  220: {
    geo_id: '220',
    geo_type: 'HRR',
    geo_name: 'Slidell',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 11.1224436231022,
    forecast_lo_95: 0.0,
    forecast_hi_95: 38.8269574354309,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Louisiana',
    geo_parent_code: 'LA',
  },
  221: {
    geo_id: '221',
    geo_type: 'HRR',
    geo_name: 'Bangor',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 50.0,
    forecast_lo_95: 26.4146785400401,
    forecast_hi_95: 73.5853214599599,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Maine',
    geo_parent_code: 'ME',
  },
  222: {
    geo_id: '222',
    geo_type: 'HRR',
    geo_name: 'Portland',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 79.0,
    forecast_lo_95: 41.9513868344622,
    forecast_hi_95: 116.353662060735,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Maine',
    geo_parent_code: 'ME',
  },
  223: {
    geo_id: '223',
    geo_type: 'HRR',
    geo_name: 'Baltimore',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 175.676541419216,
    forecast_lo_95: 8.00353196213171,
    forecast_hi_95: 344.230559848297,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Maryland',
    geo_parent_code: 'MD',
  },
  225: {
    geo_id: '225',
    geo_type: 'HRR',
    geo_name: 'Salisbury',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 57.5997464690707,
    forecast_lo_95: 9.33998680649185,
    forecast_hi_95: 105.85950613165,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Maryland',
    geo_parent_code: 'MD',
  },
  226: {
    geo_id: '226',
    geo_type: 'HRR',
    geo_name: 'Takoma Park',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 50.7714109827709,
    forecast_lo_95: 0.0,
    forecast_hi_95: 102.326532737211,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Maryland',
    geo_parent_code: 'MD',
  },
  227: {
    geo_id: '227',
    geo_type: 'HRR',
    geo_name: 'Boston',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 453.796332456475,
    forecast_lo_95: 248.89944498643,
    forecast_hi_95: 658.69321992652,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Massachusetts',
    geo_parent_code: 'MA',
  },
  230: {
    geo_id: '230',
    geo_type: 'HRR',
    geo_name: 'Springfield',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 33.7684368614781,
    forecast_lo_95: 2.4217031319255,
    forecast_hi_95: 65.1151705910307,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Massachusetts',
    geo_parent_code: 'MA',
  },
  231: {
    geo_id: '231',
    geo_type: 'HRR',
    geo_name: 'Worcester',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 60.7945498725927,
    forecast_lo_95: 9.89813496346207,
    forecast_hi_95: 111.690964781723,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Massachusetts',
    geo_parent_code: 'MA',
  },
  232: {
    geo_id: '232',
    geo_type: 'HRR',
    geo_name: 'Ann Arbor',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 160.035176767861,
    forecast_lo_95: 60.3069053653971,
    forecast_hi_95: 259.763448170324,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  233: {
    geo_id: '233',
    geo_type: 'HRR',
    geo_name: 'Dearborn',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 38.5559163823125,
    forecast_lo_95: 0.0,
    forecast_hi_95: 86.5444713174429,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  234: {
    geo_id: '234',
    geo_type: 'HRR',
    geo_name: 'Detroit',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 162.33252343462,
    forecast_lo_95: 38.5478714554469,
    forecast_hi_95: 286.117175413794,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  235: {
    geo_id: '235',
    geo_type: 'HRR',
    geo_name: 'Flint',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 60.4823322663527,
    forecast_lo_95: 13.5960095236703,
    forecast_hi_95: 107.368655009035,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  236: {
    geo_id: '236',
    geo_type: 'HRR',
    geo_name: 'Grand Rapids',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 146.953175807978,
    forecast_lo_95: 72.2666726231606,
    forecast_hi_95: 221.639678992796,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  238: {
    geo_id: '238',
    geo_type: 'HRR',
    geo_name: 'Kalamazoo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 67.4379607847424,
    forecast_lo_95: 27.9404756922899,
    forecast_hi_95: 106.935445877195,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  239: {
    geo_id: '239',
    geo_type: 'HRR',
    geo_name: 'Lansing',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 45.7004757811411,
    forecast_lo_95: 0.0,
    forecast_hi_95: 144.026261999185,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  240: {
    geo_id: '240',
    geo_type: 'HRR',
    geo_name: 'Marquette',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 22.1606805300513,
    forecast_lo_95: 3.83149681965211,
    forecast_hi_95: 40.4898642404506,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  242: {
    geo_id: '242',
    geo_type: 'HRR',
    geo_name: 'Muskegon',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 20.3376363313673,
    forecast_lo_95: 0.0,
    forecast_hi_95: 40.8510261141896,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  243: {
    geo_id: '243',
    geo_type: 'HRR',
    geo_name: 'Petoskey',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 6.27733267486023,
    forecast_lo_95: 0.0,
    forecast_hi_95: 20.7380862382985,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  244: {
    geo_id: '244',
    geo_type: 'HRR',
    geo_name: 'Pontiac',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 29.4358319895535,
    forecast_lo_95: 7.02132694678463,
    forecast_hi_95: 65.4726187326994,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  245: {
    geo_id: '245',
    geo_type: 'HRR',
    geo_name: 'Royal Oak',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 111.943468703699,
    forecast_lo_95: 17.9257176448473,
    forecast_hi_95: 205.961219762551,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  246: {
    geo_id: '246',
    geo_type: 'HRR',
    geo_name: 'Saginaw',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 114.104700303105,
    forecast_lo_95: 67.6163832719606,
    forecast_hi_95: 160.59301733425,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  248: {
    geo_id: '248',
    geo_type: 'HRR',
    geo_name: 'St. Joseph',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 26.9144780459783,
    forecast_lo_95: 7.62837736759531,
    forecast_hi_95: 46.2005787243614,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  249: {
    geo_id: '249',
    geo_type: 'HRR',
    geo_name: 'Traverse City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 19.9799967501278,
    forecast_lo_95: 0.0,
    forecast_hi_95: 40.7310259688896,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Michigan',
    geo_parent_code: 'MI',
  },
  250: {
    geo_id: '250',
    geo_type: 'HRR',
    geo_name: 'Duluth',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 34.6828058548488,
    forecast_lo_95: 5.32433029032905,
    forecast_hi_95: 64.0412814193685,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Minnesota',
    geo_parent_code: 'MN',
  },
  251: {
    geo_id: '251',
    geo_type: 'HRR',
    geo_name: 'Minneapolis',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 273.417840773216,
    forecast_lo_95: 158.497977590739,
    forecast_hi_95: 388.337703955692,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Minnesota',
    geo_parent_code: 'MN',
  },
  253: {
    geo_id: '253',
    geo_type: 'HRR',
    geo_name: 'Rochester',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 59.0,
    forecast_lo_95: 30.577460865014,
    forecast_hi_95: 87.422539134986,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Minnesota',
    geo_parent_code: 'MN',
  },
  254: {
    geo_id: '254',
    geo_type: 'HRR',
    geo_name: 'St. Cloud',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 12.5392541117213,
    forecast_lo_95: 0.0,
    forecast_hi_95: 35.2743881139917,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Minnesota',
    geo_parent_code: 'MN',
  },
  256: {
    geo_id: '256',
    geo_type: 'HRR',
    geo_name: 'St. Paul',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 114.54053184354,
    forecast_lo_95: 61.4265823172422,
    forecast_hi_95: 167.654481369838,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Minnesota',
    geo_parent_code: 'MN',
  },
  257: {
    geo_id: '257',
    geo_type: 'HRR',
    geo_name: 'Gulfport',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 25.7192976439215,
    forecast_lo_95: 5.79248794871373,
    forecast_hi_95: 45.6461073391292,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  258: {
    geo_id: '258',
    geo_type: 'HRR',
    geo_name: 'Hattiesburg',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 29.9016620108708,
    forecast_lo_95: 0.0,
    forecast_hi_95: 65.2173786969791,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  259: {
    geo_id: '259',
    geo_type: 'HRR',
    geo_name: 'Jackson',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 108.825341117759,
    forecast_lo_95: 30.4617758466037,
    forecast_hi_95: 187.188906388914,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  260: {
    geo_id: '260',
    geo_type: 'HRR',
    geo_name: 'Meridian',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 15.2000209012411,
    forecast_lo_95: 0.0,
    forecast_hi_95: 49.0544169932934,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  261: {
    geo_id: '261',
    geo_type: 'HRR',
    geo_name: 'Oxford',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 9.42214406012245,
    forecast_lo_95: 0.0,
    forecast_hi_95: 25.8254623922485,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  262: {
    geo_id: '262',
    geo_type: 'HRR',
    geo_name: 'Tupelo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 34.799062497914,
    forecast_lo_95: 0.0,
    forecast_hi_95: 83.5692019229561,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Mississippi',
    geo_parent_code: 'MS',
  },
  263: {
    geo_id: '263',
    geo_type: 'HRR',
    geo_name: 'Cape Girardeau',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 26.4680725493219,
    forecast_lo_95: 1.33922070834782,
    forecast_hi_95: 51.596924390296,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  264: {
    geo_id: '264',
    geo_type: 'HRR',
    geo_name: 'Columbia',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 65.2196476204739,
    forecast_lo_95: 12.0061814563295,
    forecast_hi_95: 118.433113784618,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  267: {
    geo_id: '267',
    geo_type: 'HRR',
    geo_name: 'Joplin',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.7894931711174,
    forecast_lo_95: 17.5783192780575,
    forecast_hi_95: 82.0006670641774,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  268: {
    geo_id: '268',
    geo_type: 'HRR',
    geo_name: 'Kansas City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 254.705520651843,
    forecast_lo_95: 114.875992390413,
    forecast_hi_95: 394.535048913272,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  270: {
    geo_id: '270',
    geo_type: 'HRR',
    geo_name: 'Springfield',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 78.4161526408993,
    forecast_lo_95: 27.7828313298883,
    forecast_hi_95: 129.04947395191,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  273: {
    geo_id: '273',
    geo_type: 'HRR',
    geo_name: 'St. Louis',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 459.211424987822,
    forecast_lo_95: 328.99554811277,
    forecast_hi_95: 589.427301862875,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Missouri',
    geo_parent_code: 'MO',
  },
  274: {
    geo_id: '274',
    geo_type: 'HRR',
    geo_name: 'Billings',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 74.1542551423996,
    forecast_lo_95: 0.0,
    forecast_hi_95: 151.368235426854,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Montana',
    geo_parent_code: 'MT',
  },
  275: {
    geo_id: '275',
    geo_type: 'HRR',
    geo_name: 'Great Falls',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 14.8587636376359,
    forecast_lo_95: 0.0,
    forecast_hi_95: 39.1314016882581,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Montana',
    geo_parent_code: 'MT',
  },
  276: {
    geo_id: '276',
    geo_type: 'HRR',
    geo_name: 'Missoula',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.0,
    forecast_lo_95: 7.44261902441387,
    forecast_hi_95: 90.5573809755861,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Montana',
    geo_parent_code: 'MT',
  },
  277: {
    geo_id: '277',
    geo_type: 'HRR',
    geo_name: 'Lincoln',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 45.7100917200332,
    forecast_lo_95: 1.78229822365142,
    forecast_hi_95: 89.637885216415,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Nebraska',
    geo_parent_code: 'NE',
  },
  278: {
    geo_id: '278',
    geo_type: 'HRR',
    geo_name: 'Omaha',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 114.697926407947,
    forecast_lo_95: 39.5749942729298,
    forecast_hi_95: 189.820858542965,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Nebraska',
    geo_parent_code: 'NE',
  },
  279: {
    geo_id: '279',
    geo_type: 'HRR',
    geo_name: 'Las Vegas',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 71.2507537283387,
    forecast_lo_95: 0.0,
    forecast_hi_95: 211.374296381537,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Nevada',
    geo_parent_code: 'NV',
  },
  280: {
    geo_id: '280',
    geo_type: 'HRR',
    geo_name: 'Reno',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 51.8696394006347,
    forecast_lo_95: 0.0,
    forecast_hi_95: 107.03333192147,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Nevada',
    geo_parent_code: 'NV',
  },
  281: {
    geo_id: '281',
    geo_type: 'HRR',
    geo_name: 'Lebanon',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 32.8666472329275,
    forecast_lo_95: 8.66153157145862,
    forecast_hi_95: 57.0717628943964,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Hampshire',
    geo_parent_code: 'NH',
  },
  282: {
    geo_id: '282',
    geo_type: 'HRR',
    geo_name: 'Manchester',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 76.1116649610913,
    forecast_lo_95: 25.0215293303668,
    forecast_hi_95: 127.201800591816,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Hampshire',
    geo_parent_code: 'NH',
  },
  283: {
    geo_id: '283',
    geo_type: 'HRR',
    geo_name: 'Camden',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 403.45398830867,
    forecast_lo_95: 177.564349171399,
    forecast_hi_95: 629.343627445942,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  284: {
    geo_id: '284',
    geo_type: 'HRR',
    geo_name: 'Hackensack',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 97.8234008708611,
    forecast_lo_95: 7.84642936610996,
    forecast_hi_95: 187.800372375612,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  285: {
    geo_id: '285',
    geo_type: 'HRR',
    geo_name: 'Morristown',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 67.077451310641,
    forecast_lo_95: 2.82480379440751,
    forecast_hi_95: 132.909049536488,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  288: {
    geo_id: '288',
    geo_type: 'HRR',
    geo_name: 'New Brunswick',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 114.646380749819,
    forecast_lo_95: 18.5628630598601,
    forecast_hi_95: 210.729898439777,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  289: {
    geo_id: '289',
    geo_type: 'HRR',
    geo_name: 'Newark',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 106.45578968057,
    forecast_lo_95: 4.26531278579569,
    forecast_hi_95: 208.646266575346,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  291: {
    geo_id: '291',
    geo_type: 'HRR',
    geo_name: 'Paterson',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 27.361731295889,
    forecast_lo_95: 0.0,
    forecast_hi_95: 70.2536577123892,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  292: {
    geo_id: '292',
    geo_type: 'HRR',
    geo_name: 'Ridgewood',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 48.7438067263726,
    forecast_lo_95: 9.88695970297459,
    forecast_hi_95: 87.6006537497706,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Jersey',
    geo_parent_code: 'NJ',
  },
  293: {
    geo_id: '293',
    geo_type: 'HRR',
    geo_name: 'Albuquerque',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 115.196172770562,
    forecast_lo_95: 54.0446686779149,
    forecast_hi_95: 176.347676863209,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New Mexico',
    geo_parent_code: 'NM',
  },
  295: {
    geo_id: '295',
    geo_type: 'HRR',
    geo_name: 'Albany',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 133.69078256448,
    forecast_lo_95: 44.2905878723297,
    forecast_hi_95: 223.09097725663,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  296: {
    geo_id: '296',
    geo_type: 'HRR',
    geo_name: 'Binghamton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 51.8768972357532,
    forecast_lo_95: 17.8117569133582,
    forecast_hi_95: 85.9420375581483,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  297: {
    geo_id: '297',
    geo_type: 'HRR',
    geo_name: 'Bronx',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 91.4730001527831,
    forecast_lo_95: 7.02132694678463,
    forecast_hi_95: 197.760351707081,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  299: {
    geo_id: '299',
    geo_type: 'HRR',
    geo_name: 'Buffalo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 141.179077878583,
    forecast_lo_95: 32.546722131266,
    forecast_hi_95: 249.811433625901,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  300: {
    geo_id: '300',
    geo_type: 'HRR',
    geo_name: 'Elmira',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 46.4814745732822,
    forecast_lo_95: 22.7271499737583,
    forecast_hi_95: 70.2357991728061,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  301: {
    geo_id: '301',
    geo_type: 'HRR',
    geo_name: 'East Long Island',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 288.94263154864,
    forecast_lo_95: 2.00705910499298,
    forecast_hi_95: 577.366388941325,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  303: {
    geo_id: '303',
    geo_type: 'HRR',
    geo_name: 'Manhattan',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 294.94291797745,
    forecast_lo_95: 7.02132694678463,
    forecast_hi_95: 619.090874882366,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  304: {
    geo_id: '304',
    geo_type: 'HRR',
    geo_name: 'Rochester',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 162.396845846788,
    forecast_lo_95: 46.8276749518667,
    forecast_hi_95: 277.966016741708,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  307: {
    geo_id: '307',
    geo_type: 'HRR',
    geo_name: 'Syracuse',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 104.944054926415,
    forecast_lo_95: 47.227536954202,
    forecast_hi_95: 162.660572898628,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  308: {
    geo_id: '308',
    geo_type: 'HRR',
    geo_name: 'White Plains',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 122.542337952402,
    forecast_lo_95: 31.317134057525,
    forecast_hi_95: 213.767541847279,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'New York',
    geo_parent_code: 'NY',
  },
  309: {
    geo_id: '309',
    geo_type: 'HRR',
    geo_name: 'Asheville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 17.9446078771148,
    forecast_lo_95: 0.0,
    forecast_hi_95: 55.2565904841623,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  311: {
    geo_id: '311',
    geo_type: 'HRR',
    geo_name: 'Charlotte',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 269.95386244241,
    forecast_lo_95: 149.963826739599,
    forecast_hi_95: 389.94389814522,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  312: {
    geo_id: '312',
    geo_type: 'HRR',
    geo_name: 'Durham',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 180.754537967697,
    forecast_lo_95: 87.588763624203,
    forecast_hi_95: 273.920312311191,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  313: {
    geo_id: '313',
    geo_type: 'HRR',
    geo_name: 'Greensboro',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 80.1518603729982,
    forecast_lo_95: 48.6637892661411,
    forecast_hi_95: 111.639931479855,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  314: {
    geo_id: '314',
    geo_type: 'HRR',
    geo_name: 'Greenville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 110.740367239057,
    forecast_lo_95: 35.0679240370911,
    forecast_hi_95: 186.412810441023,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  315: {
    geo_id: '315',
    geo_type: 'HRR',
    geo_name: 'Hickory',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 40.1906050417063,
    forecast_lo_95: 0.0,
    forecast_hi_95: 81.1082948383907,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  318: {
    geo_id: '318',
    geo_type: 'HRR',
    geo_name: 'Raleigh',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 129.149131819362,
    forecast_lo_95: 51.1329218549033,
    forecast_hi_95: 207.165341783822,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  319: {
    geo_id: '319',
    geo_type: 'HRR',
    geo_name: 'Wilmington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 42.7308365281286,
    forecast_lo_95: 5.94753411867125,
    forecast_hi_95: 79.5141389375859,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  320: {
    geo_id: '320',
    geo_type: 'HRR',
    geo_name: 'Winston-Salem',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 144.00660541021,
    forecast_lo_95: 73.5230160597104,
    forecast_hi_95: 214.490194760709,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Carolina',
    geo_parent_code: 'NC',
  },
  321: {
    geo_id: '321',
    geo_type: 'HRR',
    geo_name: 'Bismarck',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 31.1804858816206,
    forecast_lo_95: 8.95879169311321,
    forecast_hi_95: 53.4021800701279,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Dakota',
    geo_parent_code: 'ND',
  },
  322: {
    geo_id: '322',
    geo_type: 'HRR',
    geo_name: 'Fargo/Moorhead MN',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 50.0133644995252,
    forecast_lo_95: 16.9720488049488,
    forecast_hi_95: 83.0546801941017,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Dakota',
    geo_parent_code: 'ND',
  },
  323: {
    geo_id: '323',
    geo_type: 'HRR',
    geo_name: 'Grand Forks',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 4.88747331729985,
    forecast_lo_95: 0.0,
    forecast_hi_95: 22.3288158259572,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Dakota',
    geo_parent_code: 'ND',
  },
  324: {
    geo_id: '324',
    geo_type: 'HRR',
    geo_name: 'Minot',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 12.3910110671047,
    forecast_lo_95: 0.0,
    forecast_hi_95: 35.0725020804868,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'North Dakota',
    geo_parent_code: 'ND',
  },
  325: {
    geo_id: '325',
    geo_type: 'HRR',
    geo_name: 'Akron',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 58.4043126021099,
    forecast_lo_95: 6.09997714669777,
    forecast_hi_95: 110.708648057522,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  326: {
    geo_id: '326',
    geo_type: 'HRR',
    geo_name: 'Canton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 62.547577287741,
    forecast_lo_95: 4.51498603594499,
    forecast_hi_95: 120.580168539537,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  327: {
    geo_id: '327',
    geo_type: 'HRR',
    geo_name: 'Cincinnati',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 178.184871914296,
    forecast_lo_95: 76.3326173623687,
    forecast_hi_95: 280.037126466222,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  328: {
    geo_id: '328',
    geo_type: 'HRR',
    geo_name: 'Cleveland',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 307.130158692673,
    forecast_lo_95: 117.293351490709,
    forecast_hi_95: 496.966965894639,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  329: {
    geo_id: '329',
    geo_type: 'HRR',
    geo_name: 'Columbus',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 356.586815363711,
    forecast_lo_95: 209.398287473558,
    forecast_hi_95: 503.775343253864,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  330: {
    geo_id: '330',
    geo_type: 'HRR',
    geo_name: 'Dayton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 156.153287814402,
    forecast_lo_95: 53.0501640518111,
    forecast_hi_95: 259.256411576994,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  331: {
    geo_id: '331',
    geo_type: 'HRR',
    geo_name: 'Elyria',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 23.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 46.524598289249,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  332: {
    geo_id: '332',
    geo_type: 'HRR',
    geo_name: 'Kettering',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 47.066088991495,
    forecast_lo_95: 19.2900795787522,
    forecast_hi_95: 74.8420984042379,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  334: {
    geo_id: '334',
    geo_type: 'HRR',
    geo_name: 'Toledo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 165.424133801139,
    forecast_lo_95: 92.2495905775701,
    forecast_hi_95: 238.598677024707,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  335: {
    geo_id: '335',
    geo_type: 'HRR',
    geo_name: 'Youngstown',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 43.709504231245,
    forecast_lo_95: 0.0,
    forecast_hi_95: 110.877285794086,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Ohio',
    geo_parent_code: 'OH',
  },
  336: {
    geo_id: '336',
    geo_type: 'HRR',
    geo_name: 'Lawton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 21.2318949994512,
    forecast_lo_95: 0.0,
    forecast_hi_95: 117.407554037987,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oklahoma',
    geo_parent_code: 'OK',
  },
  339: {
    geo_id: '339',
    geo_type: 'HRR',
    geo_name: 'Oklahoma City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 240.967048314898,
    forecast_lo_95: 107.407861392227,
    forecast_hi_95: 374.52623523757,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oklahoma',
    geo_parent_code: 'OK',
  },
  340: {
    geo_id: '340',
    geo_type: 'HRR',
    geo_name: 'Tulsa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 107.194646258417,
    forecast_lo_95: 7.7459115323482,
    forecast_hi_95: 206.643380984486,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oklahoma',
    geo_parent_code: 'OK',
  },
  341: {
    geo_id: '341',
    geo_type: 'HRR',
    geo_name: 'Bend',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 3.0493077710536,
    forecast_lo_95: 0.0,
    forecast_hi_95: 21.5909676380183,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oregon',
    geo_parent_code: 'OR',
  },
  342: {
    geo_id: '342',
    geo_type: 'HRR',
    geo_name: 'Eugene',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.0,
    forecast_lo_95: 3.81551586475529,
    forecast_hi_95: 94.1844841352447,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oregon',
    geo_parent_code: 'OR',
  },
  343: {
    geo_id: '343',
    geo_type: 'HRR',
    geo_name: 'Medford',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 43.4194516240081,
    forecast_lo_95: 12.0824472250559,
    forecast_hi_95: 74.7564560229604,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oregon',
    geo_parent_code: 'OR',
  },
  344: {
    geo_id: '344',
    geo_type: 'HRR',
    geo_name: 'Portland',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 182.512558514146,
    forecast_lo_95: 110.932843835614,
    forecast_hi_95: 254.092273192679,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oregon',
    geo_parent_code: 'OR',
  },
  345: {
    geo_id: '345',
    geo_type: 'HRR',
    geo_name: 'Salem',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 27.5320355890432,
    forecast_lo_95: 0.0,
    forecast_hi_95: 55.4780315645986,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Oregon',
    geo_parent_code: 'OR',
  },
  346: {
    geo_id: '346',
    geo_type: 'HRR',
    geo_name: 'Allentown',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 112.137438794474,
    forecast_lo_95: 21.9614326371019,
    forecast_hi_95: 202.313444951846,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  347: {
    geo_id: '347',
    geo_type: 'HRR',
    geo_name: 'Altoona',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 29.6481382332532,
    forecast_lo_95: 0.0,
    forecast_hi_95: 60.5072879665169,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  350: {
    geo_id: '350',
    geo_type: 'HRR',
    geo_name: 'Danville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 86.0804672428457,
    forecast_lo_95: 11.279047835433,
    forecast_hi_95: 160.881886650258,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  351: {
    geo_id: '351',
    geo_type: 'HRR',
    geo_name: 'Erie',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 84.6045876588116,
    forecast_lo_95: 27.2152321788901,
    forecast_hi_95: 141.993943138733,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  352: {
    geo_id: '352',
    geo_type: 'HRR',
    geo_name: 'Harrisburg',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 102.232684433536,
    forecast_lo_95: 37.9924777550314,
    forecast_hi_95: 166.47289111204,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  354: {
    geo_id: '354',
    geo_type: 'HRR',
    geo_name: 'Johnstown',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 17.343937481358,
    forecast_lo_95: 0.0,
    forecast_hi_95: 49.1933957449253,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  355: {
    geo_id: '355',
    geo_type: 'HRR',
    geo_name: 'Lancaster',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 25.2110966090654,
    forecast_lo_95: 0.0,
    forecast_hi_95: 60.6941893639864,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  356: {
    geo_id: '356',
    geo_type: 'HRR',
    geo_name: 'Philadelphia',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 392.564336584873,
    forecast_lo_95: 94.136646842616,
    forecast_hi_95: 690.99202632713,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  357: {
    geo_id: '357',
    geo_type: 'HRR',
    geo_name: 'Pittsburgh',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 334.841253402337,
    forecast_lo_95: 192.176386310574,
    forecast_hi_95: 477.506120494099,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  358: {
    geo_id: '358',
    geo_type: 'HRR',
    geo_name: 'Reading',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 42.0687756611383,
    forecast_lo_95: 9.58117181220152,
    forecast_hi_95: 74.5563795100751,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  359: {
    geo_id: '359',
    geo_type: 'HRR',
    geo_name: 'Sayre',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.5905418321422,
    forecast_lo_95: 16.9057527041874,
    forecast_hi_95: 82.2753309600969,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  360: {
    geo_id: '360',
    geo_type: 'HRR',
    geo_name: 'Scranton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 17.770445566376,
    forecast_lo_95: 0.0,
    forecast_hi_95: 42.3822984767317,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  362: {
    geo_id: '362',
    geo_type: 'HRR',
    geo_name: 'Wilkes-Barre',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 60.9422789917389,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  363: {
    geo_id: '363',
    geo_type: 'HRR',
    geo_name: 'York',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 59.7838053796815,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Pennsylvania',
    geo_parent_code: 'PA',
  },
  364: {
    geo_id: '364',
    geo_type: 'HRR',
    geo_name: 'Providence',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 43.8918166086005,
    forecast_lo_95: 0.0,
    forecast_hi_95: 99.1116957716388,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Rhode Island',
    geo_parent_code: 'RI',
  },
  365: {
    geo_id: '365',
    geo_type: 'HRR',
    geo_name: 'Charleston',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 87.3941530905884,
    forecast_lo_95: 0.0,
    forecast_hi_95: 177.537724949799,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Carolina',
    geo_parent_code: 'SC',
  },
  366: {
    geo_id: '366',
    geo_type: 'HRR',
    geo_name: 'Columbia',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 92.5437591094929,
    forecast_lo_95: 7.54297071010176,
    forecast_hi_95: 177.544547508884,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Carolina',
    geo_parent_code: 'SC',
  },
  367: {
    geo_id: '367',
    geo_type: 'HRR',
    geo_name: 'Florence',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 48.9670417184074,
    forecast_lo_95: 6.56673394243096,
    forecast_hi_95: 91.3673494943839,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Carolina',
    geo_parent_code: 'SC',
  },
  368: {
    geo_id: '368',
    geo_type: 'HRR',
    geo_name: 'Greenville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 108.952268325531,
    forecast_lo_95: 29.8541844525035,
    forecast_hi_95: 188.050352198558,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Carolina',
    geo_parent_code: 'SC',
  },
  369: {
    geo_id: '369',
    geo_type: 'HRR',
    geo_name: 'Spartanburg',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 48.48904987139,
    forecast_lo_95: 5.18629219658409,
    forecast_hi_95: 91.7918075461959,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Carolina',
    geo_parent_code: 'SC',
  },
  370: {
    geo_id: '370',
    geo_type: 'HRR',
    geo_name: 'Rapid City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 25.7717564737028,
    forecast_lo_95: 0.439699193796628,
    forecast_hi_95: 51.1038137536089,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Dakota',
    geo_parent_code: 'SD',
  },
  371: {
    geo_id: '371',
    geo_type: 'HRR',
    geo_name: 'Sioux Falls',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 86.6140278788149,
    forecast_lo_95: 28.7910729173296,
    forecast_hi_95: 144.4369828403,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'South Dakota',
    geo_parent_code: 'SD',
  },
  373: {
    geo_id: '373',
    geo_type: 'HRR',
    geo_name: 'Chattanooga',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 102.959206719626,
    forecast_lo_95: 44.310180952692,
    forecast_hi_95: 161.60823248656,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  374: {
    geo_id: '374',
    geo_type: 'HRR',
    geo_name: 'Jackson',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.0062452604614,
    forecast_lo_95: 0.0,
    forecast_hi_95: 63.5239029930864,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  375: {
    geo_id: '375',
    geo_type: 'HRR',
    geo_name: 'Johnson City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 62.0,
    forecast_lo_95: 25.2895838466238,
    forecast_hi_95: 98.7104161533762,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  376: {
    geo_id: '376',
    geo_type: 'HRR',
    geo_name: 'Kingsport',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 71.3725845788023,
    forecast_lo_95: 25.060368685139,
    forecast_hi_95: 117.684800472466,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  377: {
    geo_id: '377',
    geo_type: 'HRR',
    geo_name: 'Knoxville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 131.091525091585,
    forecast_lo_95: 47.333271215171,
    forecast_hi_95: 214.849778968,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  379: {
    geo_id: '379',
    geo_type: 'HRR',
    geo_name: 'Memphis',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 118.433796562313,
    forecast_lo_95: 7.46168253543593,
    forecast_hi_95: 229.405910589191,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  380: {
    geo_id: '380',
    geo_type: 'HRR',
    geo_name: 'Nashville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 254.390258833522,
    forecast_lo_95: 85.1171193996088,
    forecast_hi_95: 423.663398267434,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Tennessee',
    geo_parent_code: 'TN',
  },
  382: {
    geo_id: '382',
    geo_type: 'HRR',
    geo_name: 'Abilene',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 33.1121597544315,
    forecast_lo_95: 0.0,
    forecast_hi_95: 78.2087306182423,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  383: {
    geo_id: '383',
    geo_type: 'HRR',
    geo_name: 'Amarillo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.9589850345529,
    forecast_lo_95: 0.0,
    forecast_hi_95: 81.1376034733376,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  385: {
    geo_id: '385',
    geo_type: 'HRR',
    geo_name: 'Austin',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 182.382762213816,
    forecast_lo_95: 56.1183130817827,
    forecast_hi_95: 308.647211345849,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  386: {
    geo_id: '386',
    geo_type: 'HRR',
    geo_name: 'Beaumont',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 24.5879903298183,
    forecast_lo_95: 0.0,
    forecast_hi_95: 70.3775462840067,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  388: {
    geo_id: '388',
    geo_type: 'HRR',
    geo_name: 'Bryan',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 36.9727932429374,
    forecast_lo_95: 0.0,
    forecast_hi_95: 90.5465108475991,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  390: {
    geo_id: '390',
    geo_type: 'HRR',
    geo_name: 'Corpus Christi',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 81.2872499138639,
    forecast_lo_95: 27.762800384408,
    forecast_hi_95: 134.81169944332,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  391: {
    geo_id: '391',
    geo_type: 'HRR',
    geo_name: 'Dallas',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 375.183638344018,
    forecast_lo_95: 8.54418916932991,
    forecast_hi_95: 741.823087518707,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  393: {
    geo_id: '393',
    geo_type: 'HRR',
    geo_name: 'El Paso',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 70.4729341238819,
    forecast_lo_95: 0.0,
    forecast_hi_95: 176.678014588495,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  394: {
    geo_id: '394',
    geo_type: 'HRR',
    geo_name: 'Fort Worth',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 350.110179897392,
    forecast_lo_95: 129.055829116855,
    forecast_hi_95: 571.164530677929,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  396: {
    geo_id: '396',
    geo_type: 'HRR',
    geo_name: 'Harlingen',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 46.1462977256616,
    forecast_lo_95: 0.320125231904427,
    forecast_hi_95: 91.9724702194188,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  397: {
    geo_id: '397',
    geo_type: 'HRR',
    geo_name: 'Houston',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 813.894234987476,
    forecast_lo_95: 419.922744107739,
    forecast_hi_95: 1207.86572586721,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  399: {
    geo_id: '399',
    geo_type: 'HRR',
    geo_name: 'Longview',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 1.06876372703523,
    forecast_lo_95: 0.0,
    forecast_hi_95: 21.3305299425151,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  400: {
    geo_id: '400',
    geo_type: 'HRR',
    geo_name: 'Lubbock',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 54.2892187173349,
    forecast_lo_95: 0.0,
    forecast_hi_95: 130.720554705022,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  402: {
    geo_id: '402',
    geo_type: 'HRR',
    geo_name: 'McAllen',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 119.799188131248,
    forecast_lo_95: 48.6241232144772,
    forecast_hi_95: 190.974253048019,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  406: {
    geo_id: '406',
    geo_type: 'HRR',
    geo_name: 'Odessa',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 26.27797338543,
    forecast_lo_95: 0.0,
    forecast_hi_95: 71.9963037483339,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  411: {
    geo_id: '411',
    geo_type: 'HRR',
    geo_name: 'San Angelo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 21.0049570749612,
    forecast_lo_95: 0.0,
    forecast_hi_95: 48.6166340977017,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  412: {
    geo_id: '412',
    geo_type: 'HRR',
    geo_name: 'San Antonio',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 257.150674907833,
    forecast_lo_95: 63.4083443088517,
    forecast_hi_95: 450.893005506815,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  413: {
    geo_id: '413',
    geo_type: 'HRR',
    geo_name: 'Temple',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 58.0072653357846,
    forecast_lo_95: 0.0,
    forecast_hi_95: 128.93932095578,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  416: {
    geo_id: '416',
    geo_type: 'HRR',
    geo_name: 'Tyler',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 46.1680651100762,
    forecast_lo_95: 0.0,
    forecast_hi_95: 129.674320007726,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  417: {
    geo_id: '417',
    geo_type: 'HRR',
    geo_name: 'Victoria',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 24.2220919839473,
    forecast_lo_95: 0.0,
    forecast_hi_95: 66.5549227234256,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  418: {
    geo_id: '418',
    geo_type: 'HRR',
    geo_name: 'Waco',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 27.7960479495889,
    forecast_lo_95: 0.0,
    forecast_hi_95: 76.9957121671139,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  420: {
    geo_id: '420',
    geo_type: 'HRR',
    geo_name: 'Wichita Falls',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 27.4551082993356,
    forecast_lo_95: 0.0,
    forecast_hi_95: 62.0891759539321,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Texas',
    geo_parent_code: 'TX',
  },
  421: {
    geo_id: '421',
    geo_type: 'HRR',
    geo_name: 'Ogden',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 38.2985767935071,
    forecast_lo_95: 8.56734422000264,
    forecast_hi_95: 68.0298093670116,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Utah',
    geo_parent_code: 'UT',
  },
  422: {
    geo_id: '422',
    geo_type: 'HRR',
    geo_name: 'Provo',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 34.6594066642275,
    forecast_lo_95: 0.721468363322465,
    forecast_hi_95: 68.5973449651326,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Utah',
    geo_parent_code: 'UT',
  },
  423: {
    geo_id: '423',
    geo_type: 'HRR',
    geo_name: 'Salt Lake City',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 109.156016545312,
    forecast_lo_95: 16.9877454994268,
    forecast_hi_95: 201.324287591197,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Utah',
    geo_parent_code: 'UT',
  },
  424: {
    geo_id: '424',
    geo_type: 'HRR',
    geo_name: 'Burlington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 48.078538230522,
    forecast_lo_95: 19.8357282737461,
    forecast_hi_95: 76.3213481872978,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Vermont',
    geo_parent_code: 'VT',
  },
  426: {
    geo_id: '426',
    geo_type: 'HRR',
    geo_name: 'Arlington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 180.295631153818,
    forecast_lo_95: 94.1919142622646,
    forecast_hi_95: 266.399348045372,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  427: {
    geo_id: '427',
    geo_type: 'HRR',
    geo_name: 'Charlottesville',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 94.6753822026863,
    forecast_lo_95: 51.4772564610677,
    forecast_hi_95: 137.873507944305,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  428: {
    geo_id: '428',
    geo_type: 'HRR',
    geo_name: 'Lynchburg',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 28.9100640897753,
    forecast_lo_95: 8.29176633441541,
    forecast_hi_95: 49.5283618451351,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  429: {
    geo_id: '429',
    geo_type: 'HRR',
    geo_name: 'Newport News',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 14.6922272631807,
    forecast_lo_95: 0.0,
    forecast_hi_95: 39.4332495524282,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  430: {
    geo_id: '430',
    geo_type: 'HRR',
    geo_name: 'Norfolk',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 87.6730528526107,
    forecast_lo_95: 7.10786770283694,
    forecast_hi_95: 168.238238002384,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  431: {
    geo_id: '431',
    geo_type: 'HRR',
    geo_name: 'Richmond',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 224.522850682237,
    forecast_lo_95: 116.852693131209,
    forecast_hi_95: 332.193008233265,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  432: {
    geo_id: '432',
    geo_type: 'HRR',
    geo_name: 'Roanoke',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 107.958025176663,
    forecast_lo_95: 62.7669938384377,
    forecast_hi_95: 153.149056514888,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  435: {
    geo_id: '435',
    geo_type: 'HRR',
    geo_name: 'Winchester',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 49.2251032466129,
    forecast_lo_95: 14.7051145235853,
    forecast_hi_95: 83.7450919696406,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Virginia',
    geo_parent_code: 'VA',
  },
  437: {
    geo_id: '437',
    geo_type: 'HRR',
    geo_name: 'Everett',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 40.2336480852772,
    forecast_lo_95: 12.1085899199937,
    forecast_hi_95: 68.3587062505607,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  438: {
    geo_id: '438',
    geo_type: 'HRR',
    geo_name: 'Olympia',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 18.4174160884937,
    forecast_lo_95: 0.0,
    forecast_hi_95: 44.9249722722231,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  439: {
    geo_id: '439',
    geo_type: 'HRR',
    geo_name: 'Seattle',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 165.943381125444,
    forecast_lo_95: 81.7768871950314,
    forecast_hi_95: 250.109875055856,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  440: {
    geo_id: '440',
    geo_type: 'HRR',
    geo_name: 'Spokane',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 107.276981002617,
    forecast_lo_95: 41.9392202791461,
    forecast_hi_95: 172.614741726088,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  441: {
    geo_id: '441',
    geo_type: 'HRR',
    geo_name: 'Tacoma',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 53.4260950359922,
    forecast_lo_95: 7.03361540744575,
    forecast_hi_95: 99.8185746645387,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  442: {
    geo_id: '442',
    geo_type: 'HRR',
    geo_name: 'Yakima',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 8.80601044096684,
    forecast_lo_95: 0.0,
    forecast_hi_95: 28.4714544094319,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Washington',
    geo_parent_code: 'WA',
  },
  443: {
    geo_id: '443',
    geo_type: 'HRR',
    geo_name: 'Charleston',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 129.598558313002,
    forecast_lo_95: 81.6713788593577,
    forecast_hi_95: 177.525737766647,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'West Virginia',
    geo_parent_code: 'WV',
  },
  444: {
    geo_id: '444',
    geo_type: 'HRR',
    geo_name: 'Huntington',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 84.498419146467,
    forecast_lo_95: 36.3412558396893,
    forecast_hi_95: 132.655582453245,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'West Virginia',
    geo_parent_code: 'WV',
  },
  445: {
    geo_id: '445',
    geo_type: 'HRR',
    geo_name: 'Morgantown',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 80.2154211416605,
    forecast_lo_95: 50.9431658881314,
    forecast_hi_95: 109.48767639519,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'West Virginia',
    geo_parent_code: 'WV',
  },
  446: {
    geo_id: '446',
    geo_type: 'HRR',
    geo_name: 'Appleton',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 3.31262460393691,
    forecast_lo_95: 0.0,
    forecast_hi_95: 22.2284001884255,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  447: {
    geo_id: '447',
    geo_type: 'HRR',
    geo_name: 'Green Bay',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 63.1887367954988,
    forecast_lo_95: 27.3479522205325,
    forecast_hi_95: 99.0295213704651,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  448: {
    geo_id: '448',
    geo_type: 'HRR',
    geo_name: 'La Crosse',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 35.7755607181326,
    forecast_lo_95: 9.61606288374491,
    forecast_hi_95: 61.9350585525202,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  449: {
    geo_id: '449',
    geo_type: 'HRR',
    geo_name: 'Madison',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 111.62633520329,
    forecast_lo_95: 46.4307581382035,
    forecast_hi_95: 176.821912268378,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  450: {
    geo_id: '450',
    geo_type: 'HRR',
    geo_name: 'Marshfield',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 35.0,
    forecast_lo_95: 0.0,
    forecast_hi_95: 80.2648452240127,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  451: {
    geo_id: '451',
    geo_type: 'HRR',
    geo_name: 'Milwaukee',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 279.283482478807,
    forecast_lo_95: 96.3836638638967,
    forecast_hi_95: 462.183301093718,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  452: {
    geo_id: '452',
    geo_type: 'HRR',
    geo_name: 'Neenah',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 6.69495395538629,
    forecast_lo_95: 0.0,
    forecast_hi_95: 21.2419791795266,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  456: {
    geo_id: '456',
    geo_type: 'HRR',
    geo_name: 'Wausau',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 25.9386845605969,
    forecast_lo_95: 4.04255211589112,
    forecast_hi_95: 47.8348170053027,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wisconsin',
    geo_parent_code: 'WI',
  },
  457: {
    geo_id: '457',
    geo_type: 'HRR',
    geo_name: 'Casper',
    start_date: '2022-09-08T00:00:00',
    end_date: '2022-09-14T00:00:00',
    data_type: 'Weekly_hhs',
    hospital_admissions: 8.78340023489743,
    forecast_lo_95: 0.0,
    forecast_hi_95: 36.0614841117392,
    load_date: '2022-09-13T00:00:00',
    table_type: 'forecast',
    geo_parent: 'Wyoming',
    geo_parent_code: 'WY',
  },
}
export default HRRDefaults
