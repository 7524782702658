import React from 'react'
import styles from './AgeDistributionGraph.module.scss'
import BarChart from '../../components/BarChart'
import toolTipFormatter from '../../pages/FluVaccination/toolTipFormatter'
import dataDisplayOptions from '../../pages/FluVaccination/dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import GenderDistributionGraph from './GenderDistributionGraph'
import { startDate, endDate } from '../../pages/FluVaccination/dates'
import countingMethod from './../../utils/getCountingMethod'
const AgeDistributionGraph = ({ stateCode , getVulCountData}) => {
  
  var getdemographic = `getdemographic/${stateCode}`
  return (
    <>
    <div className={styles.base}>
      <div>
        Sample

        <div className={styles.genderPanel}>
          
         <span>
            Age Distribution            
             <span>
              Patients Count <strong> {} {countingMethod(getVulCountData.sampleAgePatientCount, 'fixed')} </strong>
            </span>
          </span> 
          <BarChart
            toolTipFormatter={toolTipFormatter}
            entity="vulnerability"
            location={getdemographic}
            locationDisplay="getdemographic"
            startDate={startDate}
            endDate={endDate}
            options={stateOptions}
            dataDisplayOptions={dataDisplayOptions}
            chartName="sampeChart"
          />
          <span className={styles.graphSource}>
            Source : <span>Medical and Pharmacy Claims Data</span>
          </span>
        </div>
      </div>
      <div>
        Population
        <div className={styles.vaccinePanel}>
          <span>
            Age Distribution            
            <span>
              Patients Count <strong> {countingMethod(getVulCountData.populationAgePatientCount, 'fixed')}</strong>
            </span>
          </span>
          <BarChart
            toolTipFormatter={toolTipFormatter}
            entity="vulnerability"
            location={getdemographic}
            locationDisplay="getdemographic"
            startDate={startDate}
            endDate={endDate}
            options={stateOptions}
            dataDisplayOptions={dataDisplayOptions}
            chartName="populationChart"
          />
          <span className={styles.graphSource}>
            Source : <span>Medical and Pharmacy Claims Data</span>
          </span>
        </div>
      </div>
    </div>
    <GenderDistributionGraph stateCode={stateCode} getVulCountData={getVulCountData}/>
</>
  )
}

export default AgeDistributionGraph
