import Highcharts from 'highcharts'

const legend = {
  title: {
    text: '',
  },
  align: 'left',
  verticalAlign: 'top',
  floating: true,
  layout: 'horizontal',
  valueDecimals: 0,
  backgroundColor: 'transparent',
  symbolRadius: 0,
  symbolHeight: 20,
  symbolWidth: 300,
  itemMarginBottom: 5,
  padding: 10,
  symbolPadding: 20,
  // reversed: true,
  width: 300,
}

export default legend
