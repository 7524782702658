import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import { Formik } from 'formik'

import TextBox from '../../../../components/TextBox'
import Label from '../../../../components/Label'
import ButtonList from '../../../../components/ButtonList'
import Button from '../../../../components/Button'
import DropDownList from '../../../../components/DropDownList'
import { useMessageToast } from '../../../../components/MessageProvider'
//import usMsaAll from '../../../../components/Map/cb_2018_us_cbsa_20m_wkid102004.json1'

import UserSelect from './userSelect'
import RegionSelect from './regionSelect'
import { useGeoState } from '../../../../components/GeoProvider'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './addEditSubscription.module.scss'
import Icon from '../../../../components/Icon'

const AddSubscriptionForm = ({ activeCbsas = {} , cbsaJson}) => {
  // Hello
  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([])
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const { showAddsubscribePop, setShowAddsubscribePop } = useGeoState(false)
  const subscriptionsUrl = `${baseUrl}/admin/subscriptions`
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

  const history = useHistory()
  const toast = useMessageToast()

  const filteredCbsasFlu = cbsaJson.features
    .filter((msa) => activeCbsas['FLU'].includes(msa.properties.cbsa))
    .map((msa) => {
      return {
        value: String(msa.properties.cbsa),
        label: msa.properties.name,
      }
    })

  const filteredCbsasCovid = cbsaJson.features
    .filter((msa) => activeCbsas['COVID-19'].includes(msa.properties.cbsa))
    .map((msa) => {
      return {
        value: String(msa.properties.cbsa),
        label: msa.properties.name,
      }
    })

  const handleSuccess = () => {
    toast.success(`Subscription added successfully`)
    //history.push(`/admin/subscriptions`)
    setShowAddsubscribePop(false)
    window.location.reload(true)
  }

  const handleError = (err) => {
    toast.error(`Error adding subscription`)
    window.newrelic.noticeError(`Error in admin/addsubscription `, err)
  }

  const resetRegions = (locationType, values, setFieldValue) => {
    setFieldValue('regionCategory', locationType)
    values.regions = []
  }

  const submitSubscription = (data, resetForm) => {
    let subscriptions = []
    data.regions.forEach((region) => {
      let record = {
        geo_id: region.value,
        geo_type: data.regionCategory,
        infection_type: data.infectionType,
        notification_type: data.subscriptionType,
        notification_to: data[data.subscriptionType],
        start_date: moment(data.startDate).format('YYYY-MM-DD'),
        end_date: moment(data.endDate).format('YYYY-MM-DD'),
      }
      subscriptions.push(record)
    })

    const payload = {
      [data.username['value']]: { subscriptions: subscriptions },
    }
    fetch(subscriptionsUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) throw Error('Failed to Add Subscription')
        handleSuccess()
        resetForm()
      })
      .catch((error) => {
        handleError(error)
      })
  }

  return (
    <div className={styles.details}>
      <Formik
        initialValues={{
          username: '',
          infectionType: 'FLU',
          subscriptionType: 'email',
          email: '',
          sms: '',
          startDate: moment().toDate(),
          endDate: moment().add(90, 'day').toDate(),
          regionCategory: 'STATE',
          regions: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .when('subscriptionType', {
              is: 'email',
              then: Yup.string()
                .trim()
                .required('Email field is required')
                .max(100, 'Email should be max 100 characters long.'),
            }),
          sms: Yup.string().when('subscriptionType', {
            is: 'sms',
            then: Yup.string()
              .trim()
              .required('Phone number is required')
              .matches(phoneRegExp, 'Phone number is not valid'),
          }),
          startDate: Yup.date()
            .required('Start Date is required.')
            .max(Yup.ref('endDate'), 'Start Date should be before End Date.')
            .typeError('Invalid Start date'),
          endDate: Yup.date()
            .required('End Date is required.')
            .min(new Date(), 'End Date should be after today.')
            .typeError('Invalid End date'),
          regions: Yup.array()
            .required('Region is required.')
            .min(1, 'Pick at least 1 region')
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          submitSubscription(values, resetForm)
          setSubmitting(false)
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = props

          return (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              onReset={handleReset}
            >
              <div>
              <button
                noValidate
                style={{ marginLeft: '90%' }}
                onClick={() => {
                  setShowAddsubscribePop(false)
                }}
              >
                <Icon type="remove" size={'1.5rem'} />
              </button>
              <div>
              <h1>Add Subscriptions</h1>
              <p style={{lineHeight:'4rem',fontWeight:'500', fontSize:'14.22px'}}>
                Enter Subscription details, Emails and SMS will be sent out
                every Monday.
              </p>
              </div>
                <UserSelect
                  value={values.username}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.username}
                  touched={touched.username}
                  setUsers={setUsers}
                />
              </div>
              <div style={{ display: 'flex' }}>
              <div style={{width:'100%'}}>
                <DropDownList
                  errorText={errors.access}
                  id="infectionType"
                  value={values.infectionType}
                  name="infectionType"
                  label={
                    <Label error={errors.access} htmlFor="infectionType">
                      <b>Infection Type</b>
                    </Label>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="95%"
                >
                  <DropDownList.Item value="FLU">FLU</DropDownList.Item>
                  <DropDownList.Item value="COVID-19">
                    COVID-19
                  </DropDownList.Item>
                </DropDownList>
              </div>
              <div style={{width:'100%'}}>
                <DropDownList
                  errorText={errors.access}
                  id="subscriptionType"
                  value={values.subscriptionType}
                  name="subscriptionType"
                  label={
                    <Label error={errors.access} htmlFor="subscriptionType">
                      <b>Subscription Type</b>
                    </Label>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="95%"
                >
                  <DropDownList.Item value="email">Email</DropDownList.Item>

                  <DropDownList.Item value="sms">SMS</DropDownList.Item>
                </DropDownList>
              </div>
              </div>
              
              <div style={{width:'100%'}}>
              {values.subscriptionType === 'email' && (
                <div>
                  <TextBox
                    errorText={touched.email ? errors.email : null}
                    id="email"
                    label={
                      <Label
                        error={touched.email && errors.email}
                        htmlFor="email"
                      >
                        <b>Email</b>
                      </Label>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.email}
                    width="97%"
                  />
                </div>
              )}
              {values.subscriptionType === 'sms' && (
                <div>
                  <TextBox
                    errorText={touched.sms ? errors.sms : null}
                    id="sms"
                    label={
                      <Label error={touched.sms && errors.sms} htmlFor="sms">
                        <b>Phone</b>
                      </Label>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.sms}
                    width="97%"
                  />
                </div>
              )}
             </div>
             <div style={{ display: 'flex' }}>
                  <div style={{ width: '45%' }}>
                    <Label
                      error={touched.startDate && errors.startDate}
                      htmlFor="startDate"
                    >
                      Start Date
                    </Label>
                    <DatePicker
                      selected={values.startDate}
                      dateFormat="MM/dd/yyyy"
                      name="startDate"
                      minDate={moment().toDate()}
                      maxDate={moment().add(365, 'day').toDate()}
                      placeholderText="Select a date"
                      onChange={(date) => setFieldValue('startDate', date)}
                      scrollableYearDropdown
                    />
                    {!!errors.startDate && touched.startDate && (
                      <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%', marginLeft: '22px' }}>
                    <Label
                      error={touched.endDate && errors.endDate}
                      htmlFor="endDate"
                    >
                      End Date
                    </Label>
                    <DatePicker
                      selected={values.endDate}
                      dateFormat="MM/dd/yyyy"
                      className="form-control"
                      name="endDate"
                      minDate={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)}
                      maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
                      placeholderText="Select a date"
                      onChange={(date) => setFieldValue('endDate', date)}
                    />
                    {!!errors.endDate && touched.endDate && (
                      <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {errors.endDate}
                      </div>
                    )}
                  </div>
                </div>
              <div>
                <DropDownList
                  errorText={errors.access}
                  id="regionCategory"
                  value={values.regionCategory}
                  name="regionCategory"
                  label={
                    <Label error={errors.access} htmlFor="regionCategory">
                      <b>Location Type</b>
                    </Label>
                  }
                  onChange={(locType) =>
                    resetRegions(locType.target.value, values, setFieldValue)
                  }
                  onBlur={handleBlur}
                  width="33%"
                >
                  <DropDownList.Item value="STATE">State</DropDownList.Item>
                  <DropDownList.Item value="CBSA">Metro Area</DropDownList.Item>
                </DropDownList>
              </div>
              <div>
                <RegionSelect
                  value={values.regions}
                  infectionType={values.infectionType}
                  regionCategory={values.regionCategory}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.regions}
                  touched={touched.regions}
                  filteredCbsasFlu={filteredCbsasFlu}
                  filteredCbsasCovid={filteredCbsasCovid}
                />
              </div>
              <ButtonList>
                <Button type="submit">Save</Button>
                <Button
                  type="reset"
                  onClick={() => history.push('/admin/subscriptions')}
                >
                  Cancel
                </Button>
              </ButtonList>              
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddSubscriptionForm
