import React from "react";

import styles from "./SelectInput.module.scss";

const PATHS = {
  DEFAULT:
    "M2,0 L22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,22 C24,23.1045695 23.1045695,24 22,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M2,2 L2,22 L22,22 L22,2 L2,2 Z",
  INDETERMINATE:
    "M2,0 L22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,22 C24,23.1045695 23.1045695,24 22,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M4,11 L4,13 L20,13 L20,11 L4,11 Z",
  SELECTED:
    "M2,0 L22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,22 C24,23.1045695 23.1045695,24 22,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M9.36396103,16.5563492 L4.41421356,11.6066017 L3,13.0208153 L9.36396103,19.3847763 L21.3847763,7.36396103 L19.9705627,5.94974747 L9.36396103,16.5563492 Z"
};

const CheckBox = ({ indeterminate: isIndeterminate, selected: isSelected }) => (
  <svg
    className={`${isIndeterminate ? styles.indeterminate : ""} ${
      isSelected ? styles.selected : ""
    }`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={
        isSelected
          ? PATHS.SELECTED
          : isIndeterminate
          ? PATHS.INDETERMINATE
          : PATHS.DEFAULT
      }
    />
  </svg>
);

export default CheckBox;
