import React, { useState, useEffect } from 'react'

import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import CovidDetailedView from './CovidDetailedView'
import Spinner from '../../../../components/Loader/Spinner'
const CovidDetailsComponent = ({ cbsaData,
  statesData,
  countyData,
  cbsaGraphData,
  stateGraphData,
  hash,
  cbsaJson,
  countyJson, }) => {  
  const {
    dataStartDate,
    dataEndDate,

  } = useGeoState()
  const { user } = useAuthState()

  const [covidErrorMsaData, setCovidErrorMsaData] = useState()
  const { data: errMsaData, error: errMsaDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covid',
    'errormetric/msa'
  )
  useEffect(() => {
    if (errMsaData !== undefined || errMsaData === null) {
      if (errMsaData.response_code === 200) {
        setCovidErrorMsaData(errMsaData.response_data)
      } else {
        // setErrorCode(errMsaData.response_code)
        // setErrorMessage(errMsaData.response_message)
        // setErrorSubject(errMsaData.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [errMsaData])



  return (
    <div>
      {covidErrorMsaData !== undefined ? (

        <CovidDetailedView
          cbsaData={null}
          statesData={statesData}
          msaData={covidErrorMsaData}
          countyData={countyData}
          cbsaGraphData={cbsaGraphData}
          stateGraphData={stateGraphData}
          hash={hash}
          cbsaJson={cbsaJson}
          countyJson={countyJson}
        />

      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default CovidDetailsComponent