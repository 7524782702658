const geoTypeDisplay = (geoType) => {
  let displayGeoType = geoType
  switch (geoType.toUpperCase()) {
    case 'CBSA':
    case 'CITY':
      displayGeoType = 'Metro Area'
      break
    case 'STATE':
      displayGeoType = 'State'
      break
    case 'COUNTY':
      displayGeoType = 'County'
      break
    default:
      break
  }

  return displayGeoType
}

export default geoTypeDisplay
