import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import getLineChartOptions from './getLineChartOptions'
import styles from './LineChartDisplay.module.scss'
if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const LineChartDisplay = ({
  data,
  height,
  width,
  type,
  yaxisName,
}) => {


  var hydratedData = data
  const chartOptions = getLineChartOptions(
    height,
    width,
    type,
    hydratedData,
    yaxisName
  )

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    
    <div></div>
  )
}

export default LineChartDisplay
