import moment from 'moment'
import Highcharts from 'highcharts'

function getHorizontalBarChartOptions(
  toolTipFormatter,
  data,
  trendName,
  finalData,
  height,
  chartName
) {
  return {
    chart: {
      type: 'bar',
      height: height,
    },
    title: {
      text: '',
      align: 'left',
    },
    credits: {
      enabled: false,
    },

    legend: {},
    yAxis: [
      {
        min: 0,
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: 'Patient Count (in Thousands)',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },

        opposite: true,
      },
    ],
    xAxis: {
      categories: finalData[0].data,
      crosshair: true,
      labels: {
        useHTML: true,
        formatter: function () {
          if (chartName === 'chart1') {
            return (
              '<a target="_blank" href="/Vaccinations/fluvaccinationdetails?state=' +
              this.value +
              '">' +
              this.value +
              '</a>'
            )
          } else {
            return this.value.toString().substring(0, 15)
          }
        },
      },
      //opposite: true
    },
    tooltip: {
      //backgroundColor:'#ffffff',
      // borderWidth:1,
      // backgroundColor: "rgba(255,255,255,0)",
      // borderRadius: 2,
      // shadow: false,
      // useHTML: true,
      
      //formatter: function () {
      //    return '<b>' + this.x + '</b><br/>' +
      //        this.series.name + ': ' + this.y + '%<br/>'

      //},
      formatter: function () {
        if (this.y * 1000 > 1000000) {
          return (
            '<b>' +
            this.x +
            ' : </b>' +
            Highcharts.numberFormat((this.y * 1000) / 1000000, 3) +
            'M'
          )
        } else if (this.y * 1000 > 1000) {
          return (
            '<b>' +
            this.x +
            ' : </b>' +
            Highcharts.numberFormat((this.y * 1000) / 1000, 3) +
            'K'
          )
        } else {
          return '<b>' + this.x + ' : </b>' + this.y * 1000
        }
      },      
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.y * 1000 > 1000000) {
              return Highcharts.numberFormat((this.y * 1000) / 1000000, 3) + 'M'
            } else if (this.y * 1000 > 1000) {
              return Highcharts.numberFormat((this.y * 1000) / 1000, 3) + 'K'
            } else {
              return this.y * 1000
            }
          },
        },
        name: '',
        type: 'bar',
        showInLegend: false,
        color: '#003C71',
        yAxis: 1,
        data: finalData[1].data,
      },
    ],
    onsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            // maxHeight: 100,
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
}

export default getHorizontalBarChartOptions
