import dayjs from 'dayjs'

// export const startDate = dayjs()
//   .day(0)
//   .subtract(9 * 7, 'day') // 9 weeks
//   .format('YYYY-MM-DD')
// export const endDate = dayjs()
//   .subtract(1 * 7, 'day') // 1 week
//   .format('YYYY-MM-DD')
export const displayDateUnformatted = dayjs().day(-7)
// export const displayDate = displayDateUnformatted.format('YYYY-MM-DD')
// export const showDate = dayjs().day(-7).format('YYYY-MM-DD')
//export const showDate = dayjs().day(0).subtract(1, 'day').format('MM-DD-YYYY')

export const startDate = (dayjs().$y - 1).toString() + '-10-02'
export const endDate = dayjs().day(0).add(182, 'day').format('YYYY-MM-DD') //someFutureSunday
export const displayDate = dayjs().day(-1).format('YYYY-MM-DD')
//export const lastDisplayDate = dayjs().day(-7).format('YYYY-MM-DD')
