import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './ModelInformation.module.scss'
import { useAuthState } from '../../../../components/AuthProvider'
import activityLog from '../../../../utils/activityLog'

const ModelInformation = () => {
  // const history = useHistory()

  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Model Information',
    indicator_name: 'Model Information',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }


  useEffect(() => {
    activityLog(logObject)
  }, [1])

  return (
    <div className={styles.containerDiv}>
      <div className={styles.fluContainer}>

        <br />
        <div className={styles.bottomPadded}>
          <h4>Machine learning - Ensemble methods</h4>
          <p>
            Ensemble learning is the machine learning approach used to forecast Flu and COVID-19 activity.
            The ensemble method combines multiple learning models into one optimal predictive model.
            This technique facilitates greater prediction stability by minimizing sources of error,
            including variance, noise, and bias, from the base models.
            The blended output will produce more accurate predictions for improved decision-making.
          </p>

        </div>

        <div className={styles.bottomPadded}>
          <h4>Incidence Proportion</h4>
          <p>
            Surveillance and forecasting of disease incidence in the weekly time interval for any given region, is defined as proportion: # of new disease cases / # of UHC members received care
          </p>
        </div>

        <div className={styles.bottomPadded}>
          <h4>Epiweek</h4>
          <p>
            An epidemiological week, commonly referred to as an epi week or a CDC week, is simply a
            standardized method of counting weeks to allow for the comparison of data year after year.
            The first epi week of the year ends, by definition, on the first Saturday of January,
            if it falls at least four days into the month.
            Each epi week begins on a Sunday and ends on a Saturday.
          </p>
        </div>

        <div className={styles.bottomPadded}>
          <h4>Horizons</h4>
          <p>
            These are the forecasting horizons. As the forecast generates N weeks/steps ahead, all epiweeks (except the initial and latest epiweeks) have been forecasted N times.
            Horizon ‘N’ (N being 1, 2…N) indicates ‘N’ step ahead forecast.
          </p>
        </div>

        <div className={styles.bottomPadded}>
          <h4>Data Source</h4>
          <p>
            Unified Data Warehouse (UDW) is a centralized repository that incorporates data from
            over 100 source systems that UHG utilizes, including all primary claim platforms.
            UDW provides up to ten years of claims, provider, financial management, product,
            plan, & benefit, customer, consumer/ member, and clinical data.
          </p>
        </div>
      </div>
      <div className={styles.covidContainer}>

        <br />
        <div className={styles.bottomPadded2}>
          <h4>FluCast</h4>
          <p>
            Cases are adjudicated medical claims from Unified Data Warehouse (UDW),
            based on positive laboratory test results and specific ICD-10 diagnosis codes associated with influenza:
          </p>
          <ul>
            <li>
              <p>
              J069
              </p>
            </li>
            <li>
              <p>
              J09-J11
              </p>
            </li>
            <li>
              <p>
              J1289
              </p>
            </li>
            <li>
              <p>
              J1290
              </p>
            </li>
            <li>
              <p>
              J18
              </p>
            </li>
            <li>
              <p>
              J209
              </p>
            </li>
            <li>
              <p>
              J40
              </p>
            </li>
          </ul>
          <h4>COVIDCast</h4>
          <ul>
            {/* <li>
              <b>Cases and Deaths: John's Hopkins daily COVID-19 Report</b>
              <p>
                We utilise Covid-19 actuals data released by John's Hopkins
                University which is one of the leading data sources for COVID-19
                cases and deaths across United States.
              </p>
            </li> */}
            <li>
              <b>COVID-19 Incidences</b>
              <p>
                Cases are adjudicated medical claims from Unified Data Warehouse (UDW),
                based on positive laboratory test results and specific ICD-10 diagnosis codes associated with COVID-19:
              </p>
              <ul>
            <li>
              <p>
              U071
              </p>
            </li>
            <br/>            
            </ul>
            </li>
            <li>
              <b>Hospital Admissions: HHS</b>
              <p>
                Facility-level data for hospital utilization aggregated on a weekly basis (Sunday to Saturday) having values for previous day(Saturday to Friday) are aggregated at HRR(Hospital Referral
                Regions) geographical resolution. These are derived from reports
                with facility-level granularity across two main sources: (1) HHS
                TeleTracking, and (2) reporting provided directly to HHS Protect
                by state/territorial health departments on behalf of their
                healthcare facilities. More information on registered hospitals
                and data can be found on &nbsp;
                <a
                  href="https://healthdata.gov/Hospital/COVID-19-Reported-Patient-Impact-and-Hospital-Capa/uqq2-txqb"
                  rel="no-follow"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  Healthdata.gov
                </a>
              </p>
            </li>
          </ul>
        </div>
        <h3>Cardiac Risk Calculator</h3>
        <br />
        <div className={styles.bottomPadded2}>
          <h4>Machine learning - Random Forest</h4>
          <p>
            Random forest is the machine learning approach used to predict patient's risk for cardiac.
            It builds decision trees on different samples and takes their majority vote for classification.
          </p>


          <h4>Optum de-identified data wareshouse (ODDW)</h4>
          <p>
            We are using ODDW which contains EHR and Claims from Humedica. EHR data assets include Integrated Claims/EHR, Market Clarity, Enriched Oncology, OMOP EHR,
            OMOP Integrated and the COVID dataset. Claims data assets are the Clinformatics Data Mart (CDM), Socio-Economic Data (SES),
            Health Risk Assessment (HRA), Five Digit Zip Code, Non-affiliate claims and OMOP claims.
          </p>

          <h4>Database contains multiple line of Business data</h4>
          <ul>
            <li>
              <p>
                Commercial       - 68%
              </p>
            </li>
            <li>
              <p>
                Medicaid         - 12%
              </p>
            </li>
            <li>
              <p>
                Medicare         - 12%
              </p>
            </li>
            <li>
              <p>
                Uninsured        - 4%
              </p>
            </li>
            <li>
              <p>
                Other Payor Type - 2%
              </p>
            </li>
            <li>
              <p>
                Unknown          - 2%
              </p>
            </li>
          </ul>

          <h4>Patients selection criteria</h4>
          <p>
            Should be diagonised with U07.1 ICD code or positive rt-pcr test.<br />
            Should have minimum 1 year historical data and 1 year post covid data.
          </p>
        </div>

      </div>
    </div>
  )
}

export default ModelInformation
