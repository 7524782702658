import moment from 'moment'
import getEpiWeek from '../../utils/getEpiWeek'

const toolTipFormatter = (ordinate, xAxisLable) => {
  let toolTipHTML = ''
  //let epiweek = getEpiWeek(moment(ordinate.x)._d)
  let epiweek = getEpiWeek(moment(ordinate.x).subtract(7,'d')._d) // This change done for Pharma Flu
  if (xAxisLable === 'epiWeek') {
    toolTipHTML = `<h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px;">
        Epi Week ${ordinate.x}</h4>`
  } else {
    const date = moment(ordinate.x).format('MMM D, YYYY')
    toolTipHTML = `<h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px;">
        ${date} / Epiweek ${epiweek}</h4>`
  }

  ordinate.points.forEach((point) => {
    toolTipHTML += `<h5 style="background-color: ${
      point.color
    }; color: #fff; padding: 5px;">${point.series.name.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 0,
      }
    )}</h5>`

    if ('high' in point.point) {
      toolTipHTML += `<h6 style="padding: 0 5px;">${point.point.low.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
        }
      )} - ${point.point.high.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</h6>`
    } else {
      toolTipHTML += `<h6 style="padding: 0 5px;">${point.y.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
        }
      )}</h6>`
    }
  })

  return toolTipHTML
}

function epiweekToolTip() {
  return toolTipFormatter(this, 'epiWeek')
}

function dateToolTip() {
  return toolTipFormatter(this)
}

export { epiweekToolTip, dateToolTip }
