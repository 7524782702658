import moment from 'moment'
import Highcharts from 'highcharts'

function getHorizontalMultiBarChartOptions(toolTipFormatter, data, trendName,show ) {  
  var height=400;
  //subtract 1 extra day to make dotted line in sink
  var fordate = moment().add(-moment()._d.getDay() - 1, 'day')._d
  //remove flu index condition because covid and flu are weekly data
  // if (trendName != 'Flu Index') {
  //   fordate = moment()
  // }
  
  var vt =data
  // [
  //   {
  //     STATE_CODE: 'OR',
  //     STATE_NAME: 'Oregon',
  //     MR: 6.76,
  //     EI: 5.1,
  //     CS: 0,
  //     TOTAL_RATE: 11.86,
  //   },
  //   {
  //     STATE_CODE: 'CO',
  //     STATE_NAME: 'Colorado',
  //     MR: 5.32,
  //     EI: 6.36,
  //     CS: 0,
  //     TOTAL_RATE: 11.68,
  //   },
  //   {
  //     STATE_CODE: 'NY',
  //     STATE_NAME: 'New York',
  //     MR: 1.52,
  //     EI: 6.47,
  //     CS: 1.77,
  //     TOTAL_RATE: 9.76,
  //   },
  //   {
  //     STATE_CODE: 'AZ',
  //     STATE_NAME: 'Arizona',
  //     MR: 3.4,
  //     EI: 3.44,
  //     CS: 2.79,
  //     TOTAL_RATE: 9.629999999999999,
  //   },
  //   {
  //     STATE_CODE: 'NJ',
  //     STATE_NAME: 'New Jersey',
  //     MR: 0.78,
  //     EI: 4.65,
  //     CS: 3.63,
  //     TOTAL_RATE: 9.06,
  //   },
  //   {
  //     STATE_CODE: 'WI',
  //     STATE_NAME: 'Wisconsin',
  //     MR: 1.03,
  //     EI: 5.66,
  //     CS: 1.95,
  //     TOTAL_RATE: 8.64,
  //   },
  //   {
  //     STATE_CODE: 'NE',
  //     STATE_NAME: 'Nebraska',
  //     MR: 0.23,
  //     EI: 4.47,
  //     CS: 3.81,
  //     TOTAL_RATE: 8.51,
  //   },
  //   {
  //     STATE_CODE: 'MN',
  //     STATE_NAME: 'Minnesota',
  //     MR: 1.22,
  //     EI: 6.95,
  //     CS: 0,
  //     TOTAL_RATE: 8.17,
  //   },
  //   {
  //     STATE_CODE: 'IA',
  //     STATE_NAME: 'Iowa',
  //     MR: 0.58,
  //     EI: 7.19,
  //     CS: 0.01,
  //     TOTAL_RATE: 7.78,
  //   },
  //   {
  //     STATE_CODE: 'KS',
  //     STATE_NAME: 'Kansas',
  //     MR: 0.4,
  //     EI: 3.69,
  //     CS: 3.68,
  //     TOTAL_RATE: 7.77,
  //   },
  //   {
  //     STATE_CODE: 'CA',
  //     STATE_NAME: 'California',
  //     MR: 3.29,
  //     EI: 4.3,
  //     CS: 0.04,
  //     TOTAL_RATE: 7.63,
  //   },
  //   {
  //     STATE_CODE: 'TX',
  //     STATE_NAME: 'Texas',
  //     MR: 2.94,
  //     EI: 3.96,
  //     CS: 0.45,
  //     TOTAL_RATE: 7.35,
  //   },
  //   {
  //     STATE_CODE: 'ND',
  //     STATE_NAME: 'North Dakota',
  //     MR: 1.09,
  //     EI: 5.66,
  //     CS: 0,
  //     TOTAL_RATE: 6.75,
  //   },
  //   {
  //     STATE_CODE: 'WA',
  //     STATE_NAME: 'Washington',
  //     MR: 3.73,
  //     EI: 2.79,
  //     CS: 0,
  //     TOTAL_RATE: 6.52,
  //   },
  //   {
  //     STATE_CODE: 'IL',
  //     STATE_NAME: 'Illinois',
  //     MR: 0.92,
  //     EI: 5.5,
  //     CS: 0,
  //     TOTAL_RATE: 6.42,
  //   },
  //   {
  //     STATE_CODE: 'MO',
  //     STATE_NAME: 'Missouri',
  //     MR: 0.65,
  //     EI: 4.62,
  //     CS: 0.96,
  //     TOTAL_RATE: 6.23,
  //   },
  //   {
  //     STATE_CODE: 'TN',
  //     STATE_NAME: 'Tennessee',
  //     MR: 0.8,
  //     EI: 2.61,
  //     CS: 2.56,
  //     TOTAL_RATE: 5.9700000000000006,
  //   },
  //   {
  //     STATE_CODE: 'DC',
  //     STATE_NAME: 'District of Columbia',
  //     MR: 0.05,
  //     EI: 5.92,
  //     CS: 0,
  //     TOTAL_RATE: 5.97,
  //   },
  //   {
  //     STATE_CODE: 'OK',
  //     STATE_NAME: 'Oklahoma',
  //     MR: 3.17,
  //     EI: 2.7800000000000002,
  //     CS: 0,
  //     TOTAL_RATE: 5.95,
  //   },
  //   {
  //     STATE_CODE: 'HI',
  //     STATE_NAME: 'Hawaii',
  //     MR: 0.48,
  //     EI: 0.29,
  //     CS: 5.17,
  //     TOTAL_RATE: 5.94,
  //   },
  //   {
  //     STATE_CODE: 'CT',
  //     STATE_NAME: 'Connecticut',
  //     MR: 0.74,
  //     EI: 4.89,
  //     CS: 0,
  //     TOTAL_RATE: 5.63,
  //   },
  //   {
  //     STATE_CODE: 'OH',
  //     STATE_NAME: 'Ohio',
  //     MR: 0.15,
  //     EI: 4.44,
  //     CS: 0.66,
  //     TOTAL_RATE: 5.25,
  //   },
  // ]
  var categories = []
  var all_categories = []
  var age_series_data_ei = []
  var age_series_data_cs = []
  var age_series_data_mr = []
  var age_series_all_data_ei = []
  var age_series_all_data_cs = []
  var age_series_all_data_mr = []
  let counter = 0
  vt.forEach((data_val) => {

    if (show==="all")
    {
      categories.push(data_val.STATE_NAME)
      age_series_data_ei.push(data_val.EI)
      age_series_data_cs.push(data_val.CS)
      age_series_data_mr.push(data_val.MR)
      height = 600
    }
    else
    {
      if (counter < 10) {
        categories.push(data_val.STATE_NAME)
        age_series_data_ei.push(data_val.EI)
        age_series_data_cs.push(data_val.CS)
        age_series_data_mr.push(data_val.MR)
      }
    }
    
     counter++
    // all_categories.push(data_val.STATE_NAME)
    // age_series_all_data_ei.push(data_val.EI)
    // age_series_all_data_cs.push(data_val.CS)
    // age_series_all_data_mr.push(data_val.MR)
  })

  return {
    chart: {
      type: 'column',
      height: height,
      inverted: true,
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    credits: {
      enabled: false,
    },

    legend: { 
      enabled: true,
      // itemMarginRight: 1,
    },
    yAxis: {
      title: {
        text: '% Vaccination Rate',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      opposite: true,
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + '%'
        },
      },
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      labels: {       
        useHTML: true,        
        formatter: function () {
          return (
            '<a target="_blank" href="/Vaccinations/fluvaccinationdetails?state=' +
            this.value +
            '">' +
            this.value +
            '</a>'
          )
        },
      },
      //opposite: true
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '%<br/>'
        )
        //'Total: ' + this.point.stackTotal;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y + ' %'
          },
        },
        enableMouseTracking: true,
      },
    },
    series: [
      {
        name: 'Employer & Individual (EI)',
        data: age_series_data_ei,
        showInLegend: true,
        legendIndex: 2,
        color: '#CCDAFF',
      },
      {
        name: 'Community & State (CS)',
        data: age_series_data_cs,
        legendIndex: 1,
        showInLegend: true,
        color: '#879BC8',
      },
      {
        name: 'Medicare & Retirement (MR)',
        data: age_series_data_mr,
        legendIndex: 0,
        showInLegend: true,
        color: '#003C71',
      },
    ],
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            maxHeight: 100,
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
}

export default getHorizontalMultiBarChartOptions
