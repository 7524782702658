import { Pointer } from 'highcharts'
import React from 'react'

const IconInfo = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.05 22.0001C4.99998 22.0001 0.0999756 17.1001 0.0999756 11.0501C0.0999756 5.0001 4.99998 0.100098 11.05 0.100098C17.1 0.100098 22 5.0001 22 11.0501C22.05 17.1001 17.1 22.0001 11.05 22.0001ZM11.05 1.1001C5.54998 1.1001 1.09998 5.5501 1.09998 11.0501C1.09998 16.5501 5.54998 21.0001 11.05 21.0001C16.55 21.0001 21 16.5501 21 11.0501C21 5.5501 16.55 1.1001 11.05 1.1001Z"
      fill="#002677"
    />
    <path
      d="M11.05 6.5502C10.7 6.5502 10.35 6.4502 10.1 6.2002C9.84995 5.9502 9.69995 5.6502 9.69995 5.2502C9.69995 4.8502 9.84995 4.5502 10.1 4.3002C10.35 4.0502 10.7 3.9502 11.05 3.9502C11.4 3.9502 11.7 4.0502 11.95 4.3002C12.2 4.5002 12.35 4.8502 12.35 5.2502C12.35 5.6502 12.2 6.0002 11.95 6.2002C11.75 6.4502 11.45 6.5502 11.05 6.5502ZM12.4 9.3502V17.1002C12.4 17.6502 12.25 18.0502 12 18.3002C11.75 18.5502 11.4 18.7002 11.05 18.7002C10.65 18.7002 10.35 18.5502 10.1 18.3002C9.84995 18.0002 9.74995 17.6002 9.74995 17.1002V9.4002C9.74995 8.85019 9.84995 8.4502 10.1 8.2002C10.35 7.9502 10.65 7.8002 11.05 7.8002C11.45 7.8002 11.75 7.9502 12 8.2002C12.25 8.5002 12.4 8.8502 12.4 9.3502Z"
      fill="#002677"
    />
  </svg>
)

export default IconInfo
