import React from "react";

import styles from "./ItemsLoadingState.module.scss";

const ItemsLoadingState = ({ children = null, loading: isLoading = false }) => {
  if (isLoading) {
    return (
      <div className={styles.base}>
        <ul>
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>
    );
  }

  return children;
};

export default ItemsLoadingState;
