import React from "react";
import Icon from "../Icon";

import styles from "./Item.module.scss";

const Item = ({
  href,
  label,
  onClick,
  render,
  icon,
  last: isLast,
  ...rest
}) => {
  const className = `${styles.base} ${rest.className ? rest.className : ""}`;
  return (
    <li className={className}>
      {typeof render === "function" ? (
        render()
      ) : isLast ? (
        label
      ) : (
        <a href={href} onClick={onClick}>
          {icon} {label}
        </a>
      )}
      {!isLast && <Icon size="0.5rem" type="arrow_right" />}
    </li>
  );
};

export default Item;
