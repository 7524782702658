import moment from 'moment'

function toolTipFormatter() {
  const date = moment(this.x).format('MMM D, YYYY')
  let epiweek = ''
  var toolTipHTML = `<h4 style="background-color: #000; color: #fff; padding: 10px; margin-bottom: 0px;">
    ${date} / Epiweek ${epiweek}</h4>`

  let location = []
  this.points.forEach((point) => {
    if (!location.includes(point.series.name)) {
      location.push(point.series.name)
      toolTipHTML += `<h4 style="background-color: ${
        point.color
      }; color: #fff; padding: 10px;">${point.series.name}</h4>
      <table style="padding: 0 10px 10px; width: 100%">
        <tr>
          <th>Flu Index: </th>
          <td>
            ${point.y.toLocaleString(undefined, { minimumFractionDigits: 4 })}
          </td>
        </tr>
      </table>`
    }
  })

  return toolTipHTML
}

export default toolTipFormatter
