const regionDisplayOptions = [
  { value: 'state', label: 'States' },
  // { value: 'metro', label: 'States + Metro Areas' },
  { value: 'msa', label: 'State + CBSA' },
  { value: 'county', label: 'Counties' },
  // { value: 'hrr', label: 'HRR' },
]
const regionDisplayOptionsUHC = [
  { value: 'state', label: 'States' },
  { value: 'county', label: 'Counties' },
]
const regionDisplayOptionsHRR = [{ value: 'hrr', label: 'HRR' }]

const regionDisplayOptionsIncidence = [
  { value: 'state', label: 'States' },  
  { value: 'msa', label: 'State + Metro Areas' },
]

const getRegionDisplayOptions = (option) => {
  switch (option) {
    case 'UHC Indicators':
      return regionDisplayOptionsUHC
    case 'Covid Hospitalization':
      return regionDisplayOptionsHRR
    case 'Covid Incidence':
      return regionDisplayOptionsIncidence
    default:
      return regionDisplayOptions
  }
}

export default getRegionDisplayOptions
