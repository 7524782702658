import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// move this to another file
import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

import { useGeoState } from '../../components/GeoProvider'
import Spinner from '../../components/Loader/Spinner'

import styles from './FluDetailedView.module.scss'

import statesMap from '../../utils/stateMap'
import cbsasMap from '../../utils/cbsasMap'
import countyMap from '../../utils/countyMap'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const DetailedViewDisplay = ({ countyJson, cbsaJson, cbsaData, statesData, type, identifier }) => {
  const { displayDate } = useGeoState()
  const history = useHistory()
  let displayName = ''
  let displayType = type
  let data = []
  if (type === 'state') {
    Object.entries(statesData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      data.push({ date: key, ...value })
    })
  } else {
    Object.entries(cbsaData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      data.push({ date: key, ...value })
    })
  }
  switch (type) {
    case 'state':
      displayType = 'State'
      displayName = statesMap()[identifier]
      break
    case 'metro':
    case 'city':
      displayType = 'Metro'
      displayName = cbsasMap(cbsaJson)[identifier]
      break
    case 'county':
      displayName = countyMap(countyJson)[identifier]
      break
    default:
    // code block
  }
  // const percent80 = data.map((d) => {
  //   return [new Date(d.date).getTime(), d.ci_low80, d.ci_high80]
  // })

  // const percent95 = data.map((d) => {
  //   return [new Date(d.date).getTime(), d.ci_low95, d.ci_high95]
  // })

  // const forecast = data.map((d) => {
  //   return [new Date(d.date).getTime(), d.forecast_value]
  // })
  const percent80 = data.map((d) => {
    if (d.ci_low80 === 0 && d.ci_high80 === 0)
      return [new Date(d.date).getTime(), null, null]
    else return [new Date(d.date).getTime(), d.ci_low80, d.ci_high80]
  })

  const percent95 = data.map((d) => {
    if (d.ci_low95 === 0 && d.ci_high95 === 0)
      return [new Date(d.date).getTime(), null, null]
    else return [new Date(d.date).getTime(), d.ci_low95, d.ci_high95]
  })

  const forecast = data.map((d) => {
    if (d.forecast_value === 0) return [new Date(d.date).getTime(), null]
    else return [new Date(d.date).getTime(), d.forecast_value]
  })

  const actual = data
    .filter((d) => d.actual_value > 0)
    .map((d) => {
      return [new Date(d.date).getTime(), d.actual_value]
    })

  const high = data.map((d) => {
    return [new Date(d.date).getTime(), d.high_point]
  })

  const chartOptions = {
    title: {
      text: 'Flu Trend for ' + displayType + ' : ' + displayName, //undefined,
    },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %e', this.value - 86400000) //added

        },

      },
      // plotLines: [
      //   {
      //     color: '#cdcdcd',
      //     width: 1,
      //     value: new Date(displayDate).getTime(),
      //     zIndex: 10,
      //     label: {
      //       x: -20,
      //       rotation: 0,
      //       text: moment(displayDate).format('MMM D'),
      //       style: { color: 'black' },
      //     },
      //   },
      // ],
    },
    yAxis: {
      title: {
        text: 'Flu2',
      },
      min: 0,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Baseline',
        data: high,
        zIndex: 1,
        color: 'red',
      },
      {
        name: 'Forecast',
        data: forecast,
        zIndex: 1,
        color: Highcharts.getOptions().colors[0],
      },
      {
        name: 'Actual',
        data: actual,
        zIndex: 1,
        color: 'black',
      },

      {
        name: '95% Confidence level',
        data: percent95,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
      {
        name: '80% Confidence level',
        data: percent80,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.4,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
    ],
  }

  return (
    <>
      <div>
        {/* <h4>Flu Trends</h4> */}
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </>
  )
}

const DetailedView = ({  cbsaData, statesData, hash }) => {

  const [cbsaJsonFile, setCbsaJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  const [getCountyJsonFile, setCountyJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_county_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCountyJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_county_20m_wkid102004 ");
      });
  }, [1]);

  const history = useHistory()
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]

  return (
    <div className={styles.base}>
      <div className={styles.body}>
        {(type === 'state' &&
          statesData !== undefined &&
          Object.keys(statesData).length > 1) ||
        (type === 'metro' &&
          cbsaData !== undefined &&
          Object.keys(cbsaData).length > 1)  && cbsaJsonFile && getCountyJsonFile? (
          <DetailedViewDisplay
            cbsaData={cbsaData}
            statesData={statesData}
            hash={hash}
            type={type}
            identifier={identifier}
            countyJson={getCountyJsonFile}
            cbsaJson={cbsaJsonFile}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default DetailedView
