import moment from 'moment'
import Highcharts from 'highcharts'
import seasons from '../../utils/seasons.json'
import countingMethod from './../../utils/getCountingMethod'
function getLineChartOptions(toolTipFormatter, data, trendName) {
  //subtract 1 extra day to make dotted line in sink
  var fordate = moment().add(-moment()._d.getDay() - 1, 'day')._d
  //remove flu index condition because covid and flu are weekly data
  // if (trendName != 'Flu Index') {
  //   fordate = moment()
  // }
var data = data;
  var season_arr = seasons
  var arr = [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ]
  var graph_data = []
  var categories = [
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]
  for (var f = 0; f < season_arr.length - 1; f++) {
    var dataArray = []
    for (var i = 0; i < data.length; i++) {
      if (season_arr[f] === data[i].Flu_Season) {
        
        let count = data[i].VAC_COUNT / 1000
         //dataArray.push(countingMethod(count, 'fixed'));
        dataArray.push(count)
      }
    }
    if (dataArray.length > 0) {
      graph_data.push({
        name: season_arr[f],
        data: dataArray,
        showInLegend: true,
        color: arr[f],
        dataLabels: {
          enabled: true,
          formatter: function () {
            // if (this.y * 1000 > 1000000) {
            //   return Highcharts.numberFormat((this.y * 1000) / 1000000, 3) + 'M'
            // } else
             if (this.y * 1000 > 1000) {
              return Highcharts.numberFormat((this.y * 1000) / 1000, 3) + 'K'
            } else {
              return this.y * 1000
            }
          },
        },
      })
    }
  }

  return {
    chart: {
      type: 'line',
      height: 300,
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '<br/>'
        )
        //'Total: ' + this.point.stackTotal;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + ' %'
          },
        },
        enableMouseTracking: true,
      },
    },

    series: graph_data,
    // series: [
    //   // lineChartData,
    // {
    //   dataLabels: {
    //     enabled: true,
    //     formatter: function () {
    //       if (this.y * 1000 > 1000000) {
    //         return Highcharts.numberFormat((this.y * 1000) / 1000000, 3) + 'M'
    //       } else if (this.y * 1000 > 1000) {
    //         return Highcharts.numberFormat((this.y * 1000) / 1000, 3) + 'K'
    //       } else {
    //         return this.y * 1000
    //       }
    //     },
    //   },
    //   name: 'Patients',
    //   data: graph_data,
    //   showInLegend: false,
    //   color: '#9EAFDA',
    // },
    // ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 900,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
}

export default getLineChartOptions
