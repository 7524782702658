import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'

import getHorizontalMultiBarChartOptions from './getHorizontalMultiBarChartOptions'

import styles from './HorizontalMultiBarChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const HorizontalMultiBarChartDisplay = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  data,
  dataDisplayOptions,
  show,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [trendOptionSelect, setTrendOptionSelect] = useState(
    dataDisplayOptions[0].exp
  )
  const [trendNameSelect, setTrendNameSelect] = useState(
    dataDisplayOptions[0].label
  )

  //temporary data

  //temporary data
  var hydratedData = data
  const chartOptions = getHorizontalMultiBarChartOptions(
    toolTipFormatter,
    hydratedData,
    trendNameSelect,
    show
  )
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
    }),
  }

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default HorizontalMultiBarChartDisplay
