import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'
import getHorizontalBarChartOptions from './getHorizontalBarChartOptions'
import styles from './HorizontalBarChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const HorizontalBarChartDisplay = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  data,
  dataDisplayOptions,
  chartName,
  height,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [trendOptionSelect, setTrendOptionSelect] = useState(
    dataDisplayOptions[0].exp
  )
  const [trendNameSelect, setTrendNameSelect] = useState(
    dataDisplayOptions[0].label
  )  
  var vt = ''
  var vt1 = ''
  if (chartName === 'chart1') {
    vt = JSON.parse(data.response_data);
  }
  if (chartName === 'chartPopularProd') {
    vt =  JSON.parse(data.response_data);
  }

  if (chartName === 'chart3') {
    vt = JSON.parse(data.response_data);
  }
  if (chartName === 'getfactor' || chartName === 'countyFactor') {
    
    vt = data.response_data.medicalFactorsModel
  }

  // if (chartName == 'countyFactor')
  // {
  //   vt = data.medicalFactorsModel;
  // }

  var categories = []
  var all_categories = []
  var state_series_data = []
  var state_series_all_data = []

  const finalData = []
  if (chartName === 'chart1') {
    let counter = 0
    if (vt !=null || vt !=undefined)
    {
    vt.sort((a, b) => (parseFloat(a.Record) < parseFloat(b.Record)) ? 1: -1);
    vt.forEach((data_val) => {
      if (counter < 10) {
        categories.push(data_val.Area_Nm)
        state_series_data.push(parseFloat(data_val.Record)  / 1000)
      }
      counter++
      all_categories.push(data_val.Area_Nm)
      state_series_all_data.push(parseFloat(data_val.Record) / 1000)
    })
  }}

  if (chartName === 'chartPopularProd') {
    let counter = 0
    if (vt !=null || vt !=undefined)
    {
    vt.sort((a, b) => (parseFloat(a.Record) < parseFloat(b.Record)) ? 1: -1);
    vt.forEach((data_val) => {
      if (counter < 10) {
        categories.push(data_val.Prdct_Nm)
        state_series_data.push(parseFloat(data_val.Record)  / 1000)
      }
      counter++
      all_categories.push(data_val.Prdct_Nm)
      state_series_all_data.push(data_val.Record / 1000)
    })
  }}
  if (chartName === 'getfactor' || chartName === 'countyFactor') {
    let counter = 0
    if (vt !== null || vt !=undefined) {
      vt.forEach((data_val) => {
        categories.push(data_val.factor)
        state_series_data.push(data_val.factorPerc)
      })
    }
  } else if (chartName === 'chart3') {
    categories.push('Medicare and Retirement(MR)')
    categories.push('Community and State(CS)')
    categories.push('Employer and Individual(EI)')
    let counter = 0
    if (vt !== null || vt !=undefined) {
    vt.forEach((data_val) => {
      if (counter < 10) {
        //categories.push(data_val.CATEGORY)
        state_series_data.push(data_val.MR / 1000)
        state_series_data.push(data_val.CS / 1000)
        state_series_data.push(data_val.EI / 1000)
      }
      // counter++
      // all_categories.push(data_val.CATEGORY)
      // state_series_all_data.push(data_val.CATEGORY_COUNT / 1000)
    })
  }
    // finalData.push({ name: 'categories', data: categories })
    // finalData.push({ name: 'data', data: state_series_data })
    // finalData.push({ name: 'all_categories', data: all_categories })
    // finalData.push({ name: 'all_data', data: state_series_all_data })
  } else if (chartName === 'chart2') {
    let counter = 0
    if (vt1 !== null || vt1 !=undefined) {
    vt1.forEach((data_val) => {
      if (counter < 10) {
        categories.push(data_val.CATEGORY)
        state_series_data.push(data_val.CATEGORY_COUNT / 1000)
      }
      counter++
      all_categories.push(data_val.CATEGORY)
      state_series_all_data.push(data_val.CATEGORY_COUNT / 1000)
    })
    }
    // finalData.push({ name: 'categories', data: categories })
    // finalData.push({ name: 'data', data: state_series_data })
    // finalData.push({ name: 'all_categories', data: all_categories })
    // finalData.push({ name: 'all_data', data: state_series_all_data })
  }

  finalData.push({ name: 'categories', data: categories })
  finalData.push({ name: 'data', data: state_series_data })
  finalData.push({ name: 'all_categories', data: all_categories })
  finalData.push({ name: 'all_data', data: state_series_all_data })
  var hydratedData = []
  const chartOptions = getHorizontalBarChartOptions(
    toolTipFormatter,
    hydratedData,
    trendNameSelect,
    finalData,
    height,
    chartName
  )
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
    }),
  }

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default HorizontalBarChartDisplay
