import React from 'react'
import GeoProvider from '../../components/GeoProvider'
import { startDate, endDate, displayDate } from './dates'
import dataDisplayOptions from './dataDisplayOptions'
import FluMap from './FluMap'
import styles from './FluMeter.module.scss'
import * as htmlToImage from 'html-to-image';
import { useAuthState } from '../../components/AuthProvider';
const HeatMap = () => {
  const disease='flumeter' 
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const saveImageApiUrl = `${clientConfigApiDomain}/${disease}/saveimage`  

  const SaveImage = () => {
    htmlToImage.toPng(document.getElementById('root'))
    .then(function (imageData) {     
      
      var getImgObject={name: "actuals_heatmap.png,forecast_heatmap.png" , base64Content: imageData};
      fetch(saveImageApiUrl, { method: 'post' , body: JSON.stringify(getImgObject)})
      .then((response) => {
        if (!response.ok) throw Error('Upload failed')
        response.json().then((responseBody) => {          
        })
      })
      .catch(() => {
        console.log('Catch Error');       
      })
    });
    
    
  }
  return (
    <div className={styles.base}>
      <GeoProvider       
        startDate={startDate}
        endDate={endDate}
        displayDate={displayDate}
        mapOptions={dataDisplayOptions[0]['options'][0]}
      >
        <FluMap />
      </GeoProvider>
      <button id="btnSaveImage" style={{border:'none',color:'white',backgroundColor:'white'}}  onClick={() => {
              SaveImage()
            }}
      >
     .
      </button>

    </div>
    
  )
}

export default HeatMap
