import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import { useAuthState } from '../../../components/AuthProvider'
import Spinner from '../../../components/Loader/Spinner'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import Tabs from '../../../components/Tabs'

import styles from './Emails.module.scss'
import Email from './Email'

const Emails = () => {
  const apiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const fluEmailApiUrl = `${apiDomain}/flu/getEmail`
  const covidEmailApiUrl = `${apiDomain}/covid/getEmail`
  const tabIndices = React.useMemo(() => ['#flu', '#covid-19'], [])

  const { user } = useAuthState()
  const [fluEmail, setFluEmail] = useState({})
  const [covidEmail, setCovidEmail] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [selectedTab, setSelectedTab] = React.useState(0)

  const history = useHistory()
  const location = useLocation()

  const onLoadTabs = React.useCallback(() => {
    for (const key in tabIndices) {
      if (location.hash === tabIndices[key]) setSelectedTab(+key)
    }
  }, [location.hash, tabIndices])

  const tabsChangeHandler = (index) => {
    setSelectedTab(index)
    history.push({ hash: tabIndices[index] })
  }

  React.useEffect(() => {
    onLoadTabs()
  }, [onLoadTabs])

  useEffect(() => {
    const getFluEmail = async () => {
      const response = await fetch(`${fluEmailApiUrl}/${user.email}`)
      const data = await response.json()
      setFluEmail(data)
    }
    const getCovidEmail = async () => {
      const response = await fetch(`${covidEmailApiUrl}/${user.email}`)
      const data = await response.json()
      setCovidEmail(data)
    }
    if (!hasLoaded) {
      setIsLoading(true)
      Promise.all([getFluEmail(), getCovidEmail()]).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    }
  }, [
    fluEmailApiUrl,
    covidEmailApiUrl,
    setIsLoading,
    setHasLoaded,
    hasLoaded,
    user.email,
  ])

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            <DefaultBreadcrumbs />
            <div className={styles.content}>
              <h1>Emails</h1>
              <p>Shows the emails that will be sent out.</p>
            </div>
          </div>
          <div className={styles.body}>
            {isLoading ? (
              <Spinner />
            ) : hasLoaded ? (
              <Tabs selectedIndex={selectedTab} onChange={tabsChangeHandler}>
                <Tabs.Panel label="Flu">
                  <Email email={fluEmail} disease={'flu'} />
                </Tabs.Panel>
                <Tabs.Panel label="COVID-19">
                  <Email email={covidEmail} disease={'covid'} />
                </Tabs.Panel>
              </Tabs>
            ) : null}
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Emails
