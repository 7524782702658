import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import toolTipFormatter from './toolTipFormatterActivityLogsDate'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const ActivityDailyChart = ({ area, yAxisLabel = false, height = '300px' }) => {
  const dashboardType = area.map((d) => {
    return [d.activity_date]
  })
  const hitsValues = area.map((d) => {
    return [d.total_hits]
  })
  const uniqueHitsValues = area.map((d) => {
    return [d.unique_hits]
  })
  const total_hits_data = area
    .filter((d) => d.total_hits > 0)
    .map((d) => {
      return [
        new Date(d.activity_date.replace('T00:00:00', '')).getTime(),
        d.total_hits,
      ]
    })
  const unique_hits_data = area
    .filter((d) => d.unique_hits > 0)
    .map((d) => {
      return [
        new Date(d.activity_date.replace('T00:00:00', '')).getTime(),
        d.unique_hits,
      ]
    })
  const chartOptions = {
    title: {
      text: undefined,
    },
    // xAxis: {
    //   categories: dashboardType,
    //   crosshair: true,
    // },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      labels: { enabled: yAxisLabel },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: toolTipFormatter,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Total Hits', //'Daily Total Hits',
        data: total_hits_data,

        type: 'line',
        zIndex: 1,
        color: '#15A796',
        marker: {
          enabled: true,
        },
      },
      {
        name: 'Unique Hits', //'Daily Unique Hits',
        data: unique_hits_data,
        type: 'line',
        zIndex: 1,
        color: 'blue',
        marker: {
          enabled: true,
        },
      },
    ],
  }
  return (
    <>
    <br/>
      <h4>Total and Unique Hits Over Time</h4>
      <HighchartsReact
        containerProps={{
          style: {
            width: '100%',
            position: 'relative',
            height: height,
          },
        }}
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate
        updateArgs={[true, true, true]}
      />
    </>
  )
}

export default ActivityDailyChart
