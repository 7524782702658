import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../components/GeoProvider'
import GeoProvider from '../../components/GeoProvider'
import styles from './BottomGraphPanel.module.scss'
import HorizontalMultiBarChart from '../../components/HorizontalMultiBarChart'
import HorizontalBarChart from '../../components/HorizontalBarChart'
import countingMethod from './../../utils/getCountingMethod'
import toolTipFormatter from './toolTipFormatter'
import stateOptions from '../../components/Map/usStatesAll'
import dayjs from 'dayjs'
import moment from 'moment'
import Spinner from '../../components/Loader/Spinner'

const BottomGraphPanel = ({ getfilterts, getMemberVaccinated }) => {
  const dataDisplayOptions = [
    { value: '0', label: 'Flu Index', exp: 'forecast_value' },
  ]
  const mondayData = dayjs()
    .day(+1)
    .format('YYYY-MM-DD')
  const entity = 'FluVaccine'
  const location = 'VaccineDataCount'
  const startDate = ''
  const endDate = ''
  const month = getfilterts.month
  const fluseason = getfilterts.season
  const state = getfilterts.state
  const fluFolder = 'VaccineDataCount'
  const lob = getfilterts.lob
  const type = getfilterts.type
  const filterId = ''
  const [responseData, setReponseData] = useState(undefined)

  const { data: resData, error: resDataError } = useGeoFetch(
    startDate,
    endDate,
    entity,
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}`
  )
  useEffect(() => {
    if (resData !== undefined && resData !== null) {
      if (resData.response_code===200){    
         var countData = JSON.parse(resData.response_data)   
        //  setReponseData(JSON.parse(resData.response_data) )
        countData.forEach(items => {
          if (items.Key=='Member')
          {
            setReponseData(parseFloat(items.Value ))
          }
        });
        
      }      
    }
  }, [resData])

  return (
    <GeoProvider>
      {' '}
      {responseData ? (
        <div className={styles.base}>
          <div className={styles.rateTitle}>
            <div
              style={{
                display: 'inline-flex',
                width: '60%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: '600',
                justifyContent: 'flex-start',
              }}
            >
              {' '}
              <p> States Flu Vaccination Rate - Top 10</p>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '40%',
                justifyContent: 'flex-end',
              }}
            >
              {' '}
              <span className={styles.graphSource}>
                Total Vaccinations so far{' '}
                <strong>
                  {' '}
                  {countingMethod(
                    responseData,
                    'fixed'
                  )}
                </strong>
              </span>
            </div>
          </div>
          <div className={styles.ratePanel}>
            <span className={styles.graphSource}>
              Source :{' '}
              <span className={styles.spanStyle}>
                Medical and Pharmacy Claims Data
              </span>
            </span>
            {/* <span className={styles.graphSource}>
              Patients Count :{' '}
              <strong>
                {' '}
                {countingMethod(responseData.Total_Claims.Value, 'fixed')}
              </strong>
            </span> */}

            <span className={styles.graphSource}>
              Time Period :{' '}
              <strong>
                August 2023 - {moment(mondayData).format('MMM DD, YYYY')}
              </strong>
            </span>
            <HorizontalMultiBarChart
              toolTipFormatter={toolTipFormatter}
              entity="FluVaccine"
              location="VaccinationRate"
              options={stateOptions}
              dataDisplayOptions={dataDisplayOptions}
              filterId={getfilterts}
              fluFolder="VaccinationRate"
              fluseason={getfilterts.season}
              month={getfilterts.month}
              lob={getfilterts.lob}
              type={getfilterts.type}
            />
          </div>
          <div className={styles.healthTitle}>
            <p> Most Vulnerable to COVID-19, States - Top 10</p>
          </div>
          <div className={styles.rateList}>
            <span className={styles.graphSource}>
              Source : <span>Medical and Pharmacy Claims Data</span>
            </span>
            <span className={styles.graphSource}>
              Patients Count:{' '}
              <strong>{getMemberVaccinated.MemberVaccinated}</strong>
            </span>
            <span className={styles.graphSource}>
              Time Period: <strong>August 2021 - August 2022</strong>
            </span>

            {/* <HorizontalBarChart
              toolTipFormatter={toolTipFormatter}
              entity="FluVaccine"
              location="PopularProduct"
              options={stateOptions}
              dataDisplayOptions={dataDisplayOptions}
              chartName="chart1"
              //filterid="PopularProduct-LOB-All_VTYPE-All_FLUSEASON-10_MONTH-All"
              height="400"
              filterId={getfilterts}
              fluFolder="PopularProduct"
              fluseason={getfilterts.season}
              month={getfilterts.month}
              lob={getfilterts.lob}
              type={getfilterts.type}
            /> */}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </GeoProvider>
  )
}

export default BottomGraphPanel
