import axios from 'axios'
import fetchIntercept from 'fetch-intercept'
import { USER_AUTH_KEY } from './constants'
import ErrorLogs from '../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../components/AuthProvider'
export const registerAxiosInterceptor = () => {
  
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {      
      if (error.response.status === 401) {
       window.location.replace('/')
      }
      else if (error.response.status === 405) {
        window.location.replace('/')
      }  
      else if (error.response.status === 403) {
        window.location.replace('/access-denied')
      }      
      const { user } = useAuthState()
      ErrorLogs({
        message_type: 'Error',
        error_details: `${'apim error'}, ${'apim error'}, ${error.response.status}`,
        user: user,
      })
      return Promise.reject(error)
    }
  )
}

// This function takes any existing headers that are being sent in the request as a JSON object,
// adds the headers that need to be added and sends them out as instance of a Headers class
const addHeadersToExistingHeaders = (existingHeaders, headers) => {
  var newHeaders = new Headers()

  // Create a headers object from the existing headers if its not null
  if (existingHeaders) {
    newHeaders = new Headers(existingHeaders)
  }

  // Append the required headers to the existing headers
  for (var header in headers) {
    newHeaders.append([header], headers[header])
  }
  return newHeaders
}

export const registerFetchInterceptor = () => {
  fetchIntercept.register({
    request: (url, config) => {
      const withDefaults = Object.assign({}, config)
      if (
        url.toLowerCase().indexOf('api') !== -1 &&
        url.toLowerCase().indexOf('login') === -1
      ) {
        // const user = JSON.parse(localStorage.getItem("USER_AUTH"));
        // if (user) {
        //   var selectedClient = user.preferences.selectedClient.id;
        //   var selectedOrg = user.preferences.selectedClient.organization.id;
        //   var headers = addHeadersToExistingHeaders(withDefaults.headers, {
        //     client_id: selectedClient,
        //     organization_id: selectedOrg
        //   });
        // }
        // // withDefaults.headers should be a instance of the Headers class
        // withDefaults.headers = headers;

        withDefaults.credentials = 'include'
      }

      // Add authenticatedUser header for local environment alone
      if (
        process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
        process.env.REACT_APP_DEVELOPMENT_MODE === 'false'
      ) {
        const sessionUser = window.localStorage.getItem(USER_AUTH_KEY)

        var headers = addHeadersToExistingHeaders(withDefaults.headers, {
          authenticatedUser: sessionUser,
        })
        // withDefaults.headers should be a instance of the Headers class
        withDefaults.headers = headers
      }

      return [url, withDefaults]
    },
    response: (response) => {
      if (response.status === 401 || response.status === 402 || response.status === 404) {
        window.location.replace('signout')
      }else if (response.status === 405) {
        window.location.replace('/signout')
      } else if (response.status === 403) {
        window.location.replace('/access-denied')
      } else return response
    },
  })
}

export const registerAxiosRequestInterceptor = () => {
  axios.interceptors.request.use((config) => {
    if (
      config.url.toLowerCase().indexOf('api') !== -1 &&
      config.url.toLowerCase().indexOf('login') === -1
    ) {
      // const user = JSON.parse(localStorage.getItem('USER_AUTH'))
      // if (user) {
      //   var selected_client = user.preferences.selectedClient.id
      //   var selected_org = user.preferences.selectedClient.organization.id
      //   config.headers['client_id'] = selected_client
      //   config.headers['organization_id'] = selected_org
      // }

      config.withCredentials = true
    }

    // Add authenticatedUser header for local environment alone
    if (
      process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
      process.env.REACT_APP_DEVELOPMENT_MODE === 'false'
    ) {
      const sessionUser = window.localStorage.getItem(USER_AUTH_KEY)
      config.headers.common['authenticatedUser'] = sessionUser
    }
    return config
  })
}
