import React, { useEffect, useState } from 'react'
import styles from './VulHeader.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import countingMethod from './../../utils/getCountingMethod'
import { useGeoFetch } from '../../components/GeoProvider'
import Spinner from '../../components/Loader/Spinner'
import ErrorMessage from '../../components/Layout/ErrorMessage'

const VulHeader = () => {
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getDataSourceCount, setDataSourceCount] = useState([])
  const [getStateData, setStateData] = useState([])
  const { data: getCountyData, error: getCountyDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    `getcountydata/1`
  )
  const { data: getSate, error: getStateError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    `getstate`
  )
  useEffect(() => {
    if (getSate !== undefined && getCountyData !== undefined) {
      if (getSate.response_code === 200 && getCountyData.response_code === 200) {
        Promise.all([getSate, getCountyData]).then((response) => {          
          if (response[1] !== undefined && response[1].response_data !== null) {            
            setStateData(response[0].response_data)
            setDataSourceCount(
              Object.values(
                response[1].response_data.vulnerabilityDataSource[0]
              )
            )
          }
        })
      } else {
        setErrorCode(getSate.response_code)
        setErrorMessage(getSate.response_message)
        setErrorSubject(getSate.response_subject)
        seterrorOccured(true)
      }
    }
  }, [getSate, getCountyData])
  //#endregion

  const history = useHistory()
  const location = useLocation()
  var hash = location.hash
  const handleSelect = (e) => {
    if (e.target.selectedOptions[0].value !== '0') {
      var index = e.target.selectedIndex
      var optionElement = e.target.childNodes[index]
      var stateId = optionElement.getAttribute('stateId')
      hash = `state-${stateId}`
      history.push({ hash: hash })
    }
  }

  if (getCountyDataError || getStateError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        {/* <div className={styles.titleVul}> Vulnerability Estimate</div>

        <div className={styles.titleVulInner}>
          {' '}
          Estimating the percentage of population at significant risk of
          COVID-19 Associated Hopitalization.
        </div>
       */}
        <div className={styles.titlePanel}>
          <h4>Vulnerability Estimate </h4>
          <p>
            Estimating the percentage of population at significant risk of
            COVID-19 Associated Hopitalization.{' '}
          </p>
        </div>
      </div>
      <div className={styles.countPanel}>
        {getDataSourceCount[0] !== undefined &&
        getDataSourceCount[0] !== null ? (
          <>
            <div className={styles.dateWeek}>
              <div>
                <span className={styles.position}>Data Sourced from</span>
              </div>
              <span className={styles.headerSpan}>{getDataSourceCount[0]}</span>
            </div>
            <div className={styles.dateWeek}>
              <div>
                <span className={styles.position}>Source of Data</span>
              </div>
              <span className={styles.headerSpan}>{getDataSourceCount[2]}</span>
            </div>
            <div className={styles.dateWeek}>
              <div>
                <span className={styles.position}>Patients Count</span>
              </div>
              <span className={styles.headerSpan}>
                {countingMethod(getDataSourceCount[1], 'fixed')}
              </span>
            </div>
            <div className={styles.dateWeek}>
              <div>
                <span className={styles.position}>
                  Patients Selection Criteria
                </span>
              </div>
              <span className={styles.headerSpan}>
                COVID-19 Patients, including Hopsitalized and Non Hospitalized
              </span>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <div className={styles.filterPanel}>
        {getStateData && (
          <select onChange={handleSelect} className={styles.ddl}>
            <option value="0">Select State...</option>
            {getStateData.map((item) => (
              <option
                key={item.stateId}
                value={item.stateCode}
                stateid={item.stateId}
              >
                {item.stateName}
              </option>
            ))}
          </select>
        )}
         <span style={{color:'#e40046', fontSize: '16px'}}><b>Proof of concept</b></span>
      </div>
    </div>
  )
}

export default VulHeader
