import React from "react";
import BackToHomeLink from "../../components/BackToHomeLink";
import Container from "../../components/Container";
import Content from "../../components/Content";
import Layout from "../../components/Layout";
import { SUPPORT_EMAIL } from "../../utils/constants";

const NoAccess = () => (
  <Layout>
    <Container>
      <Content>
        <h1>403: Login Error</h1>
        <p>There is a problem with your access to the application.</p>
        <p>
          For help, contact us at{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        </p>
        <p>
          <BackToHomeLink />
        </p>
      </Content>
    </Container>
  </Layout>
);

export default NoAccess;
