import React from 'react'
import { useHistory } from 'react-router-dom'

import { useGeoState } from '../../../../components/GeoProvider'
import LinkButton from '../../../../components/LinkButton'
import Tag from '../../../../components/Tag'
import statesMap from '../../../../utils/stateMap'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

import Tooltip from '../../../../components/Tooltip'
import Content from '../../../../components/Content'

const StateStat = ({ state, mapOptions }) => {
  
  const history = useHistory()
  return (
    <div className={statStyles.base}>
      <div
        style={{
          // display: 'inline-flex',
          width: '55%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.name}
      >
        <LinkButton
          className={statStyles.btn}
          onClick={() => {
            history.push(`/forecasts/Flumap#state-${state.identifier}`)
          }}
          title={state.name}
        >
          {state.name.length > 15
            ? state.name.substring(0, 15) + '...'
            : state.name}
        </LinkButton>

        <div
          style={{
            fontSize: '10px',
          }}
        >
          Accuracy :{' '}
          <b>
            {state.forecast_ci_revised_95.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            %
          </b>
        </div>
      </div>

      <div
        style={{
          marginTop: '-45px',
          height: '10%',
          marginLeft: '160px',
          textAlign: 'center',
          // display: 'inline-flex',
          // width: '45%',
          //justifyContent: 'flex-middle',
        }}
      >
        <Tag
          dataValue={state}
          levelKey={mapOptions.tagKey}
          valueKey={mapOptions.tagValue}
          showNumbers={true}
        />
        {/* <RiskTrendIcon trendingPercentage={state.trending_percentage} /> */}
      </div>
      {/* <span style={{
          fontSize: "10px"
        }}>
      Accuracy for this Location:  <b>{state.forecast_ci_revised_95.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
          %</b> 
        </span> */}
      {/* <span>Accuracy: {}</span> */}
      {/* <div
        style={{
          display: 'inline-flex',
          width: '32%',
          justifyContent: 'flex-end',
        }}
      >
        <span>
          {state.forecast_ci_revised_95.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
          %
        </span>
      </div> */}
    </div>
  )
}

const StateStats = ({ stateData, mapOptions }) => {
  
  const aboutText =
    'The Prediction Interval Accuracy is defined in terms of how often the forecasted prediction interval contains the true Actual value (Last Flu Season).'
  const inputRef = React.useRef(false)
  const { displayDate, searchFilter, setSearchFilter } = useGeoState()
  let isCancelled = React.useRef(false)
  const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
  const states = statesMap()
  const stateDataForDisplayDate = Object.entries(stateData[displayDate])
  const stateDataForDisplayDateNew = stateDataForDisplayDate.slice(3, stateDataForDisplayDate.length);
  const mappedStateData = stateDataForDisplayDateNew.map((state, index) => {
   
    const stateName = states[state[0]]
    return { ...state[1], name: stateName, identifier: state[0] }
  
  })
  //console.log(mappedStateData[0].forecast_ci_revised_95_overall)
  //forecast_ci_revised_95_overall
  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }

  let filteredStateData = mappedStateData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredStateData = mappedStateData.filter((cbsa) => {
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredStateData = filteredStateData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })
  function showSearch() {
   
    //console.log(isCancelled);
    isCancelled.current = true
    btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
    btnSearchLocation ? setSearchFilter('') : (inputRef.current = false)
    //btnSearchLocation ? inputRef.current.focus() : inputRef.current.focus()
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%', //'95%',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'graphik',
                    fontSize: '21px',
                    fontWeight: '600',
                  }}
                >
                  States
                </h5>
              </span>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '30%',
                justifyContent: 'right',
              }}
            >
              <span onClick={showSearch}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3584 10.8862L18 17.01L17.0701 18L10.3724 11.7859C9.26916 12.7439 8.21615 13.0355 6.64841 13.0355C3.10647 13.0355 0.203247 10.1323 0.203247 6.59032C0.203247 3.04838 3.10647 0.145157 6.64841 0.145157C10.1903 0.145157 13.0936 3.04838 13.0936 6.59032C13.0936 8.36129 12.5197 9.7249 11.3584 10.8862ZM6.78389 1.45483C4.03637 1.45483 1.78389 3.70175 1.78389 6.44249C1.78389 9.20792 4.03637 11.4548 6.78389 11.4548C9.53142 11.4548 11.7839 9.18323 11.7839 6.44249C11.7839 3.70175 9.53142 1.45483 6.78389 1.45483Z"
                    fill="#E40046"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: '12px',
            }}
          >
            All States Average Accuracy :{' '}
            <b>
              {mappedStateData[3].forecast_ci_revised_95_overall.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                }
              ) === 0
                ? 'NA'
                : mappedStateData[3].forecast_ci_revised_95_overall.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  ) + '%'}
            </b>
            <span style={{ marginLeft: '5px' }}>
              {' '}
              <Tooltip placement="top" iconHeight="15" iconWidth="15">
                <Content maxWidth="300px" fontSize="12px">
                  <p>{aboutText}</p>
                </Content>
              </Tooltip>
            </span>
          </div>
          {btnSearchLocation ? (
            <input
              ref={inputRef}
              type="text"
              onChange={handleChange}
              placeholder="Filter States"
            />
          ) : null}
        </div>

        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #424242',
            borderBottom: '1px solid #424242',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '55%' }}>
            <h4>Area Name</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '20%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Influenza Levels</h4>
          </div>
          {/* <div
            style={{
              display: 'inline-flex',
              width: '38%',
              justifyContent: 'flex-end',
            }}
          >
            <h4>Trend</h4>
          </div> */}
        </div>
      </div>
      <div style={{ overflowY: 'scroll', height: '100%' }}>
        {filteredStateData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((state) => {
            return (
              <StateStat
                state={state}
                key={state.identifier}
                mapOptions={mapOptions}
              />
            )
          })}
      </div>
    </div>
  )
}

export default StateStats
