const Logo = ({ color = '#003C71' }) => {
  return (
    <svg viewBox="0 0 368.63 26.72" focusable="false">
      <path
        fill={color}
        d="M497.13,306.21c2.84,0,4-1.28,4-3.73s-1.31-3.81-3.9-3.81h-3.78v19a1.08,1.08,0,0,1-1.08,1.08H490a1.09,1.09,0,0,1-1.08-1.08V297.13A1.11,1.11,0,0,1,490,296h7.65c4.69,0,7.61,2.13,7.61,6.46,0,3.41-2.16,5-4.66,5.71l4.92,9.22a1.41,1.41,0,0,1,.2.63.7.7,0,0,1-.62.75h-2.86a1,1,0,0,1-.85-.62l-5.26-10.28a2.51,2.51,0,0,1-.37-1.06c0-.34.17-.62.77-.62Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M518.93,319.27c-4.39,0-10-1.51-10-11.86s5.56-11.87,10-11.87,10,1.57,10,11.87S523.48,319.27,518.93,319.27Zm0-21.18c-4.72,0-5.53,4.25-5.53,9.32s.86,9.31,5.46,9.31,5.6-4,5.6-9.31C524.46,302.34,523.57,298.09,518.93,298.09Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M541.82,319.27c-6.11,0-8.6-3.44-8.6-7.61V297.13A1.11,1.11,0,0,1,534.3,296h2.29a1.12,1.12,0,0,1,1.09,1.11v14.79c0,3.77,1.81,4.8,4.35,4.8,3.33,0,4.35-2,4.35-4.8V297.13a1.11,1.11,0,0,1,1.08-1.11h1.87a1.12,1.12,0,0,1,1.09,1.11v14.53C550.42,316.48,547.39,319.27,541.82,319.27Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M562.36,307.4h1.12c2.31,0,4.11-1.17,4.11-4.26,0-3.39-1.75-4.5-4.22-4.5h-3.53v19.07a1,1,0,0,1-1,1.08h-2.27a1.09,1.09,0,0,1-1.08-1.08V297.13a1.11,1.11,0,0,1,1.08-1.11h8.2c4.42,0,7,2.69,7,7.12,0,4-2.34,6.7-7,6.7a15,15,0,0,1-2.29-.18c-.94-.16-1.37-.67-1.37-1.2A1.12,1.12,0,0,1,562.36,307.4Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M452.35,318.79h-2.16a1.1,1.1,0,0,1-1.12-1.11v-9.09h-8.79v9.09a1.1,1.1,0,0,1-1.12,1.11H437a1.11,1.11,0,0,1-1.12-1.11V297.13A1.12,1.12,0,0,1,437,296h2.23a1.11,1.11,0,0,1,1.08,1.11v8.26h8.79v-8.26a1.11,1.11,0,0,1,1.08-1.11h2.24a1.11,1.11,0,0,1,1.08,1.11v20.55A1.1,1.1,0,0,1,452.35,318.79Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M221.17,319.19c-7,0-9.49-3.94-9.49-8.62V294.3a1.29,1.29,0,0,1,1.25-1.27h2a1.29,1.29,0,0,1,1.24,1.27V311c0,4.24,2.34,5.38,5.12,5.38,3.69,0,5.13-1.88,5.13-5.38V294.3a1.29,1.29,0,0,1,1.24-1.27h1.73a1.29,1.29,0,0,1,1.24,1.27v16.27C230.6,316,227.31,319.19,221.17,319.19Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M319.23,318.79H311a1.11,1.11,0,0,1-1.09-1.1V297.15A1.14,1.14,0,0,1,311,296h8.22c5.41,0,9.7,2.9,9.7,11.51S324.31,318.79,319.23,318.79Zm-1.05-20.13h-3.89v17.49h3.89c5.14,0,6.39-3.9,6.39-8.62S323.36,298.66,318.18,298.66Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M352.36,318.8h-1.95a1.24,1.24,0,0,1-1.25-1.25V307.14H338.71v10.41a1.25,1.25,0,0,1-1.26,1.25H335.5a1.24,1.24,0,0,1-1.25-1.25v-23.1a1.26,1.26,0,0,1,1.22-1.25h2a1.26,1.26,0,0,1,1.22,1.25v9.41h10.45v-9.41a1.26,1.26,0,0,1,1.22-1.25h2a1.26,1.26,0,0,1,1.21,1.25v23.1A1.24,1.24,0,0,1,352.36,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M413.61,318.8h-11a1.11,1.11,0,0,1-1.12-1.12V297.13a1.11,1.11,0,0,1,1.08-1.11h2.3a1.11,1.11,0,0,1,1.08,1.11v18.45h7.65a1.13,1.13,0,0,1,1.09,1.12v1A1.11,1.11,0,0,1,413.61,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M264.92,318.8H262.7a1.13,1.13,0,0,1-1.12-1.14V297.15a1.13,1.13,0,0,1,1.09-1.13H265a1.13,1.13,0,0,1,1.08,1.13v20.51A1.13,1.13,0,0,1,264.92,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M286,299.23h-5.18v18.43a1.13,1.13,0,0,1-1.12,1.14h-2.22a1.14,1.14,0,0,1-1.12-1.14V299.23h-5.21a1.09,1.09,0,0,1-1.09-1.08v-1a1.13,1.13,0,0,1,1.09-1.12H286a1.12,1.12,0,0,1,1.08,1.12v1A1.09,1.09,0,0,1,286,299.23Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M430.74,299.23h-5.53v18.43a1.13,1.13,0,0,1-1.11,1.14h-2.23a1.14,1.14,0,0,1-1.12-1.14V299.23h-5.57a1.09,1.09,0,0,1-1.08-1.08v-1a1.12,1.12,0,0,1,1.08-1.12h15.56a1.12,1.12,0,0,1,1.08,1.12v1A1.09,1.09,0,0,1,430.74,299.23Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M304.88,318.8H292a1,1,0,0,1-1.08-1V297.14A1.12,1.12,0,0,1,292,296h12.38a1.12,1.12,0,0,1,1.08,1.12v.9a1.09,1.09,0,0,1-1.08,1.08h-9v6.32h8.1a1.11,1.11,0,0,1,1.08,1.11v.91a1.1,1.1,0,0,1-1.08,1.08h-8.1v7.14h9.47A1.11,1.11,0,0,1,306,316.8v1A1,1,0,0,1,304.88,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M373.49,318.8H360.65a1,1,0,0,1-1.09-1V297.14a1.13,1.13,0,0,1,1.09-1.12H373a1.12,1.12,0,0,1,1.08,1.12v.9a1.09,1.09,0,0,1-1.08,1.08h-9v6.32h8.09a1.12,1.12,0,0,1,1.09,1.11v.91a1.1,1.1,0,0,1-1.09,1.08H364v7.14h9.47a1.11,1.11,0,0,1,1.08,1.11v1A1,1,0,0,1,373.49,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M253.64,318.8h-3.36a1.75,1.75,0,0,1-1.79-1.07c-1.68-3.55-8.14-18.06-8.14-18.06v18a1.12,1.12,0,0,1-1.09,1.11h-1.51a1.11,1.11,0,0,1-1.08-1.11V297.11a1.1,1.1,0,0,1,1.08-1.09H241a1.83,1.83,0,0,1,1.9,1.06C244.68,300.89,251,315,251,315V297.11a1.11,1.11,0,0,1,1.09-1.09h1.51a1.1,1.1,0,0,1,1.08,1.09v20.58A1.11,1.11,0,0,1,253.64,318.8Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M482.87,318.05a15.57,15.57,0,0,1-7,1.31c-6.42,0-12.17-2.7-12.17-13.36,0-8.37,3.54-13.36,12.17-13.36a15.69,15.69,0,0,1,6.7,1.28c.79.33.84.5.84.86,0,.18-.1.72-.12.86-.13.65-.31,1.71-.41,2.28-.06.32-.1.76-.58.76s-.74-.46-1-.68a7.64,7.64,0,0,0-5.47-2.33c-6.12,0-7.16,5.87-7.16,10.33,0,6.16,2,10.33,7.16,10.33a11.1,11.1,0,0,0,3.32-.41v-8.44c0-.51.3-.67.83-.67h2.83c.6,0,.8.24.8.71v9.24C483.64,317.63,483.59,317.73,482.87,318.05Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M397.25,317.35l-6.55-20.29a1.53,1.53,0,0,0-1.6-1.05h-2.89a1.45,1.45,0,0,0-1.54,1.05l-6.52,20.42a1.5,1.5,0,0,0-.14.52.8.8,0,0,0,.79.79h1.47a1.54,1.54,0,0,0,1.59-1.05l1.28-4.26h8.3l1.35,4.26a1.48,1.48,0,0,0,1.62,1.05h2.15a.78.78,0,0,0,.82-.82A2.29,2.29,0,0,0,397.25,317.35Zm-13.31-6.67,3.39-11.29,3.2,11.29Z"
        transform="translate(-211.68 -292.64)"
      />
      <path
        fill={color}
        d="M577,295.54a3.28,3.28,0,1,1-3.28,3.27A3.27,3.27,0,0,1,577,295.54Zm0,6.06a2.79,2.79,0,1,0-2.66-2.79A2.67,2.67,0,0,0,577.05,301.6Zm-1.26-4.71h1.47c.88,0,1.31.35,1.31,1.08a1,1,0,0,1-1,1.06l1.09,1.7H578l-1.07-1.7h-.53v1.7h-.62Zm.62,1.65h.75c.48,0,.79-.1.79-.6s-.39-.56-.79-.56h-.75Z"
        transform="translate(-211.68 -292.64)"
      />
    </svg>
  )
}

export default Logo
