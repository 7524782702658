import React from 'react'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import styles from './Admin.module.scss'
import { useLocation } from 'react-router-dom'
import GeoProvider from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'
import AdminHeader from './AdminHeader'
import CountUser from './CountUser'
import ButtonAddUser from './ButtonAddUser'

const Vulnerability = () => {
  const { user } = useAuthState()
  const location = useLocation()
      
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.content}>
              {/* <Header /> */}
              <GeoProvider>
                    <AdminHeader/>  
                    <div>
                    <CountUser userCount={128}/>  
                    <ButtonAddUser/>
                    </div> 
              </GeoProvider>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Vulnerability
