import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthDispatch } from '../../components/AuthProvider'

const SignOut = () => {
  const { clearSession } = useAuthDispatch()

  React.useEffect(() => {
    clearSession()
  }, [clearSession])

  return <Redirect to="/" />
}

export default SignOut
