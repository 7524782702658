import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout'
import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Content from '../../../components/Content'
import ViewActivityDetails from './ViewActivityDetails'
import ActivityStats from './ActivityStats'
import UserActivityCount from './UserActivityCount'
import DateFilter from './DateFilter'
import GeoProvider from '../../../components/GeoProvider'
import styles from './UserActivity.module.scss'
import { startDate, endDate } from '../UserActivity/dates'
import AdminHeader from '../../AdminPages/AdminHeader'
const UserActivity = () => {
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            {/* <DefaultBreadcrumbs />
            <div className={styles.content}>
              <h1>User Activity Metrics</h1>
              <p>
                User activity for all the users who have the access of this
                system.
              </p>
            </div> */}
          </div>
          <GeoProvider fromDate={new Date(startDate)}
              toDate={new Date(endDate)}>              
          <div className={styles.body}>
          <AdminHeader/>
            <DateFilter/>
            <UserActivityCount />
            <ActivityStats />
            <ViewActivityDetails />
          </div>
          
          </GeoProvider>
          </div>
      </Content>
    </Layout>
  )
}

export default UserActivity
