import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import DataTable, {
  DropDownListFilter,
} from '../../../components/DataTableProfile'
// import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import Spinner from '../../../components/Loader/Spinner'
import ButtonList from '../../../components/ButtonList'
import LinkButton from '../../../components/LinkButton'
import Modal from '../../../components/ModalPopupProfile'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import { useMessageToast } from '../../../components/MessageProvider'

import geographyHandler from '../../../utils/geographyHandler'
import handleResponse from '../../../utils/handleFetchResponse'
import dateSortHandler from '../../../utils/dateSorterDatatable'
import geoTypeDisplay from '../../../utils/geoTypeDisplay'

import styles from './ViewSubscription.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import Icon from '../../../components/Icon'
import ModelAddSubs from '../../../components/ModalPopupAddSubs'
import AddSubscription from '../AddEditSubscription/index'
import ButtonListSubs from '../../../components/ButtonListSubs'
// import { FastField } from 'formik'
// import { useLocation } from 'react-router-dom'
import LinkButtonSubs from '../../../components/LinkButtonSubs'
import { useGeoState } from '../../../components/GeoProvider'

const Subscription = () => {
  const { user } = useAuthState()
  const history = useHistory()
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const subscriptionsUrl = `${baseUrl}/subscriptions`
  const toast = useMessageToast()
  const [loading, setLoading] = useState({
    isLoading: false,
    hasLoaded: false,
    hasError: false,
  })
  const [subscriptions, setSubscriptions] = useState([])
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})

  // const [showAddsubscribeDialog, setShowAddsubscribeDialog] = useState(false) //Added
  //Added

  const { showAddsubscribePop, setShowAddsubscribePop } = useGeoState()

  var token = window.localStorage.getItem('access_token')

  const [cbsaJsonData, setCbsaJsonFile] = useState()

  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        // console.log(data)
        setCbsaJsonFile(data)
      })
      .catch(function (err) {
        console.log(err, ' error file load cb_2018_us_cbsa_20m_wkid102004 ')
      })
  }, [])

  const onAddsubscribeClick = () => {
    //setShowAddsubscribeDialog(true)
    setShowAddsubscribePop(true)
  }

  const handleDeleteSuccess = () => {
    setLoading((loading) => ({
      ...loading,
      isLoading: false,
      hasLoaded: true,
    }))
    setShowUnsubscribeDialog(false)
    toast.success(`Subscription deleted successfully`)
    setSubscriptions(subscriptions.filter((u) => u !== selectedSubscription))
  }

  const handleDeleteError = () => {
    setLoading({
      hasError: true,
      isLoading: false,
      hasLoaded: false,
    })
    toast.error(`Error when attempting to delete subscription`)
  }

  const deleteSubscriptionHandler = () => {
    setLoading((loading) => ({ ...loading, isLoading: true }))
    fetch(`${subscriptionsUrl}/${selectedSubscription.subscription_id}`, {
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json',
        id_token: token,
      },
    })
      .then((response) => {
        if (!response.ok) throw Error('Failed to delete user')
        handleDeleteSuccess()
      })
      .catch(() => {
        handleDeleteError()
      })
    setShowUnsubscribeDialog(false)
  }

  const createSubscriptionsData = useCallback((data, cbsaJsonData) => {
    const tableRows = []
    for (const key in data) {
      const obj = data[key]
      const subscriptions = data[key].subscriptions
      for (let subscription of subscriptions) {
        tableRows.push({
          ...subscription,
          user_name: key,
          full_name: obj.last_name + ', ' + obj.first_name,
          start_date: subscription.start_date.split('T')[0],
          end_date: subscription.end_date.split('T')[0],
          geography: geographyHandler(
            subscription.geo_type,
            subscription.geo_id,
            cbsaJsonData
          ),
          geoTypeDisplay: geoTypeDisplay(subscription.geo_type),
        })
      }
    }
    setSubscriptions(tableRows)
  }, [])

  const getListOfSubscriptions = useCallback(
    (cbsaJsonData) => {
      setLoading((loading) => ({ ...loading, isLoading: true }))
      fetch(`${subscriptionsUrl}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          id_token: token,
        },
      })
        .then((res) => handleResponse(res))
        .then((response) => {
          setLoading((loading) => ({
            ...loading,
            isLoading: false,
            hasLoaded: true,
          }))
          createSubscriptionsData(response, cbsaJsonData)
        })
        .catch((err) => {
          setLoading((loading) => ({
            ...loading,
            hasError: true,
            isLoading: false,
          }))
        })
    },
    [subscriptionsUrl, createSubscriptionsData, token]
  )

  const Unsubscribe = ({
    cell: {
      row: { original: subscription },
    },
  }) => {
    const onUnsubscribeClick = () => {
      setSelectedSubscription(subscription)
      setShowUnsubscribeDialog(true)
    }

    return (
      <ButtonList className={styles.icons}>
        <LinkButton icon="" onClick={onUnsubscribeClick}>
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.09 1.41L14.67 5H0.5V7H14.67L11.08 10.59L12.5 12L18.5 6L12.5 0L11.09 1.41ZM19.5 0V12H21.5V0H19.5Z"
              fill="white"
            />
          </svg>
        </LinkButton>
      </ButtonList>
    )
  }

  const columns = [
    { Header: 'User Name', accessor: 'user_name', defaultCanSort: true },
    { Header: 'Name', accessor: 'full_name' },
    {
      Header: 'Location Type',
      accessor: 'geoTypeDisplay',
      Filter: DropDownListFilter,
    },
    { Header: 'Location Name', accessor: 'geography' },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      sortMethod: dateSortHandler,
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      sortMethod: dateSortHandler,
    },
    {
      Header: 'Infection Type',
      accessor: 'infection_type',
      Filter: DropDownListFilter,
    },
    {
      Header: 'Notification Type',
      accessor: 'notification_type',
      Filter: DropDownListFilter,
    },
    { Header: 'Notification To', accessor: 'notification_to' },
    {
      Header: '',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: Unsubscribe,
    },
  ]

  const onUnsubscribeCancelClick = () => {
    setShowUnsubscribeDialog(false)
    setSelectedSubscription({})
  }

  useEffect(() => {
    if (cbsaJsonData !== undefined) {
      getListOfSubscriptions(cbsaJsonData)

      var logObject = {
        dashboard_url: window.location.href,
        dashboard_name: 'My Subscriptions',
        indicator_name: 'My Subscriptions',
        geo_name: '',
        geo_type: '',
        user_name: user.username,
        email: user.email,
      }
      activityLog(logObject)
    }
  }, [cbsaJsonData])

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            {/* <DefaultBreadcrumbs /> */}
            <div className={styles.content}>
              <p className={styles.h3Format}>Profile</p>
              {/* <p>
                This page allows you to View, Add or Edit your subscriptions.
                Emails and SMS will be sent out every Monday.
              </p> */}
              <div>
                <table className={styles.tableColor}>
                  <tr>
                    <th
                      className={styles.thFormat}
                      style={{ paddingLeft: '10px' }}
                    >
                      Name
                    </th>
                    <th className={styles.thFormat}>User Name</th>
                    <th className={styles.thFormat}>Email</th>
                    <th className={styles.thFormat}>Phone</th>
                    <th className={styles.thFormat}>Role</th>
                    <th className={styles.thFormat}>Authentication System</th>
                  </tr>
                  <tr>
                    <td
                      className={styles.tdFormat}
                      style={{ paddingLeft: '10px' }}
                    >
                      {user.firstName} {user.lastName}
                    </td>
                    <td className={styles.tdFormat}>
                      <td>{user.username}</td>
                    </td>
                    <td className={styles.tdFormat}>{user.email}</td>
                    <td className={styles.tdFormat}>{user.phone}</td>
                    <td className={styles.tdFormat}>{user.role}</td>
                    <td className={styles.tdFormat}>OHID</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className={styles.body} style={{ marginTop: '-15px' }}>
            <div>
              <h3 className={styles.h3Format}>Subscription</h3>
            </div>
            <Flex gutter={3}>
              {loading.isLoading ? (
                <Spinner />
              ) : loading.hasLoaded ? (
                <Flex.Item span={12}>
                  <ButtonListSubs spacing={2} style={{}}>
                    <LinkButtonSubs
                      icon="add"
                      // onClick={() => {
                      //   history.push('/subscriptions/add')
                      // }}
                      onClick={onAddsubscribeClick}
                    >
                      Subscription
                    </LinkButtonSubs>
                  </ButtonListSubs>
                  <DataTable
                    data={subscriptions}
                    columns={columns}
                    hasPagination
                    //hasFiltering
                    hasSorting
                  />
                </Flex.Item>
              ) : null}
            </Flex>
          </div>
          <ModelAddSubs
            open={showAddsubscribePop}
            onClose={() => setShowAddsubscribePop(false)}
          >
            <AddSubscription location={''} />
          </ModelAddSubs>
          <Modal
            open={showUnsubscribeDialog}
            onClose={() => setShowUnsubscribeDialog(false)}
          >
            <button
              noValidate
              style={{ marginLeft: '90%' }}
              onClick={onUnsubscribeCancelClick}
            >
              <Icon type="remove" size={'1.5rem'} />
            </button>
            <div
              className={styles.tdFormat}
              style={{ marginBottom: '0rem', fontSize: '18px' }}
            >
              Subscription
              {/* Are you sure you want to delete this subscription? */}
              <div className={styles.modal}>
                <div style={{ display: 'contents' }}>
                  <div style={{ height: '20px' }} className={styles.thFormat}>
                    Name{' '}
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.full_name}
                  </div>
                </div>
                <div style={{ display: 'contents' }}>
                  <div style={{ height: '20px' }} className={styles.thFormat}>
                    Username
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.user_name}
                  </div>
                </div>
                <div style={{ display: 'contents' }}>
                  <div style={{ height: '20px' }} className={styles.thFormat}>
                    Subscription Type
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.notification_type}
                  </div>
                </div>
                <div style={{ display: 'contents' }}>
                  <div style={{ height: '20px' }} className={styles.thFormat}>
                    Email
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.notification_to}
                  </div>
                </div>
                <div>
                  <div
                    style={{ height: '20px', width: '47%' }}
                    className={styles.thFormat}
                  >
                    Start Date
                  </div>
                  <div
                    style={{ height: '20px', width: '50%' }}
                    className={styles.thFormat}
                  >
                    End Date
                  </div>
                </div>
                <div>
                  <div
                    className={styles.tdFormat}
                    style={{
                      paddingBottom: '1rem',
                      height: '20px',
                      width: '47%',
                    }}
                  >
                    {' '}
                    {selectedSubscription.start_date}
                  </div>
                  <div
                    className={styles.tdFormat}
                    style={{ paddingBottom: '1rem', height: '20px' }}
                  >
                    {' '}
                    {selectedSubscription.end_date}
                  </div>
                </div>
                <div style={{ display: 'contents' }}>
                  <div style={{ height: '20px' }} className={styles.thFormat}>
                    Infection Type
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.infection_type}
                  </div>
                </div>
                <div style={{ display: 'contents' }}>
                  <div
                    style={{ display: 'contents' }}
                    className={styles.thFormat}
                  >
                    Location Name
                  </div>
                  <div className={styles.delvalFormat}>
                    {' '}
                    {selectedSubscription.geography}
                  </div>
                </div>
              </div>
            </div>
            <ButtonList>
              <Button onClick={onUnsubscribeCancelClick}>Close</Button>
              <Button primary type="submit" onClick={deleteSubscriptionHandler}>
                Unsubscribe
              </Button>
            </ButtonList>
          </Modal>
        </div>
      </Content>
    </Layout>
  )
}

export default Subscription
