import React from 'react'
import styles from './GenderDistributionGraph.module.scss'
import toolTipFormatter from '../../pages/FluVaccination/toolTipFormatter'
import dataDisplayOptions from '../../pages/FluVaccination/dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import PieChart from './../../components/PieChart'
import countingMethod from './../../utils/getCountingMethod'
import { startDate, endDate } from '../../pages/FluVaccination/dates'

const GenderDistributionGraph =  ({ stateCode, getVulCountData }) => {
  var getdemographic = `getdemographic/${stateCode}`
  return (
    <div className={styles.base}>
      <div className={styles.medicalPanel}>

      <span>
         Gender Distribution         
          <span>
            Patients Count <strong> {countingMethod(getVulCountData.sampleGenderPatientCount, 'fixed')}</strong>
          </span>
        </span>
      
      {getVulCountData.sampleGenderPatientCount && (  <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="vulnerability"
          location={getdemographic}
          locationDisplay="getdemographic"
          startDate={startDate}
          endDate={endDate}
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="samplePie"
          pieFor="vul"
          countPatient={getVulCountData.sampleGenderPatientCount}
        />
        )}
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
      <div className={styles.pharmacyPanel}>
      <span>
      Gender Distribution       
          <span>
            Patients Count <strong>{countingMethod(getVulCountData.populationGenderPatientCount, 'fixed')}</strong>
          </span>
        </span>
        {getVulCountData.sampleGenderPatientCount && (  <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="vulnerability"
          location={getdemographic}
          locationDisplay="getdemographic"
          startDate={startDate}
          endDate={endDate}
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="populationPie"
          pieFor="vulPopulation"
          countPatient={getVulCountData.populationGenderPatientCount}
        />
        )}
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>

      </div>
  )
}

export default GenderDistributionGraph
