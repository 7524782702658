import React from 'react'
import Highcharts from 'highcharts'
import more from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

const Chart = ({ chartType, chartOptions, height = 'inherit' }) => {
  if (chartType === 'arearange') {
    if (typeof Highcharts === 'object') {
      more(Highcharts)
    }
  }

  return (
    <HighchartsReact
      containerProps={{
        style: {
          height: height,
          width: '100%',
          position: 'relative',
        },
      }}
      highcharts={Highcharts}
      options={chartOptions}
      allowChartUpdate
      updateArgs={[true, true, true]}
    />
  )
}

export default Chart
