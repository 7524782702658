import React from 'react'
import LinkButton from '../LinkButton'
import FormulaMAE from './FormulaMAE'
import FormulaMAPE from './FormulaMAPE'
import FormulaRMSD from './FormulaRMSD'
import FormulaMASE from './FormulaMASE'

import styles from './AccuracyTable.module.scss'
const AccuracyTable = ({ columns, data }) => {
  const [btnTableExpanded1, setBtnTableExpanded1] = React.useState(false)
  const [btnTableExpanded2, setBtnTableExpanded2] = React.useState(false)
  const [btnTableExpanded3, setBtnTableExpanded3] = React.useState(false)
  const [btnTableExpanded4, setBtnTableExpanded4] = React.useState(false)
  const TableRows = ({ index }) => {
    switch (index) {
      case 0:
        return (
          <tr>
            <td
              colSpan={4}
              bgcolor={'#f7f7f7'}
              className={
                btnTableExpanded1
                  ? styles.formulaSpanShow
                  : styles.formulaSpanHide
              }
            >
              <FormulaMAE />
            </td>
          </tr>
        )
      case 1:
        return (
          <tr>
            <td
              colSpan={4}
              bgcolor={'#f7f7f7'}
              className={
                btnTableExpanded2
                  ? styles.formulaSpanShow
                  : styles.formulaSpanHide
              }
            >
              <FormulaMAPE />
            </td>
          </tr>
        )
      case 2:
        return (
          <tr>
            <td
              colSpan={4}
              bgcolor={'#f7f7f7'}
              className={
                btnTableExpanded3
                  ? styles.formulaSpanShow
                  : styles.formulaSpanHide
              }
            >
              <FormulaRMSD />
            </td>
          </tr>
        )
      case 3:
        return (
          <tr>
            <td
              colSpan={4}
              bgcolor={'#f7f7f7'}
              className={
                btnTableExpanded4
                  ? styles.formulaSpanShow
                  : styles.formulaSpanHide
              }
            >
              <FormulaMASE />
            </td>
          </tr>
        )
      default:
      // code block
    }
  }
  return (
    <div className={styles.content}>
      <table>
        <thead>
          <tr>
            <th style={{ width: '35%' }}>Error Metric</th>
            <th style={{ width: '30%' }}>Revised</th>
            <th style={{ width: '30%' }}>UnRevised</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data[0].error_metric}</td>
            <td>
              {data[0].revised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {data[0].unrevised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              <LinkButton
                icon={!btnTableExpanded1 ? 'arrow_down' : 'arrow_up'}
                iconSize="12px"
                onClick={() => {
                  setBtnTableExpanded1(!btnTableExpanded1)
                }}
              >
                {!btnTableExpanded1 ? 'View' : 'Close'}
              </LinkButton>
            </td>
          </tr>
          <TableRows index={0} />
          <tr>
            <td>{data[1].error_metric}</td>
            <td>
              {data[1].revised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {data[1].unrevised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              <LinkButton
                icon={!btnTableExpanded2 ? 'arrow_down' : 'arrow_up'}
                iconSize="12px"
                onClick={() => {
                  setBtnTableExpanded2(!btnTableExpanded2)
                }}
              >
                {!btnTableExpanded2 ? 'View' : 'Close'}
              </LinkButton>
            </td>
          </tr>
          <TableRows index={1} />
          <tr>
            <td>{data[2].error_metric}</td>
            <td>
              {data[2].revised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {data[2].unrevised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              <LinkButton
                icon={!btnTableExpanded3 ? 'arrow_down' : 'arrow_up'}
                iconSize="12px"
                onClick={() => {
                  setBtnTableExpanded3(!btnTableExpanded3)
                }}
              >
                {!btnTableExpanded3 ? 'View' : 'Close'}
              </LinkButton>
            </td>
          </tr>
          <TableRows index={2} />
          {/* <tr>
            <td>{data[3].error_metric}</td>
            <td>
              {data[3].revised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {data[3].unrevised.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              <LinkButton
                icon={!btnTableExpanded4 ? 'arrow_down' : 'arrow_up'}
                iconSize="12px"
                onClick={() => {
                  setBtnTableExpanded4(!btnTableExpanded4)
                }}
              >
                {!btnTableExpanded4 ? 'View' : 'Close'}
              </LinkButton>
            </td>
          </tr>
          <TableRows index={3} /> */}
        </tbody>
      </table>
    </div>
  )
}
export default AccuracyTable
