const getThresholdValue = (level) => {
  switch (level) {
    case 1:
      return {
        threshold: 'Minimal',
        color: '#218371',
        OutlineColor: '#218371',
      }
    case 2:
      return {
        threshold: 'Low',
        color: '#6CB2A2',
        OutlineColor: '#15A796',
      }
    case 3:
      return {
        threshold: 'Medium',
        color: '#FFFFF0',
        OutlineColor: '#C39F00',
      }
    case 4:
      return {
        threshold: 'High',
        color: '#FB8D7B',
        OutlineColor: '#FF1A1A',
      }
    case 5:
      return {
        threshold: 'Very High',
        color: '#C40000',
        OutlineColor: '#C40000',
      }

    default:
      return {
        threshold: 'NA',
        color: 'grey',
        OutlineColor: '#C40000',
      }
  }
}

export default getThresholdValue
