export default function getEpiWeek(date) {
  const thisDate = new Date(date)
  var target = new Date(thisDate.valueOf())
  var dayPs = (thisDate.getDay() + 7) % 7
  target.setDate(target.getDate() - dayPs + 3)
  var jan4 = new Date(target.getFullYear(), 0, 4)
  var dayDifference = (target - jan4) / 86400000
  if (new Date(target.getFullYear(), 0, 1).getDay() < 4) {
    return 1 + Math.ceil(dayDifference / 7)
  } else {
    return Math.ceil(dayDifference / 7)
  }
}
