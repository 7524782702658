import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'

import Spinner from '../Loader/Spinner'

import getLineChartOptions from './getLineChartOptions'

import styles from './LineChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const LineChartDisplay = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  data,
  dataDisplayOptions,
  filterid,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [trendOptionSelect, setTrendOptionSelect] = useState(
    dataDisplayOptions[0].exp
  )
  const [trendNameSelect, setTrendNameSelect] = useState(
    dataDisplayOptions[0].label
  )

  var hydratedData = data;
  const chartOptions = getLineChartOptions(
    toolTipFormatter,
    hydratedData,
    trendNameSelect
  )
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
    }),
  }

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default LineChartDisplay
