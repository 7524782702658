import React from 'react'
import AppName from './AppName'
import Container from '../Container'
import Logo from '../Logo'
import { ThemeContext } from '../Theme'

import styles from './Header.module.scss'

const padding = { xs: [0, 1], md: [0, 3], lg: [0, 4], xl: [0, 5] }

const Header = ({ appName, children = null, logoUrl, onLogoClick }) => {
  const theme = React.useContext(ThemeContext)

  return (
    <header className={`${styles.base} ${styles[theme]}`}>
      <Container className={styles.container} padding={padding}>
        <a
          className={`${styles.logo} ${onLogoClick ? styles.pointer : ''}`}
          href={logoUrl}
          onClick={onLogoClick}
        >
          <Logo />
        </a>
        {appName && <AppName>{appName}</AppName>}
        {children && <div className={styles.items}>{children}</div>}
      </Container>
    </header>
  )
}

export default Header
