const FormulaRMSD = ({ color = '#000000' }) => {
  return (
    <>
      <p
        style={{
          marginBottom: '0px',
          'line-height': '18px',
          'padding-top': '10px',
        }}
      >
        <strong>RMSD / RMSE : </strong> The root-mean-square deviation (RMSD) or
        root-mean-square error (RMSE) is a frequently used measure of the
        differences between values (sample or population values) predicted by a
        model, or an estimator and the values observed. The RMSD represents the
        square root of the second sample moment of the differences between
        predicted values and observed values or the quadratic mean of these
        differences. It is penalizing large errors more and consider error
        direction.
        <svg viewBox="0 0 582.000000 258.000000" focusable="false">
          <g
            transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)"
            fill={color}
            stroke="none"
          >
            <path
              d="M2322 1998 c-16 -21 -22 -65 -32 -253 -12 -240 -28 -479 -43 -650 -7
-77 -12 -215 -12 -308 1 -110 -3 -166 -10 -164 -5 2 -27 37 -48 78 -21 41 -46
82 -56 92 -17 18 -19 18 -55 -7 -45 -30 -46 -40 -4 -46 23 -4 37 -13 48 -35 8
-16 17 -32 20 -35 3 -3 24 -42 45 -88 32 -66 44 -82 60 -80 25 4 31 37 45 271
6 100 15 223 20 272 5 50 9 133 9 185 1 52 6 125 11 161 5 37 12 129 15 205 2
77 10 190 16 251 l12 113 1471 2 c1463 3 1471 3 1471 23 0 20 -8 20 -1486 23
-1182 2 -1489 -1 -1497 -10z"
            />
            <path
              d="M2418 1702 c-28 -5 -22 -21 42 -112 63 -89 66 -104 31 -145 -10 -11
-23 -27 -29 -35 -7 -8 -26 -31 -42 -50 -16 -19 -30 -38 -30 -42 0 -13 68 -20
173 -16 58 1 109 6 113 10 3 4 8 24 11 45 2 21 5 43 8 50 2 6 -3 17 -11 24
-19 15 -34 2 -50 -44 -10 -30 -14 -32 -75 -38 -78 -8 -97 -1 -72 27 54 60 93
115 93 131 0 11 -13 35 -30 53 -16 18 -30 37 -30 42 0 4 -9 17 -21 29 -31 31
-12 42 59 34 50 -6 61 -11 71 -32 8 -17 18 -23 34 -21 32 4 38 72 7 88 -19 10
-205 12 -252 2z"
            />
            <path
              d="M3414 1676 c-52 -54 -69 -102 -69 -186 0 -133 57 -211 159 -217 49
-3 55 -1 82 29 56 63 94 116 94 132 0 9 5 16 10 16 6 0 16 12 23 28 6 15 18
33 25 41 7 7 12 25 10 40 -3 23 -8 26 -38 26 l-35 0 3 -53 c3 -40 0 -58 -14
-73 -26 -28 -31 -24 -37 32 -12 97 -61 129 -106 68 -25 -34 -21 -50 8 -32 30
19 48 -12 56 -93 7 -66 6 -73 -16 -93 -12 -11 -30 -21 -38 -21 -9 0 -26 -3
-37 -6 -45 -12 -97 82 -97 176 0 86 41 164 94 182 7 2 10 12 6 21 -10 27 -49
19 -83 -17z"
            />
            <path
              d="M4893 1703 c-17 -6 -16 -33 1 -33 42 0 96 -101 96 -180 0 -24 -8 -65
-17 -90 -17 -42 -56 -90 -74 -90 -18 0 -8 -29 12 -35 16 -5 30 2 56 27 52 50
73 104 73 188 0 83 -17 127 -69 183 -33 35 -50 42 -78 30z"
            />
            <path
              d="M5120 1690 c-28 -28 -26 -50 5 -50 14 0 25 5 25 11 0 16 44 6 48 -12
1 -8 -20 -40 -47 -70 -28 -30 -51 -60 -51 -66 0 -17 36 -25 96 -21 40 2 49 7
52 23 3 30 -14 53 -30 37 -11 -11 -58 -17 -58 -7 0 2 20 23 45 46 29 27 45 50
45 65 0 35 -34 64 -75 64 -22 0 -43 -8 -55 -20z"
            />
            <path
              d="M2742 1668 c-15 -15 -15 -41 -1 -55 7 -7 8 -22 1 -46 -8 -28 -7 -39
4 -48 19 -16 44 5 44 38 0 33 28 73 51 73 21 0 24 -11 8 -27 -6 -6 -9 -28 -7
-49 3 -34 6 -39 31 -42 22 -3 30 1 34 17 3 12 9 21 14 21 5 0 9 7 9 15 0 18
-27 20 -33 3 -2 -7 -8 -9 -13 -5 -4 4 -3 17 4 27 7 11 12 33 12 50 0 35 -29
47 -75 30 -19 -7 -31 -8 -39 0 -13 13 -29 13 -44 -2z"
            />
            <path
              d="M4559 1643 c-79 -87 -96 -132 -43 -119 22 6 23 3 30 -54 4 -33 11
-63 15 -66 14 -9 11 -19 -19 -52 -16 -18 -38 -32 -49 -32 -11 0 -25 -6 -32
-13 -15 -19 5 -37 41 -37 22 0 39 14 83 67 30 37 55 73 55 79 0 7 6 17 13 21
15 10 46 68 58 107 7 23 4 35 -13 60 -61 87 -88 95 -139 39z m84 -33 c18 -17
19 -22 7 -30 -12 -7 -12 -12 0 -35 11 -21 12 -30 2 -45 -7 -11 -12 -29 -12
-41 0 -14 -5 -19 -17 -17 -12 2 -23 22 -33 63 -9 33 -20 67 -24 74 -6 9 -1 20
15 32 30 24 35 23 62 -1z"
            />
            <path
              d="M4043 1515 c-11 -31 12 -36 153 -33 131 3 139 4 139 23 0 19 -8 20
-143 23 -123 2 -143 0 -149 -13z"
            />
            <path
              d="M3185 1450 c-10 -11 -24 -20 -32 -20 -26 0 -13 -29 15 -32 25 -3 27
-7 30 -51 3 -46 2 -49 -27 -60 -61 -23 -30 -47 60 -47 51 0 77 18 57 38 -7 7
-21 12 -31 12 -17 0 -19 7 -16 78 2 42 0 83 -4 90 -12 18 -31 15 -52 -8z"
            />
            <path
              d="M3786 1443 c-23 -19 -27 -29 -20 -41 7 -10 7 -29 0 -54 -7 -27 -7
-44 0 -56 24 -38 94 -20 94 23 0 25 -14 33 -25 15 -11 -18 -25 -11 -25 13 0
29 18 47 46 47 29 0 48 22 32 38 -7 7 -19 12 -27 12 -8 0 -22 6 -30 14 -14 13
-20 11 -45 -11z"
            />
            <path
              d="M4746 1438 c-15 -17 -17 -25 -8 -39 9 -14 8 -25 -3 -46 -23 -44 -4
-83 40 -83 27 0 41 11 50 39 7 23 5 24 -25 18 -28 -6 -31 -5 -25 11 4 9 10 25
12 35 3 9 13 17 23 17 54 0 69 37 20 47 -16 3 -30 9 -30 14 0 16 -35 8 -54
-13z"
            />
            <path
              d="M2895 1430 c-4 -6 -1 -18 6 -26 9 -11 31 -14 98 -12 78 3 86 5 86 23
0 18 -8 20 -92 23 -58 2 -94 -1 -98 -8z"
            />
            <path
              d="M2758 1407 c-20 -16 -25 -26 -19 -36 5 -8 7 -38 4 -67 -5 -51 -5 -53
21 -60 34 -8 48 -2 65 30 16 28 8 47 -11 28 -19 -19 -38 -14 -38 11 0 32 11
44 48 52 38 8 42 30 7 39 -14 4 -25 11 -25 16 0 17 -24 11 -52 -13z"
            />
            <path
              d="M2894 1335 c-11 -29 11 -36 102 -33 81 3 89 5 89 23 0 18 -8 20 -93
23 -77 2 -93 0 -98 -13z"
            />
            <path
              d="M133 1304 c-4 -10 1 -22 12 -30 16 -12 17 -18 7 -39 -7 -14 -12 -35
-12 -46 0 -28 -37 -129 -55 -149 -21 -23 -1 -40 47 -40 42 0 59 20 39 44 -8
10 -9 25 -3 48 6 26 14 33 32 33 20 0 26 -8 40 -60 14 -53 20 -60 43 -63 33
-4 46 21 23 43 -9 9 -16 25 -16 35 0 10 -4 22 -10 25 -18 11 -11 35 14 46 13
6 32 28 41 49 16 34 16 40 1 68 -22 43 -44 52 -126 52 -56 0 -72 -3 -77 -16z
m149 -47 c14 -22 8 -46 -17 -72 -27 -26 -50 -31 -75 -15 -12 7 -12 12 -2 22 6
6 12 26 12 44 0 18 6 35 13 38 17 7 60 -3 69 -17z"
            />
            <path
              d="M433 1304 c-4 -11 1 -22 13 -31 15 -11 17 -17 6 -33 -6 -11 -12 -32
-12 -47 0 -16 -7 -41 -15 -57 -8 -15 -15 -33 -15 -40 0 -6 -7 -24 -15 -40 -21
-40 -13 -56 30 -56 37 0 59 25 35 40 -8 5 -10 19 -6 41 4 19 9 60 13 92 6 58
19 75 28 35 2 -13 6 -54 7 -93 3 -66 5 -70 28 -73 21 -2 28 5 45 44 11 25 26
50 33 56 6 6 12 19 12 29 0 11 7 19 15 19 8 0 15 -6 15 -14 0 -28 -34 -121
-48 -133 -26 -21 -6 -43 38 -43 43 0 59 16 42 45 -10 18 4 124 19 139 5 6 9
25 9 42 0 18 7 40 16 49 20 20 11 45 -16 45 -33 0 -57 -17 -64 -45 -4 -14 -11
-25 -16 -25 -6 0 -10 -11 -10 -24 0 -13 -7 -26 -15 -30 -8 -3 -15 -11 -15 -19
0 -15 -50 -66 -50 -51 -5 106 -14 180 -23 186 -23 14 -77 9 -84 -8z"
            />
            <path
              d="M847 1297 c-15 -12 -30 -37 -33 -55 -6 -31 -3 -37 45 -81 28 -26 51
-54 51 -63 0 -41 -75 -64 -107 -33 -18 18 -43 4 -43 -25 0 -31 26 -43 88 -38
77 5 107 33 107 99 0 38 -5 50 -29 70 -64 54 -67 58 -56 79 13 24 56 26 81 5
15 -14 18 -14 33 0 23 23 20 52 -6 59 -50 13 -105 6 -131 -17z"
            />
            <path
              d="M1067 1306 c-3 -8 1 -23 10 -31 12 -13 13 -21 5 -40 -6 -13 -14 -47
-18 -75 -4 -28 -11 -53 -15 -56 -5 -3 -9 -14 -9 -25 0 -10 -6 -28 -12 -39 -19
-31 5 -41 83 -38 54 3 73 9 103 32 21 15 40 36 43 45 4 9 11 19 17 23 6 4 11
40 11 82 0 69 -2 78 -28 103 -24 25 -36 28 -106 31 -64 3 -79 1 -84 -12z m151
-62 c27 -31 28 -59 3 -116 -18 -42 -45 -67 -87 -79 -40 -12 -46 2 -28 72 9 35
18 80 21 99 3 19 10 41 16 48 13 17 47 6 75 -24z"
            />
            <path
              d="M1483 1184 c-10 -27 18 -34 147 -34 129 0 157 7 147 34 -5 14 -27 16
-147 16 -120 0 -142 -2 -147 -16z"
            />
            <path
              d="M2383 1154 c-3 -8 -1 -20 4 -25 5 -5 570 -8 1464 -7 1446 3 1454 3
1454 23 0 20 -8 20 -1458 23 -1317 2 -1458 1 -1464 -14z"
            />
            <path
              d="M1491 1101 c-8 -5 -11 -16 -8 -25 5 -14 27 -16 147 -16 120 0 142 2
147 16 3 9 0 20 -8 25 -18 11 -260 11 -278 0z"
            />
            <path
              d="M3722 903 c-12 -14 -19 -30 -16 -35 7 -11 34 -10 34 2 0 6 5 10 10
10 12 0 14 -43 3 -54 -13 -13 -30 -104 -23 -116 4 -7 16 -10 26 -8 13 2 23 23
37 78 10 41 22 79 27 85 12 14 -34 65 -59 65 -9 0 -27 -12 -39 -27z"
            />
            <path
              d="M3840 915 c-15 -18 -5 -35 21 -35 23 0 24 -16 3 -81 -20 -60 -9 -99
28 -99 24 0 68 39 68 59 0 15 -37 14 -43 -1 -2 -7 -8 -10 -12 -6 -4 4 0 25 9
48 20 51 20 94 0 114 -19 20 -58 21 -74 1z"
            />
          </g>
        </svg>
      </p>

      <p style={{ marginBottom: '0px' }}>
        RMSD is always non-negative, and a value of 0 (almost never achieved in
        practice) would indicate a perfect fit to the data. In general, a lower
        RMSD is better than a higher one. However, comparisons across different
        types of data would be invalid because the measure is dependent on the
        scale of the numbers used.
      </p>
    </>
  )
}

export default FormulaRMSD
