import { epiweekToolTip } from '../../toolTipFormatterFlu'

const getLineChartOptions = (data, weeks, chartName = '') => {
  return {
    chart: {
      type: 'line',
    },
    title: {
      text: chartName,
    },

    xAxis: {
      title: {
        text: 'Epi Weeks (52 Weeks)',
      },
      categories: weeks,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: epiweekToolTip,
    },
    yAxis: {
      title: {
        text: 'Flu2',
      },
      min: 0,
    },

    credits: {
      enabled: false,
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: data,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
}

export default getLineChartOptions
