import React, { useState, useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import moment from 'moment'
import DashboardHeader from './DashboardHeader'
import Filter from './Filter'
import LineGraph from './LineChart'
import FluIndicatorTable from './FluIndicatorTable'
import { startDate, endDate } from '../dates'
import Spinner from '../../../components/Loader/Spinner'
const FluSurveillance = () => {

  const [errorOccured, seterrorOccured] = useState(false)
  const entity = 'surveillance'
  const [nats, setNATs] = useState()
  const [natsAll, setAllNATs] = useState()
  const { fromDate, toDate, locationCode, locationType } = useGeoState()

  const { data: natsData, error: natDataError } = useGeoFetch(
    moment(fromDate).format('YYYY-MM-DD'),
    moment(toDate).format('YYYY-MM-DD'),
    entity,
    locationType + '/Flu?identifier=' + locationCode
  )

  useEffect(() => {
    if (natsData !== undefined) {
     
        setNATs(natsData)
     
    }
  }, [natsData])

  const { data: natsAllData, error: natAllDataError } = useGeoFetch(
    startDate,
    endDate,
    entity,
    'NAT/Flu?identifier=NAT'
  )

  useEffect(() => {
    if (natsAllData !== undefined) {
  
        setAllNATs(natsAllData)
    
    }
  }, [natsAllData])

  var headerData = []
  var data = []
 
  if (natAllDataError || natDataError || errorOccured) {
    return (
      <></>
    )
  }
  return (
    <div>
      {natsAll !== undefined ? (
        <DashboardHeader data={headerData} nationalStats={natsAll} />
      ) : (
        <Spinner />
      )}

      <hr style={{ height: '.5px', background: 'grey' }} />
      <Filter />
      <hr style={{ height: '.2px', background: 'grey' }} />
      {nats !== undefined ? (
        <LineGraph data={data} nationalStats={nats} />
      ) : (
        <Spinner />
      )}
      {nats !== undefined ? (
        <FluIndicatorTable data={data} nationalStats={nats} />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default FluSurveillance
