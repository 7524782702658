import React, { useState, useEffect } from 'react'
import CovidHRRMap from '../../../../pages/Forecasts/HRR/CovidHRRMap'
import MapLegend from './mapLegend'
import dataDisplayOptions from './dataDisplayOptions'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import toolTipFormatter from './toolTipFormatter'
import EmptyMap from '../../../../components/Map/emptyMap'
import CovidCountiesMap from './CovidCountiesMap'
const CountyComponent = ({countyResponseData, countyJson, covidErrorCountyData }) => {
    const {
        dataStartDate,
        dataEndDate,
        mapOptions,
        regionSelected,
        diseaseSelected,        
    } = useGeoState()
    const { user } = useAuthState()
    const [errorCode, setErrorCode] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubject, setErrorSubject] = useState()
    const [errorOccured, seterrorOccured] = useState(false)
    const [hrrDataResponse, setHRRsData] = useState()
    const [countiesData, setCountiesData] = useState()
    const { data: countyData, error: countyDataError } = useGeoFetch(
        dataStartDate,
        dataEndDate,
        'covid',
        'accuracyreport/county'
      )
      useEffect(() => {
        if (countyData !== undefined || countyData === null) {
          if (countyData.response_code === 200) {
            setCountiesData(countyData.response_data)
            countyResponseData(countyData.response_data)
            
          } else {
            setErrorCode(countyData.response_code)
            setErrorMessage(countyData.response_message)
            setErrorSubject(countyData.response_subject)
            seterrorOccured(true)
          }
        }
      }, [countyData])


      const { data: errCountyData, error: errCountyDataError } = useGeoFetch(
        dataStartDate,
        dataEndDate,
        'covid',
        'errormetric/county'
      )
      useEffect(() => {
        if (errCountyData !== undefined || errCountyData === null) {
          if (errCountyData.response_code === 200) {
            covidErrorCountyData(errCountyData.response_data)
          } else {
            setErrorCode(errCountyData.response_code)
            setErrorMessage(errCountyData.response_message)
            setErrorSubject(errCountyData.response_subject)
            seterrorOccured(true)
          }
        }
      }, [errCountyData])

    return (
        <>
            {regionSelected === 'county' && diseaseSelected === 'covid' && (
              <>
                 <MapLegend/>
                {countiesData !== undefined && countyJson ? (
                  <CovidCountiesMap
                    countyData={countiesData}
                    mapOptions={dataDisplayOptions[0]['optionscovid'][0]}
                    toolTipFormatter={toolTipFormatter}
                    countyJson={countyJson}
                  />
                ) : (
                  <EmptyMap />
                )}
              </>
            )}

        </>
    )
}

export default CountyComponent