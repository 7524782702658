import React from "react";
import { useMedia } from "../ResponsiveMedia";

import styles from "./AppName.module.scss";

const AppName = ({ children = null }) => {
  const display = useMedia({ xs: "none", md: "block" });

  return (
    <div className={styles.base} style={{ display }}>
      <span>{children}</span>
    </div>
  );
};

export default AppName;
