import React from "react";
import { Dialog } from "@reach/dialog";
import Card from "../Card";

import "@reach/dialog/styles.css";
import styles from "./ModalPopupSubs.module.scss";

const ModalPopupSubs = ({ children, onClose, open }) => (
  <Dialog
    className={styles.base}
    role="dialog"
    aria-labelledby="dialog"
    onDismiss={onClose}
    isOpen={open}
  >
    <Card className={styles.card} primary>
      {children}
    </Card>
  </Dialog>
);

export default ModalPopupSubs;
