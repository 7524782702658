export default function LegendCasesWeeklyHrr(props) {
  return (
    <svg
      viewBox="0 0 100 280"
      height="275"
      width="100"
      align="center"
      {...props}
    >
      <rect
        x="0"
        y="225"
        width="25"
        height="25"
        fill="#E0E8F3"
        id="ILI_V_13"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="200"
        width="25"
        height="25"
        fill="#D4E2F4"
        id="ILI_V_12"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="175"
        width="25"
        height="25"
        fill="#BAD4F7"
        id="ILI_V_11"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="150"
        width="25"
        height="25"
        fill="#91BBF5"
        id="ILI_V_10"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="125"
        width="25"
        height="25"
        fill="#75ABF5"
        id="ILI_V_9"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="100"
        width="25"
        height="25"
        fill="#4C93F3"
        id="ILI_V_8"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="75"
        width="25"
        height="25"
        fill="#2F7AE1"
        id="ILI_V_7"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="50"
        width="25"
        height="25"
        fill="#226CD3"
        id="ILI_V_6"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="25"
        width="25"
        height="25"
        fill="#08397E"
        id="ILI_V_5"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="0"
        width="25"
        height="25"
        fill="#04224C"
        id="ILI_V_4"
        className="LegendColorCodes"
      ></rect>
      <rect
        x="0"
        y="250"
        width="25"
        height="25"
        fill="#D3D3D3"
        id="ILI_V_3"
        className="LegendColorCodes"
      ></rect>
      <text x="40" y="250" className="legend-label" fontSize="13">
        1
      </text>
      <text x="40" y="225" className="legend-label" fontSize="13">
        35
      </text>
      <text x="40" y="200" className="legend-label" fontSize="13">
        75
      </text>
      <text x="40" y="175" className="legend-label" fontSize="13">
        120
      </text>
      <text x="40" y="150" className="legend-label" fontSize="13">
        175
      </text>
      <text x="40" y="125" className="legend-label" fontSize="13">
        245
      </text>
      <text x="40" y="100" className="legend-label" fontSize="13">
        350
      </text>
      <text x="40" y="75" className="legend-label" fontSize="13">
        503
      </text>
      <text x="40" y="50" className="legend-label" fontSize="13">
        783
      </text>
      <text x="40" y="25" className="legend-label" fontSize="13">
        2000
      </text>
      <text x="40" y="275" className="legend-label" fontSize="13">
        No Data
      </text>
    </svg>
  )
}
