import { React, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useAuthState } from '../AuthProvider'

import TextBox from '../TextBox'
import Label from '../Label'
import ButtonList from '../ButtonList'
import Button from '../Button'
import DropDownList from '../DropDownList'
import { useMessageToast } from '../MessageProvider'
import stateOptions from '../Map/usStatesAll'
//import usMsaAll from '../Map/cb_2018_us_cbsa_20m_wkid102004.json1'
import { useGeoState } from '../GeoProvider'

// TO-DO this can be used when optimised way to show County list is found
// import countyMap from '../../utils/countyMap'

import infectionTypeOptions from './infectionTypeOptions'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './addEditSubscription.module.scss'
import './datePicker.scss'
import Icon from '../../components/Icon'
import IconInfo from '../../components/IconInfo'
import ErrorMessage from '../../components/Layout/ErrorMessage'

const AddSubscriptionForm = ({
  existingRegions = {
    FLU: { state: [], city: [] },
    'COVID-19': { state: [], city: [], county: [] },
  },
  //Hi
  successRedirect = '/subscriptions', //'/profile/subscriptions',
  loadValues = {},
  activeCbsas = {},
  cbsaJson
}) => {

  const history = useHistory()
  const { showAddsubscribePop, setShowAddsubscribePop } = useGeoState(false)  
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const subscriptionsUrl = `${baseUrl}/subscriptions`
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

  const { user } = useAuthState()

  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const filteredCbsasFlu = cbsaJson.features
    .filter(
      (msa) =>
        !existingRegions['FLU']['city'].includes(String(msa.properties.cbsa)) &&
        activeCbsas['FLU'].includes(msa.properties.cbsa)
    )
    .map((msa) => {
      return {
        value: String(msa.properties.cbsa),
        label: msa.properties.name,
      }
    })

  const filteredStatesFlu = stateOptions
    .filter(
      (state) => !existingRegions['FLU']['state'].includes(String(state.value))
    )
    .map((state) => {
      return {
        value: state.value,
        label: state.label,
      }
    })

  const filteredCbsasCovid = cbsaJson.features
    .filter(
      (msa) =>
        !existingRegions['COVID-19']['city'].includes(
          String(msa.properties.cbsa)
        ) && activeCbsas['COVID-19'].includes(msa.properties.cbsa)
    )
    .map((msa) => {
      return {
        value: String(msa.properties.cbsa),
        label: msa.properties.name,
      }
    })

  const filteredStatesCovid = stateOptions
    .filter(
      (state) =>
        !existingRegions['COVID-19']['state'].includes(String(state.value))
    )
    .map((state) => {
      return {
        value: state.value,
        label: state.label,
      }
    })

  // TO-DO this can be used when optimised way to show County list is found
  // const countyData = countyMap()
  // const filteredCountysCovid = Object.entries(countyData)
  //   .filter(([key, _]) => !existingRegions['COVID-19']['county'].includes(key))
  //   .map(([key, value]) => {
  //     return {
  //       value: key,
  //       label: value,
  //     }
  //   })

  const filteredCountysCovid = []

  const handleSuccess = () => {
    if (loadValues['regions']) {
      toast.success(`Subscription updated successfully`)
    } else {
      //toast.success(`Subscription added successfully`)
    }
    toast.success(`Subscription added successfully`)
    setShowAddsubscribePop(false)
    history.push(successRedirect)
    window.location.reload(true)
  }

  const handleError = () => {
    toast.error(`Error adding subscription`)
  }

  const resetRegions = (locationType, values, setFieldValue) => {
    setFieldValue('regionCategory', locationType)
    values.regions = []
  }

  const toast = useMessageToast()
  const submitSubscription = (data, resetForm) => {

    let subscriptions = []
    data.regions.forEach((region) => {
      const record = {
        geo_id: region.value,
        geo_type: data.regionCategory,
        infection_type: data.infectionType,
        notification_type: data.subscriptionType,
        notification_to: data[data.subscriptionType],
        start_date: moment(data.startDate).format('YYYY-MM-DD'),
        end_date: moment(data.endDate).format('YYYY-MM-DD'),
      }
      subscriptions.push(record)
    })
    
    const payload = { [user.username]: { subscriptions: subscriptions } }
    fetch(subscriptionsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',      
     },
      body: JSON.stringify(payload),
    })
      .then((response) => {        
        if (!response.ok) {
          setErrorCode(response.status)
          setErrorMessage(response.statusText)
          setErrorSubject(response.statusText)
          seterrorOccured(true)
        }
        //throw Error('Failed to Add Subscription')
        else {
          handleSuccess()
        }
      })
      .catch(() => {        
        handleError()
      })
  }
//Error handling
if (errorOccured) 
{
  return (
    <ErrorMessage
      errorCode={errorCode}
      errorMessage={errorMessage}
      errorSubject={errorSubject}
    />
  )
}

  function MySelect(props) {
    const handleChange = (value) => {
      props.onChange('regions', value)
    }

    const handleBlur = () => {
      props.onBlur('regions', true)
    }

    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        borderRadius: '0px',
        width: '100%',
        border: '0.0625rem solid #999999',
      }),
    }
    let options
    if (props.infectionType === 'FLU') {
      if (props.regionCategory === 'STATE') {
        options = filteredStatesFlu
      } else {
        options = filteredCbsasFlu
      }
    } else {
      if (props.regionCategory === 'STATE') {
        options = filteredStatesCovid
      } else if (props.regionCategory === 'CBSA') {
        options = filteredCbsasCovid
      } else {
        options = filteredCountysCovid
      }
    }

    if (loadValues.regions) {
      loadValues['regions'].forEach((f) => {
        options.push(f)
      })
    }

    return (
      <div>            
         <div class={styles.tooltip}>
        <Label error={props.touched && props.error} htmlFor="region">         
          Locations {'  '}
          {/* <Tooltip>
            <Content> */}
            <IconInfo
            className={styles.icon}
            height="15"
            width="15"
            type="info_filled"
          />
              <span class={styles.tooltiptext}>
                Selecting multiple locations will create multiple subscriptions
                that can be managed from the subscription list view.
              </span>
             {/* </Content>
          </Tooltip>       */}
        </Label>
        </div>
        <Select
          isDisabled={loadValues['regions'] ? true : false}
          isMulti
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={props.value}
          styles={colourStyles}
        />
        {!!props.error && props.touched && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>{props.error}</div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.details}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          infectionType: loadValues['infection_type']
            ? loadValues['infection_type']
            : 'FLU',
          subscriptionType: loadValues['notification_type']
            ? loadValues['notification_type']
            : 'email',
          email: loadValues['email'] ? loadValues['email'] : '',
          sms: loadValues['sms'] ? loadValues['sms'] : '',
          startDate: loadValues['start_date']
            ? loadValues['start_date']
            : moment().toDate(),
          endDate: loadValues['end_date']
            ? loadValues['end_date']
            : moment().add(90, 'day').toDate(),
          regionCategory: loadValues['geo_type']
            ? loadValues['geo_type']
            : 'STATE',
          regions: loadValues['regions'] ? loadValues['regions'] : '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .when('subscriptionType', {
              is: 'email',
              then: Yup.string()
                .trim()
                .required('Email field is required')
                .max(100, 'Email should be max 100 characters long.'),
            }),
          sms: Yup.string().when('subscriptionType', {
            is: 'sms',
            then: Yup.string()
              .trim()
              .required('Phone number is required')
              .matches(phoneRegExp, 'Phone number is not valid'),
          }),
          startDate: Yup.date()
            .required('Start Date is required.')
            .max(Yup.ref('endDate'), 'Start Date should be before End Date.')
            .typeError('Invalid End date'),
          endDate: Yup.date()
            .required('End Date is required.')
            .min(new Date(), 'End Date should be after today.')
            .typeError('Invalid End date'),
          regions: Yup.array()
            .required('Region is required.')
            .min(1, 'Pick at least 1 region')
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
              })
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          submitSubscription(values, resetForm)
          setSubmitting(false)
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          } = props

          return (
            <>            
              <button
                noValidate
                style={{ marginLeft: '90%' }}
                onClick={() => {
                  setShowAddsubscribePop(false)
                }}
              >
                <Icon type="remove" size={'1.5rem'} />
              </button>
              <form onSubmit={handleSubmit} autoComplete="off">              
              <div><Label style={{fontWeight:'700'}}>Add Subscription</Label></div>
                <div>
                  {/* Below button added for close click */}

                  {loadValues['notification_type'] ? (
                    <>
                      <Label>SUBSCRIPTION TYPE</Label>
                      <div>{loadValues['notification_type']}</div>{' '}
                    </>
                  ) : (
                    <DropDownList
                      errorText={errors.access}
                      id="subscriptionType"
                      value={values.subscriptionType}
                      name="subscriptionType"
                      label={
                        <Label error={errors.access} htmlFor="subscriptionType">
                          <b>Subscription Type</b>
                        </Label>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="33%"
                      isDisabled={
                        loadValues['notification_type'] ? true : false
                      }
                    >
                      <DropDownList.Item value="email">Email</DropDownList.Item>

                      <DropDownList.Item value="sms">SMS</DropDownList.Item>
                    </DropDownList>
                  )}
                </div>
                {values.subscriptionType === 'email' && (
                  <>
                    <div>
                      {loadValues['email'] ? (
                        <>
                          <Label>EMAIL</Label>
                          <div>{loadValues['email']}</div>{' '}
                        </>
                      ) : (
                        <TextBox
                          errorText={touched.email ? errors.email : null}
                          id="email"
                          label={
                            <Label
                              error={touched.email && errors.email}
                              htmlFor="email"
                            >
                              <b>Email</b>
                            </Label>
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          value={values.email}
                          width="100%"
                        />
                      )}
                    </div>
                  </>
                )}
                {values.subscriptionType === 'sms' && (
                  <div>
                    {loadValues['sms'] ? (
                      <>
                        <Label>PHONE</Label>
                        <div>{loadValues['sms']}</div>{' '}
                      </>
                    ) : (
                      <TextBox
                        errorText={touched.sms ? errors.sms : null}
                        id="sms"
                        label={
                          <Label
                            error={touched.sms && errors.sms}
                            htmlFor="sms"
                          >
                            <b>PHONE</b>
                          </Label>
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={values.sms}
                        disabled={loadValues['sms'] ? true : false}
                        width="100%"
                      />
                    )}
                  </div>
                )}
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '45%' }}>
                    <Label
                      error={touched.startDate && errors.startDate}
                      htmlFor="startDate"
                    >
                      Start Date
                    </Label>
                    <DatePicker
                      selected={values.startDate}
                      dateFormat="MM/dd/yyyy"
                      name="startDate"
                      minDate={moment().toDate()}
                      maxDate={moment().add(365, 'day').toDate()}
                      placeholderText="Select a date"
                      onChange={(date) => setFieldValue('startDate', date)}
                      scrollableYearDropdown
                    />
                    {!!errors.startDate && touched.startDate && (
                      <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                  <div style={{ width: '45%', marginLeft: '22px' }}>
                    <Label
                      error={touched.endDate && errors.endDate}
                      htmlFor="endDate"
                    >
                      End Date
                    </Label>
                    <DatePicker
                      selected={values.endDate}
                      dateFormat="MM/dd/yyyy"
                      className="form-control"
                      name="endDate"
                      minDate={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)}
                      maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
                      placeholderText="Select a date"
                      onChange={(date) => setFieldValue('endDate', date)}
                    />
                    {!!errors.endDate && touched.endDate && (
                      <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {errors.endDate}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '45%' }}>
                    {loadValues['infection_type'] ? (
                      <>
                        <Label>INFECTION TYPE</Label>
                        <div>{loadValues['infection_type']}</div>{' '}
                      </>
                    ) : (
                      <DropDownList
                        errorText={errors.access}
                        id="infectionType"
                        value={values.infectionType}
                        name="infectionType"
                        label={
                          <Label error={errors.access} htmlFor="infectionType">
                            <b>Infection</b>
                          </Label>
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width="110%"
                        isDisabled={loadValues['infection_type'] ? true : false}
                      >
                        {Object.values(infectionTypeOptions).map(
                          (infectionType) => (
                            <DropDownList.Item
                              key={infectionType.label}
                              value={infectionType.value}
                            >
                              {infectionType.label}
                            </DropDownList.Item>
                          )
                        )}
                      </DropDownList>
                    )}
                  </div>
                  <div style={{ width: '45%', marginLeft: '22px' }}>
                    {loadValues['geo_type'] ? (
                      <>
                        <Label>Location Type</Label>
                        <div>{loadValues['geo_type']}</div>{' '}
                      </>
                    ) : (
                      <DropDownList
                        errorText={errors.access}
                        id="regionCategory"
                        value={values.regionCategory}
                        name="regionCategory"
                        label={
                          <Label error={errors.access} htmlFor="regionCategory">
                            <b>Area </b>
                          </Label>
                        }
                        onChange={(locType) =>
                          resetRegions(
                            locType.target.value,
                            values,
                            setFieldValue
                          )
                        }
                        onBlur={handleBlur}
                        width="108%"
                        isDisabled={loadValues['geo_type'] ? true : false}
                      >
                        {infectionTypeOptions[values.infectionType][
                          'regionsOptions'
                        ].map((regiontype) => (
                          <DropDownList.Item
                            key={regiontype.label}
                            value={regiontype.value}
                          >
                            {regiontype.label}
                          </DropDownList.Item>
                        ))}
                      </DropDownList>
                    )}
                  </div>
                </div>
                <div>
                  {loadValues['regions'] ? (
                    <>
                      <Label>Selected Location(s)</Label>
                      <div>{loadValues['regions'][0].label}</div>{' '}
                    </>
                  ) : (
                    <MySelect
                      value={values.regions}
                      infectionType={values.infectionType}
                      regionCategory={values.regionCategory}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.regions}
                      touched={touched.regions}
                    />
                  )}
                </div>
                <ButtonList>                 
                  <Button onClick={() => history.push(successRedirect)}>
                    Cancel
                  </Button>
                  <Button type="submit">Add</Button>
                </ButtonList>
              </form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddSubscriptionForm
