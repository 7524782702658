
import React, { useState } from 'react'
import styles from './RiskFilter.module.scss'
import ageDisplayOption from './ageDisplayOptions'
import gender from './genderDisplayOptions'
import Select from 'react-select'
import race from './raceDisplayOptions'
import ethinicity from './ethinicityDisplayOptions'
import cholesterol from './cholesterolDisplayOptions'
import bmi from './bmiDisplayOptions'
import smoking from './smokingDisplayOptions'
import optional from './optionalDisplayOptions'
import criticality from './criticalityDisplayOptions'
import alcohol from './alcoholDisplayOptions'
import Spinner from '../../components/Loader/Spinner'
import { useGeoState } from '../../components/GeoProvider'

const RiskFilter = () => {

  const [getAge, setAge] = useState()
  const [getGender, setGender] = useState()
  const [getRace, setRace] = useState()
  const [getEthinicity, setEthinicity] = useState()
  const [getCholesterol, setCholesterol] = useState()
  const [getBMI, setBMI] = useState()
  const [getSmoking, setSmoking] = useState()
  const [getAlcohol, setAlcohol] = useState()
  const [getCriticality, setCriticality] = useState() 
  const [getCcsrSelected, setCcsrSelected] = useState([])
  const [getloader, setloader] = useState(false)

  
const { setRiskResult } = useGeoState('')
 //const url ="http://idp-riskcalculatorapi.uhg.com/api/HttpTrigger1?age=1&gender=2&bmi=1&smoke=0&ethnicity=1&criticality=1&race=1&alcohol=2&cholesterol=3&ccsr1=1&ccsr2=1"
 const urlInput=process.env.REACT_APP_RISK_CALC+'api/HttpTrigger1?'
//  var res = fetch(url,  {
//     method: 'GET',   
//   })
//   .then((response) => (response.status === 200 ? response.json() : null))
//   .then((data) => {
//     if (data !== undefined && data !== null) 
//       {
//         //console.log(data.score)
//         setResult(data.score)
//       }
//   })

/* Filter for Selected Options */
let optionsList
if (getCcsrSelected !== null && getCcsrSelected.length > 0) {
  optionsList = getCcsrSelected.map((getCcsrSelected) => {
    return getCcsrSelected.value
  })
} else {
  optionsList = []
}
console.log('optionsList:' +optionsList)
    var ageInput = getAge !== undefined ? 'age=' + getAge : 'age=0' 
    var genderInput= getGender !== undefined ?'&gender=' + getGender :'&gender=0'
    var raceInput= getRace !== undefined ?'&race=' + getRace :'&race=0'
    var bmiInput= getBMI !== undefined ?'&bmi=' + getBMI :'&bmi=0'
    var ethinicityInput= getEthinicity !== undefined ?'&ethnicity=' + getEthinicity :'&ethnicity=0'
    var cholesterolInput= getCholesterol !== undefined ?'&cholesterol=' + getCholesterol :'&cholesterol=0'
    var alcoholInput= getAlcohol !== undefined ?'&alcohol=' + getAlcohol :'&alcohol=0'
    var smokeInput= getSmoking !== undefined ?'&smoke=' + getSmoking :'&smoke=0'
    var criticalityInput= getCriticality !== undefined ?'&criticality=' + getCriticality :'&criticality=0'
    var ccsr1Input= optionsList.length >0  ? '&ccsr1=' + optionsList[0] : '&ccsr1=0'
    var ccsr2Input= optionsList.length >1  ? '&ccsr2=' + optionsList[1] : '&ccsr2=0'
    var finalUri=urlInput + ageInput + genderInput+ bmiInput + smokeInput + ethinicityInput + criticalityInput + raceInput + alcoholInput + cholesterolInput + ccsr1Input + ccsr2Input
    //console.log(finalUri)
  
    const fetchData = async () => {
      try {
        const response = await fetch(finalUri,);        
        const json = await response.json();        
        if (json !== undefined && json !== null) 
        {
          //console.log(json);
          setRiskResult(json.score)
          setloader(false) 
        }         
      } catch (error) {
        console.log("error", error);
      }
    };
   

  return (
    <div>

   
    <div className={styles.base}>
      <div className={styles.demographyPanel}>
        <span className={styles.filterTitle}>Demographics</span>
      </div>
      <div className={styles.lifePanel}>
        <span className={styles.filterTitle}>Lifestyle</span>
      </div>
      <div className={styles.covidPanel}>
        <span className={styles.filterTitle}>COVID-19 Specifications</span>
      </div>
      <div className={styles.demographyFilterPanel}>
        <div className={styles.dateWeek} style={{ paddingLeft: 0 }}>
          <span className={styles.headerSpan}>AGE GROUP</span>          
          <div className={styles.hedaerText}>
          <Select          
            //styles={customStyles}
            defaultValue={ageDisplayOption[0]}
            options={ageDisplayOption}
            onChange={(opt) => {
             //setAge(opt.label)
             setAge(opt.value)    
             setloader(true)          
              fetchData()                          
            }}
          />
         
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>GENDER</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={gender[0]}
            options={gender}
            onChange={(opt) => {
              setGender(opt.value)
              setloader(true)      
              fetchData()   
            }}
          />
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>RACE</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={race[0]}
            options={race}
            onChange={(opt) => {
              setRace(opt.value)
              setloader(true)
              fetchData()  
            }}
          />
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>ETHNICITY</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={ethinicity[0]}
            options={ethinicity}
            onChange={(opt) => {
              setEthinicity(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
      </div>
      <div className={styles.lifeFilterPanel}>
        <div className={styles.dateWeek} style={{ paddingLeft: 0 }}>
          <span className={styles.headerSpan}>CHOLESTEROL</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={cholesterol[0]}
            options={cholesterol}
            onChange={(opt) => {
              setCholesterol(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>BMI</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={bmi[0]}
            options={bmi}
            onChange={(opt) => {
              setBMI(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>SMOKING STATUS</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={smoking[0]}
            options={smoking}
            onChange={(opt) => {
              setSmoking(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
        <div className={styles.dateWeek}>
          <span className={styles.headerSpan}>ALCOHOL</span>
          <div className={styles.hedaerText}>
          <Select
            //styles={customStyles}
            defaultValue={alcohol[0]}
            options={alcohol}
            onChange={(opt) => {
              setAlcohol(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
      </div>
      <div className={styles.covidFilterPanel}>
        <div className={styles.dateWeek} style={{ paddingLeft: 0 }}>
          {/* <span className={styles.headerSpan}>ACUTE PERIOD</span> */}
          <span className={styles.headerSpan}>CRITICALITY DURING COVID</span>
          <div>
          <Select
            //styles={customStyles}
            defaultValue={criticality[0]}
            options={criticality}
            onChange={(opt) => {
              setCriticality(opt.value)
              setloader(true)
              fetchData()
            }}
          />
          </div>
        </div>
      </div>
     
    </div>
  
      <div st className={styles.illness}>
          <div className={styles.dateWeek} style={{ paddingLeft: 0 }}>
            <span className={styles.headerSpan}>
              Optional: Select one or more cardiac-related illness
            </span>
            <div>
            <Select
            isMulti
              //styles={customStyles}
              //defaultValue={optional[0]}
              options={optional}
              onChange={(opt) => {
                setCcsrSelected(opt)
                setloader(true)
                fetchData()
              }}
            />
            </div>
          </div>
        </div>

        <div>
             {getloader ? (
               <Spinner />
                ) : (
                  null
                )}            
          </div>
    </div>
  )
}

export default RiskFilter
