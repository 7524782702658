import React from 'react'
import { useGeoFetch } from '../GeoProvider'
import Spinner from '../Loader/Spinner'

import BarChartDisplay from './BarChartDisplay'

const BarChart = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  dataDisplayOptions,
  chartName,
  filterId,
  fluFolder,
  fluseason, month, lob , type, state
}) => {
  // const [errorCode, setErrorCode] = useState()
  // const [errorMessage, setErrorMessage] = useState()
  // const [errorSubject, setErrorSubject] = useState()
  // const [errorOccured, seterrorOccured] = useState(false)
  // const [getData, setData] = useState()
  
  const { data , error} = useGeoFetch(startDate, endDate, entity,
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`) 
    
  
  return data ? (
    <BarChartDisplay
      toolTipFormatter={toolTipFormatter}
      entity={entity}
      location={location}
      locationDisplay={locationDisplay}
      startDate={startDate}
      endDate={endDate}
      options={options}
      data={data}
      dataDisplayOptions={dataDisplayOptions}
      chartName={chartName}
      error={error}
    />
  ) : (
    <Spinner />
  )
}

export default BarChart
