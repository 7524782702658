import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import EmptyMap from '../../components/Map/emptyMap'
import Map from '../../components/Map'
import MapLegend from './mapLegend'
import styles from './VulDashboard.module.scss'
import dataDisplayOptions from './dataDisplayOptions'
import toolTipFormatter from './toolTipFormatter'
const VulDashboard = () => {
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [states, setStates] = useState()
  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    //let url = `${window.location.origin}/usStatesAll.json`
    let url = `${window.location.origin}/usStatesAll.json`    
   
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)       
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [getStateJsonFile]);
  const { data: statesData, error: stateDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getallstatedata/1'
  )
  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  // if (stateDataError) {
  //   return <ErrorMessage />
  // }
  if (stateDataError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }
  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.map}`}>
        <div className={`${styles.head}`}>
          Percentage of Population at Significant Risk of COVID-19 Associated
          Hopitalization - State Level{' '}
        </div>
        {
          <>
            {states !== undefined && states && getStateJsonFile ? (
              <>
                <MapLegend />
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  vulStateData={states}
                  statesData={null}
                  cbsaData={null}
                  mapOptions={dataDisplayOptions[0]['options'][0]}
                  dynamicMapTitle={false}
                  mapTitleDisable={true}
                  mapType="VUL"
                  stateJson={getStateJsonFile}
                />
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        }
      </div>
    </div>
  )
}

export default VulDashboard
