const cholesterolDisplayOptions = [
  { value: '0', label: 'Select' },
  { value: '1', label: 'Very High' },
  { value: '2', label: 'High'},
  { value: '3', label: 'Borderline High'},
  { value: '4', label: 'Fairly Good'},
  { value: '5', label: 'Optimum'},
  { value: '6', label: 'Unknown'},
]

export default cholesterolDisplayOptions
