import React, { useEffect, useState, useMemo } from 'react'

import Flex from '../../../../components/Flex'
import { useAuthState } from '../../../../components/AuthProvider'
import Content from '../../../../components/Content'
import DataTable, { DropDownListFilter } from '../../../../components/DataTableNew'
import Spinner from '../../../../components/Loader/Spinner'
import ActivityStatsBar from './ActivityStatsBar'
import ActivityDailyChart from './ActivityDailyChart'
import moment from 'moment'
import styles from './ActivityStats.module.scss'
import CustomDataTable from '../CustomDataTable'
import styled from 'styled-components'
import { useGeoState,useGeoFetch } from '../../../../components/GeoProvider'

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
)

const ActivityStats = () => {
  const { fromDate, toDate } = useGeoState()
 
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const userApiUrl = `${clientConfigApiDomain}/admin/usersactivitystats?endDate=${moment(fromDate).format('YYYY-MM-DD')}&startDate=${moment(toDate).format('YYYY-MM-DD') }`
  const { user } = useAuthState()

  const [activitystatsdaily, setActivityStatsDaily] = useState([])
  const [activitystatstotal, setActivityStatsTotal] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const entity = 'admin'
  

  const timeFormatCell = ({
    cell: {
      value,
      row: { original: selectedUser },
    },
  }) => {
    const moment = require('moment-timezone')

    return (
      <span>
        {moment(selectedUser.lastLogin)
          .tz(user.preferences.timeZone)
          .format('L, LTS z')}
      </span>
    )
  }

  const userCsvColumns = [
    {
      Header: 'Dashboard',
      accessor: 'dashboard_name',
    },
    {
      Header: 'Unique Hits',
      accessor: 'unique_hits',
    },
    {
      Header: 'Total Hits',
      accessor: 'total_hits',
    },
  ]

  const userColumns = [
    {
      Header: 'Dashboard',
      accessor: 'dashboard_name',
      sortType: (a, b) => {
        return `${a.values.dashboard_name} ? ${a.values.dashboard_name} : 'NA'`.toLowerCase() >
          `${b.values.dashboard_name} ? ${b.values.dashboard_name} : 'NA'`.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: 'Unique Hits',
      accessor: 'unique_hits',
      sortType: (a, b) => {
        return `${a.values.unique_hits} ? ${a.values.unique_hits} : 'NA'`.toLowerCase() >
          `${b.values.unique_hits} ? ${b.values.unique_hits} : 'NA'`.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: 'Total Hits',
      accessor: 'total_hits',
      sortType: (a, b) => {
        return `${a.values.total_hits} ? ${a.values.total_hits} : 'NA'`.toLowerCase() >
          `${b.values.total_hits} ? ${b.values.total_hits} : 'NA'`.toLowerCase()
          ? 1
          : -1
      },
    },
    {
      Header: '',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const { data: satsData, error: satsDataError } = useGeoFetch(
    moment(fromDate).format('YYYY-MM-DD'),
    moment(toDate).format('YYYY-MM-DD'),
    entity,
    'usersactivitystats'
  )
  useEffect(() => {
    if (satsData !== undefined) {
      setActivityStatsDaily(satsData.daily_hits)
      setActivityStatsTotal(satsData.combined_hits)
    }
  }, [satsData])

  // useEffect(() => {
  //   const getActivityStats = async () => {
  //     const response = await fetch(`${userApiUrl}/`)
  //     const data = await response.json()
  //     setActivityStatsDaily(data.daily_hits)
  //     setActivityStatsTotal(data.combined_hits)
  //   }
  //   if (!hasLoaded) {
  //     setIsLoading(true)
  //     getActivityStats()
  //     setIsLoading(false)
  //     setHasLoaded(true)
  //   }
  // }, [
  //   userApiUrl,
  //   setIsLoading,
  //   setHasLoaded,
  //   hasLoaded,
  //   user.clients,
  //   user.role,
  //   user.orgIds,
  // ])

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  // const filteredItems = activitystatstotal?.filter(    
  //   (row) =>
  //     row?.dashboard_name.toLowerCase()?.includes(filterText.toLowerCase())
  //     //  ||
  //     // row?.unique_hits.toLowerCase()?.includes(filterText.toLowerCase()) ||
  //     // row?.total_hits.toLowerCase()?.includes(filterText.toLowerCase()) 
  // )

  return (
    <>    
      <ActivityStatsBar area={activitystatstotal} yAxisLabel />
      <ActivityDailyChart area={activitystatsdaily} yAxisLabel />      
      <Flex gutter={3}>
        {isLoading ? (
          <Spinner />
        ) : hasLoaded ? (
          <Flex.Item span={12}>
            <Content></Content>
            <div className={styles.base}>
              <h4>User Hits</h4>
              <div>
                <DataTable
                  columns={userColumns}
                  csvColumns={userCsvColumns}
                  data={activitystatstotal}
                  hasPagination
                  hasFiltering
                  hasSorting
                  // hasDownloadCsv={true}
                />
                {/* <CustomDataTable
                title=""
                columns={userColumns}
                data={filteredItems}
                expandableRows={false}
                //expandableRowsComponent={ExpandedComponent}
                pagination
                //paginationResetDefaultPage={resetPaginationToggle} 
                subHeaderComponent={subHeaderComponentMemo}
                /> */}
              </div>
            </div>
          </Flex.Item>
        ) : null}
      </Flex>
    </>
  )
}

export default ActivityStats
