import React,{useEffect} from 'react'
import { useHistory } from 'react-router-dom'

import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import styles from './Dashboard.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import DashboardHeader from './DashboardHeader'
import ModelTabs from './ModelTabs'

const Dashboard = () => {
  // const history = useHistory()

  const { user } = useAuthState()  
  var data = [1]
  return (
    <Layout>
      <Content>
        <DashboardHeader />
        <div className={styles.base}>
          <div className={styles.mainPanel}>
            <ModelTabs key={data}></ModelTabs>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Dashboard
