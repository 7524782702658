import DataTable from 'react-data-table-component';
const customStyles = {
	headRow: {
		style: {
            borderBottom: '1px solid black',
            height:'10px',
            fontWeight:'700'
		},
	},
	headCells: {
		style: {
			color: '#202124',
            fontSize: '14px',
            
		},
	},
	rows: {
        style:{
            border: '1px solid black',
            borderBottom:'0px !important',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
        }
		// highlightOnHoverStyle: {
		// 	backgroundColor: 'rgb(230, 244, 244)',
		// 	borderBottomColor: '#FFFFFF',
		// 	borderRadius: '25px',
        //     outline: '1px solid #FFFFFF',
        //     border: '2px solid red',
        // },
        
	},
	// pagination: {
	// 	style: {
	// 		border: 'none',
	// 	},
	// },
};
function CustomDataTable({ columns, data, title, expandableRows, expandableRowsComponent, pagination,paginationResetDefaultPage, subHeaderComponent }) {   
    return (
        <DataTable
            columns={columns}
            data={data}
            title={title}
            expandableRowsComponent={expandableRowsComponent}
            expandableRows={expandableRows}
            pagination={pagination}
			paginationResetDefaultPage={paginationResetDefaultPage} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponent}
			selectableRows={false}
            persistTableHead={true}
            customStyles={customStyles}
        />
    );
}
export default CustomDataTable