import React from 'react'

import { useGeoFetch } from '../GeoProvider'
import ErrorMessage from '../Layout/ErrorMessage'
import Spinner from '../Loader/Spinner'

import LineChartDisplay from './LineChartDisplay'

const LineChart = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  dataDisplayOptions,
  filterId,
  fluFolder,
  fluseason,
  month,
  lob,
  type,
}) => {
  //console.log(dataDisplayOptions)
  const { data, error } = useGeoFetch(
    startDate,
    endDate,
    entity,    
`${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}`
  )
  // if (error) {
  //   return <ErrorMessage />
  // }

  return data ? (
    <LineChartDisplay
      toolTipFormatter={toolTipFormatter}
      entity={entity}
      location={location}
      locationDisplay={locationDisplay}
      startDate={startDate}
      endDate={endDate}
      options={options}
      data={data}
      dataDisplayOptions={dataDisplayOptions}
    />
  ) : (
    <Spinner />
  )
}

export default LineChart
