import styles from './Tag.module.scss'

const Tag = ({
  dataValue,
  levelOverride, // if present then the dataValue is displayed with the level of the override
  showNumbers = false,
  levelKey = 'forecast_level',
  valueKey = 'forecast_value',
  marginLeft,
}) => {
  if (!dataValue) {
    return <></>
  }

  const castLevel = dataValue[levelKey]
  const castValue = dataValue[valueKey]

  let severityValue
  let title
  let bgColor = levelOverride

  switch (castLevel) {
    case 1:
      bgColor = 'L1'
      severityValue = 'Minimal'
      title = 'Minimal'
      break
    case 2:
      bgColor = 'L2'
      severityValue = 'Low'
      title = 'Low'
      break
    case 3:
      bgColor = 'L3'
      severityValue = 'Medium'
      title = 'Medium'
      break
    case 4:
      bgColor = 'L4'
      severityValue = 'High'
      title = 'High'
      break
    case 5:
      bgColor = 'L5'
      severityValue = 'Very High'
      title = 'Very High'
      break
    // case 6:
    //   bgColor = 'L6'
    //   severityValue = 'Medium'
    //   title = 'Moderate'
    //   break
    // case 7:
    //   bgColor = 'L7'
    //   severityValue = 'Medium'
    //   title = 'Moderate'
    //   break
    // case 8:
    //   bgColor = 'L8'
    //   severityValue = 'High'
    //   title = 'High'
    //   break
    // case 9:
    //   bgColor = 'L9'
    //   severityValue = 'High'
    //   title = 'High'
    //   break
    // case 10:
    //   bgColor = 'L10'
    //   severityValue = 'High'
    //   title = 'High'
    //   break
    // case 11:
    //   bgColor = 'L11'
    //   severityValue = 'Very High'
    //   title = 'Very High'
    //   break
    // case 12:
    //   bgColor = 'L12'
    //   severityValue = 'Very High'
    //   title = 'Very High'
    //   break
    // case 13:
    //   bgColor = 'L13'
    //   severityValue = 'Very High'
    //   title = 'Very High'
    //   break
    default:
      bgColor = levelOverride
      severityValue = 'NA'
      title = 'Not Available'
      break
  }

  let displayValue
  if (showNumbers) {
    displayValue = severityValue
  } else {
    displayValue = levelOverride ? dataValue : Number(castValue).toFixed(2)
  }

  return (
    <span
      className={styles.base + ' ' + styles[bgColor]}
      style={{ marginLeft }}
      title={title}
    >
      {displayValue}
    </span>
  )
}

export default Tag
