import React from 'react'
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useExpanded,
} from 'react-table'
import DropDownListFilter from './DropDownListFilter'
import SelectInputFilter from './SelectInputFilter'
import TextBoxFilter from './TextBoxFilter'
import DropDownList from '../DropDownList/'
import Button from '../Button'
import TextBox from '../TextBox'
import ColumnSelectorCheckbox from './ColumnSelectorCheckbox'
import LinkButton from '../LinkButton'
import Tooltip from '../Tooltip'

import styles from './DataTable.module.scss'

const defaultPropGetter = () => ({})

const DataTable = ({
  columns,
  data,
  csvColumns = null,
  hasFiltering = false,
  hasPagination = false,
  hasSorting = false,
  hasDownloadCsv = false,
  showColumnSelector = false,
  hasSubRows = false,
  hasHover = true,
  tooltipContent = '',
  tooltipPlacement = 'bottom',
  isStripped = true,
  hiddenColumns = [],
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  maxWidth = null,
  tablePadding, // supported values xs (1.25rem)
  ...rest
}) => {
  const [tableExpanded, setTableExpanded] = React.useState(false)
  const defaultColumn = React.useMemo(
    () => ({
      Filter: TextBoxFilter,
    }),
    []
  )

  // WARN: the way of escaping commas and newlines is not universal because there isn't a single CSV spec
  const generateCsvLink = () => {
    let strCsv = ''
    let accessors = []
    if (csvColumns === null) {
      columns.forEach((row) => {
        strCsv += '"' + row.Header.replace(/"/g, '"""') + '",' //Changed syntax to fix gitHub vulnerability
        accessors.push(row.accessor)
      })
    } else {
      csvColumns.forEach((row) => {
        strCsv += '"' + row.Header.replace(/"/g, '""') + '",'  //Changed syntax to fix gitHub vulnerability
        accessors.push(row.accessor)
      })
    }
    data.forEach((row) => {
      strCsv += '\n'
      accessors.forEach((accessor) => {
        const val = String(row[accessor])

        //strCsv += '"' + val.replace('"', '""') + '",'
        strCsv += '"' + val.replace(/"/g, '""') + '",' //Changed syntax to fix gitHub vulnerability
      })
    })
    window.location.href = URL.createObjectURL(
      new Blob([strCsv], { type: 'text/csv' })
    )
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { hiddenColumns },
    },
    hasFiltering && useFilters,
    hasSorting && useSortBy,
    hasPagination && usePagination,
    hasSubRows && useExpanded
  )
  const [pageValue, setPageValue] = React.useState(`${pageIndex + 1}`)
  React.useEffect(() => {
    setPageValue(`${pageIndex + 1}`)
  }, [pageIndex])

  let paddingClass = undefined
  if (tablePadding) {
    switch (tablePadding.toLowerCase()) {
      case 'xs':
        paddingClass = styles.tableXs
        break
      default:
        paddingClass = ''
    }
  }

  return (
    <div className={styles.base}>
      <div className={styles.table + ' ' + paddingClass}>
        {showColumnSelector ? (
          <div className={styles.toggleButton}>
            <LinkButton
              icon={tableExpanded ? 'contract' : 'expand'}
              style={{ outline: '#fff', marginLeft: 'auto' }}
              onClick={() => setTableExpanded(!tableExpanded)}
            />
          </div>
        ) : null}
        <div
          className={`${styles.wrapper} ${
            tableExpanded ? styles.wrapperXl : ''
          } ${rest.className ? rest.className : ''}`}
          style={{ maxWidth: maxWidth }}
        >
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        hasSorting ? column.getSortByToggleProps() : '',
                        {
                          style: column.style,
                        },
                        getHeaderProps(column),
                      ])}
                    >
                      {column.render('Header')}
                      <span>
                        {column.Header === 'Actions' && tooltipContent ? (
                          <Tooltip placement={tooltipPlacement}>
                            {tooltipContent}
                          </Tooltip>
                        ) : null}
                        {column.canSort ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className={styles.iconCaretUp} />
                            ) : (
                              <span className={styles.iconCaretDown} />
                            )
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M4.55 7.15l3.4-4.6 3.45 4.6z"
                                fill="#424242"
                              ></path>
                              <path
                                d="M11.4 8.65l-3.45 4.65-3.4-4.65z"
                                fill="#424242"
                              ></path>
                            </svg>
                          )
                        ) : (
                          ' '
                        )}
                      </span>
                      {column.canFilter && (
                        <div onClick={(event) => event.stopPropagation()}>
                          {column.render('Filter', column.filterProps)}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data.length === 0 ? (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={columns.length}>
                    No data to show
                  </td>
                </tr>
              ) : (
                (hasPagination ? page : rows).map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps([getRowProps(row)])}
                      className={`${hasHover ? styles.hasHover : ''} ${
                        isStripped ? styles.stripped : ''
                      }`}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              { style: cell.column.style },
                              getCellProps(cell),
                            ])}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
        {hasPagination && (
          <div className={styles.pagination}>
            <div>
              <DropDownList
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <DropDownList.Item key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </DropDownList.Item>
                ))}
              </DropDownList>
            </div>
            <div className={styles.controls}>
              <Button
                className={styles.prev}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </Button>
              <Button
                className={styles.next}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </Button>
            </div>
            <div className={styles.pageNumber}>
              <span> Page </span>
              <TextBox
                type="number"
                width="4rem"
                value={pageValue}
                min="1"
                max={pageOptions.length}
                onChange={(event) => {
                  setPageValue(event.target.value.trim())
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    if (/^\d+$/.test(pageValue)) {
                      const pageIndex = Number(pageValue) - 1
                      if (pageIndex < 0) {
                        // out of lower bounds
                        gotoPage(0)
                        setPageValue('1')
                      } else if (pageIndex >= pageCount) {
                        // out of upper bounds
                        gotoPage(pageCount - 1)
                        setPageValue(`${pageCount}`)
                      } else {
                        gotoPage(pageIndex)
                      }
                    } else {
                      // not a valid number
                      gotoPage(0)
                      setPageValue('1')
                    }
                  }
                }}
                title="Press Enter to change the page number."
              />
              <span> of {pageOptions.length} </span>
            </div>
          </div>
        )}
        {hasDownloadCsv && (
          <div className={styles.downloadCsv}>
            <Button
              className={styles.button}
              primary
              disabled={data.length === 0}
              onClick={() => generateCsvLink()}
            >
              Download CSV
            </Button>
          </div>
        )}{' '}
      </div>
      {showColumnSelector ? (
        <ColumnSelectorCheckbox columns={allColumns} isHidden={tableExpanded} />
      ) : null}
    </div>
  )
}

export {
  DataTable as default,
  DropDownListFilter,
  SelectInputFilter,
  TextBoxFilter,
}
