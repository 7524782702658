import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../components/AuthProvider'
import Spinner from '../../components/Loader/Spinner'
import Logo from '../../components/Logo'
import Card from '../../components/Card'

import { APP_NAME } from '../../utils/constants'

import styles from './CreateSession.module.scss'

const CreateSession = () => {  
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const history = useHistory()

  const [{ error, redirectRoute }, { createSession }] = useAuth()

  React.useEffect(() => {
    const cs = async (id_token, client_info, session_state, state) => {
      await createSession(id_token, client_info, session_state, state)
    }    
    const hash = window.location.hash
    const hashParts = hash.substring(1, hash.length).split('&')
    const hashDict = {}

    hashParts.forEach((h) => {
      let hashPartSplit = h.split('=')
      hashDict["$"+hashPartSplit[0]] = hashPartSplit[1]
    })

    if (!hasLoaded) {
      setIsLoading(true)
      cs(
        hashDict[`$id_token`],
        hashDict[`$client_info`],
        hashDict[`$session_state`],
        hashDict[`$state`]
      ).then(() => {
        setIsLoading(false)
        setHasLoaded(true)

        var redirectTo = redirectRoute
        if (redirectRoute === '/login') {
          redirectTo = '/home'
        }

        history.push(redirectTo)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return (
      <div className={styles.base}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo color="#fff" />
          </div>

          <Card className={styles.loginCard}>
            <div className={styles.appName}>
              <span>{APP_NAME}</span>
            </div>
            <div className={styles.loginPreference}>
              <p>
                Filed to log you into the application. If this issue persists
                please contact the IDP team.
              </p>
            </div>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo color="#fff" />
        </div>

        <Card className={styles.loginCard}>
          <div className={styles.appName}>
            <span>{APP_NAME}</span>
          </div>
          <div className={styles.loginPreference}>
            <Spinner /> Logging you into the system...
          </div>
        </Card>
      </div>
    </div>
  )
}

export default CreateSession
