const FormulaMAE = ({ color = '#000000' }) => {
  return (
    <>
      <p
        style={{
          marginBottom: '0px',
          'line-height': '18px',
          'padding-top': '10px',
        }}
      >
        <strong>MAE : </strong> Mean Absolute Error (MAE) is a measure of errors
        between paired observations expressing the same phenomenon. Examples of
        Y versus X include comparisons of predicted versus observed, subsequent
        time versus initial time, and one technique of measurement versus an
        alternative technique of measurement. The signs of the errors are
        removed or made non-directional.
        </p>

      <p style={{ marginBottom: '0px' }}>
        Range from 0 to ∞ and are indifferent to the direction of errors. They
        are negatively oriented scores, which means lower values are better.
        This is known as a scale-dependent accuracy measure and therefore cannot
        be used to make comparisons between series using different scales.
      </p>
    </>
  )
}

export default FormulaMAE
