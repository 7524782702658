import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'

import Spinner from '../Loader/Spinner'

import getHorizontalBarChartOptions from './getHorizontalBarChartOptions'

import styles from './HorizontalBarChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const HorizontalBarChartDisplay = ({
  toolTipFormatter,
  data,
  dataDisplayOptions,
  chartName,
  height
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [trendOptionSelect, setTrendOptionSelect] = useState(
    dataDisplayOptions[0].exp
  )
  const [trendNameSelect, setTrendNameSelect] = useState(
    dataDisplayOptions[0].label
  )
  var vt="";
  var vt1="";
  
  if (chartName === 'getfactor' )
  {
    vt1 = data.medicalFactorsModel;
  }
  if ( chartName === 'countyFactor')
  {
    vt = data.medicalFactorsModel;
  }
  var categories = []
  var all_categories = []
  var state_series_data = []
  var state_series_all_data = []
  
  const finalData = []
 
   if (chartName === 'getfactor' ) {
    if (vt1!==null)
    {
      vt1.forEach((data_val) => {     
      categories.push(data_val.factor)
      state_series_data.push(data_val.factorPerc)
    })
  }
  } 
  if ( chartName ==="countyFactor") {
    if (vt!==null)
    {
    vt.forEach((data_val) => {     
      categories.push(data_val.factor)
      state_series_data.push(data_val.factorPerc)
    })
  }
  } 
  
  
  finalData.push({ name: 'categories', data: categories })
  finalData.push({ name: 'data', data: state_series_data })
  finalData.push({ name: 'all_categories', data: all_categories })
  finalData.push({ name: 'all_data', data: state_series_all_data })
  var hydratedData = []
  const chartOptions = getHorizontalBarChartOptions(
    toolTipFormatter,
    hydratedData,
    trendNameSelect,
    finalData,
    height
  )
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
    }),
  }

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default HorizontalBarChartDisplay
