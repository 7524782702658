const raceDisplayOptions = [
  { value: '0', label: 'Select'},
  { value: '1', label: 'Emergency' },
  { value: '2', label: 'Critical'},
  { value: '3', label: 'NonCritical'},
  { value: '4', label: 'Ventilator'},
  { value: '5', label: 'ECMO'},
]

export default raceDisplayOptions
