import React, { useState, useEffect } from 'react'
import CovidHRRMap from '../../../../pages/Forecasts/HRR/CovidHRRMap'
import MapLegend from './mapLegend'
import dataDisplayOptions from './dataDisplayOptions'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import toolTipFormatterHRR from './toolTipFormatterHRR'
import EmptyMap from '../../../../components/Map/emptyMap'
const HrrComponent = ({stateJson,  hrrData }) => {
    const {
     
        regionSelected,
        setRegionSelected,
        diseaseSelected,
        // dataSelected,
        setHrrEndDate,
    } = useGeoState()
    const { user } = useAuthState()
    const [errorCode, setErrorCode] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubject, setErrorSubject] = useState()
    const [errorOccured, seterrorOccured] = useState(false)
    const [hrrDataResponse, setHRRsData] = useState()
    const { data: accHRRData, error: accHRRDataError } = useGeoFetch(
        '',
        '',
        'covid',
        'hrr/accuracy'
    )
    useEffect(() => {
        if (accHRRData !== undefined || accHRRData === null) {
            if (accHRRData.response_code === 200) {
                setHrrEndDate('2022-06-30')
                setHRRsData(accHRRData.response_data)
                hrrData(accHRRData.response_data)
            } else {
                setErrorCode(accHRRData.response_code)
                setErrorMessage(accHRRData.response_message)
                setErrorSubject(accHRRData.response_subject)
                seterrorOccured(true)
            }
        }
    }, [accHRRData])

    const [getJsonData, setJsonData] = useState();
    useEffect(() => {
        let url = `${window.location.origin}/us_hrr_values.json`
        fetch(url)
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                // console.log(data)
                setJsonData(data);
            })
            .catch(function (err) {
                console.log(err, " error file load us_hrr_values ");
            });
    }, [1]);

    return hrrDataResponse && getJsonData ? (
        <>
            {regionSelected === 'hrr' && diseaseSelected === 'covid' && getJsonData && (
                <>
                    <MapLegend />
                   
                        <CovidHRRMap
                            hrrData={hrrDataResponse}
                            mapOptions={dataDisplayOptions[0]['optionscovid'][2]}
                            toolTipFormatter={toolTipFormatterHRR}
                            hrrMapJson={getJsonData}
                        />
                   
                </>
            )}

        </>
    ):( <EmptyMap  stateJson={stateJson}/>)
}

export default HrrComponent