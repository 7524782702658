import React from 'react'
import Select from 'react-select'

import stateOptions from '../../../../components/Map/usStatesAll'
import Tooltip from '../../../../components/Tooltip'
import Content from '../../../../components/Content'
import Label from '../../../../components/Label'
import IconInfo from '../../../../components/IconInfo'
import 'react-datepicker/dist/react-datepicker.css'
import regionStyles from './regionSelect.module.scss'

const RegionSelect = (props) => {
  const handleChange = (value) => {
    props.onChange('regions', value)
  }

  const handleBlur = () => {
    props.onBlur('regions', true)
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '0px',
      width: '98%',
      borderColor: '#999999',
    }),
  }

  let options
  if (props.regionCategory === 'CBSA') {
    if (props.infectionType === 'FLU') {
      options = props.filteredCbsasFlu
    } else {
      options = props.filteredCbsasCovid
    }
  } else {
    options = stateOptions
  }
  return (
    <div style={{ paddingBottom: '.5rem', paddingTop:'.5rem' }}>
      <Label error={props.touched && props.error} htmlFor="region">
        Select Locations {'  '}
        {/* <Tooltip>
          <Content>
            <p>
              Selecting multiple locations will create multiple subscriptions
              that can be managed from the subscription list view.
            </p>
          </Content>
        </Tooltip> */}
        
      </Label>

      <Select
        style={{ width: '33%' }}
        isDisabled={props.disable}
        isMulti
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        styles={colourStyles}
      />
      {!!props.error && props.touched && (
        <div style={{ color: 'red', marginTop: '.5rem' }}>{props.error}</div>
      )}
    </div>
  )
}

export default RegionSelect
