import React, { useState, useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import { useAuthState } from '../../../../components/AuthProvider'

const FluMsaApi = ({  cbsaMetroData  }) => {  
  const {
    dataStartDate,
    dataEndDate,
  } = useGeoState()
  const entity = 'flu'
  const { user } = useAuthState()
  const { data: cbsaData, error: cbsaDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'msa'
  )
  const [metros, setMetros] = useState()
  useEffect(() => {
    if (cbsaData !== null && cbsaData !== undefined) {
      if (cbsaData.response_code === 200) {
      // setMetros(cbsaData.response_data)
        cbsaMetroData(cbsaData.response_data)
      } else {
        // setErrorCode(cbsaData.response_code)
        // setErrorMessage(cbsaData.response_message)
        // setErrorSubject(cbsaData.response_subject)
        // seterrorOccured(true)
      }
    }
    //setMetros(cbsaData)
  }, [cbsaData, metros])

  return (
    <>
    
    </>
  )
}

export default FluMsaApi