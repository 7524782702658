import React from 'react'
import moment from 'moment'
import styles from './DashboardHeader.module.scss'
import dataOptions from './dataDisplayOptions'
import getPositiveMethod from './../../../utils/getCountingMethod'
import Tooltip from '../../../components/Tooltip'
import Content from '../../../components/Content'
import RiskIcon from './../../../components/RiskIcon'
const longText = `
This data source is based on internal claims data. The indices below are percentages of outpatient place of service claims coded for Flu2 (P&I, pneumonia or influenza) over total outpatient claims aggregated at weekly temporal resolution. A lag of 2 weeks is considered in this claims database in order to have some stable values.
  `
const HeaderTable = (data, indicator) => {
  let finalValue = data.data[data.indicator]
  return (
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.headerSpan}>{finalValue.indicator}</span>
      </div>
      <span className={styles.dataSpan}>
        <strong>
          {getPositiveMethod(finalValue.changePercentage.toFixed(2))}
        </strong>
        {' %  '}
        <RiskIcon
          size="14px"
          dataValue={finalValue.changePercentage.toFixed(2)}
        />
        {/* {finalValue.changeLevel == 1 ? (
          <Icon size="14px" type="arrow_wtail_bold_up" color="red" />
        ) : (
          ''
        )}
        {finalValue.changeLevel == 2 ? (
          <Icon size="14px" type="arrow_wtail_bold_down" color="green" />
        ) : (
          ''
        )} */}
        {'   '} from {'   '}
        {moment(finalValue.lastWeekDate).format('MMM DD, YYYY')}
      </span>
      <br></br>
      {/* <span>
        Source:<span className={styles.sourceSpan}> {'Claims Data'}</span>
      </span> */}
    </div>
  )
}
const DashboardHeader = (data, nationalStats) => {
  // const { lastweekEndDate } = useGeoState()
  let mapNatsData = null

  var natData = data.nationalStats

  const natsDataForDisplayDate = Object.entries(
    natData[Object.keys(natData).pop()]
  )
  mapNatsData = natsDataForDisplayDate.map((nat) => {
    return {
      value: nat[1],
      name: nat[0],
      ...nat[1],
    }
  })
  var dataWeekDate = mapNatsData[0].value[dataOptions[0].value].weekDate
  var dataLoadDate = mapNatsData[0].value[dataOptions[0].value].loadDate
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>
          Flu Surveillance{' '}
          <Tooltip placement="bottom">
            <Content maxWidth="300px" fontSize="11px">
              <p>{longText}</p>
            </Content>
          </Tooltip>
        </h4>
        <p>
          You can find the latest surveillance data on Flu up through{' '}
          <b>{moment(dataWeekDate).format('MMM DD, YYYY')} </b> (last updated on{' '}
          {moment(dataLoadDate).format('MMM DD, YYYY')})
        </p>
      </div>
      <div className={styles.countPanel}>
        {dataOptions.map((val) => {
          return (
            <HeaderTable data={mapNatsData[0].value} indicator={val.label} />
          )
        })}
      </div>
    </div>
  )
}

export default DashboardHeader
