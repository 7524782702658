import React from 'react'
import styles from './PieChartPanel.module.scss'

import toolTipFormatter from './toolTipFormatter'
import dataDisplayOptions from './dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import PieChart from './../../components/PieChart'
import { startDate, endDate } from './dates'

const PieChartPanel = ({getfilterts}) => {
		
  return (
    <div className={styles.base}>
      <div>
        <span style={{fontSize:'18px', paddingLeft:'5px', color:'#424242'}}>Top 5 Most Popular Vaccines Administered </span>
       <div style={{ borderRight: '1px solid #DADBDC', height:'409px'}}  className={styles.medicalPanel}>
        <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="MedicalVaccine"
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart1"
          pieFor="vaccination"          
          filterId={getfilterts}
          fluFolder='MedicalVaccine'
          fluseason ={getfilterts.season}
          month ={getfilterts.month}
          lob ={getfilterts.lob}
          type={getfilterts.type	}		  		  
        />
               
        <span className={styles.graphSource}>
          Source : <span>Medical Claims Data</span>
        </span>

        </div>
      </div>

      <div>
        <span> &nbsp; </span>
        <div style={{ borderRight: '1px solid #DADBDC', height:'409px'}}  className={styles.pharmacyPanel}>
        <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="VaccinePharamecy"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart2"
          pieFor="vaccination"
          //filterid="VaccinePharamecy-LOB-All_VTYPE-All_FLUSEASON-10_MONTH-All"
          filterId={getfilterts}
          fluFolder='VaccinePharamecy'
          fluseason ={getfilterts.season}
          month ={getfilterts.month}
          lob ={getfilterts.lob}
          type={getfilterts.type	}
        />
        <span className={styles.graphSource}>
          Source : <span>Pharmacy Claims Data</span>
        </span>
        </div>
      </div>

      <div className={styles.classificationPanel}>
        <span> &nbsp;</span>
        <PieChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="VaccineType"
          startDate={startDate}
          endDate={endDate}
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart3"
          pieFor="vaccination"          
          filterId={getfilterts}
          fluFolder='VaccineType'
          fluseason ={getfilterts.season}
          month ={getfilterts.month}
          lob ={getfilterts.lob}
          type={getfilterts.type	}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div>
    </div>
  )
}

export default PieChartPanel
