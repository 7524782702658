import React from "react";
import Layout from '../../components/Layout'
import styles from './AccessAbort.module.scss'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const AccessAbort = () => {

  const { user } = useAuthState()
  

  return (
   
    <ErrorMessage
        errorCode={'401'}
        errorMessage={''}
        errorSubject={'UnAuthorized Access'}
        errorDetails={'Requested host unauthorized to access.'}
      />    

  )

  }

export default AccessAbort;
