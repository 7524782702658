import React from 'react'

import usMsaAll from '../../components/Map/cb_2018_us_cbsa_20m_wkid102004.json'
import { useGeoState } from '../../components/GeoProvider'
import Tag from '../../components/Tag'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

const CbsaStat = ({ cbsa, mapOptions }) => {
  return (
    <div className={statStyles.base}>
      <div
        style={{
          display: 'inline-flex',
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={cbsa.name + ', ' + cbsa.parent}
      >
        <span>
          {cbsa.name.length > 17
            ? cbsa.name.substr(0, 17) + '...'
            : cbsa.name + ', ' + cbsa.parent}
        </span>
      </div>
      <div
        style={{
          display: 'inline-flex',
          width: '40%',
          justifyContent: 'flex-end',
        }}
      >
        <Tag
          dataValue={cbsa}
          levelKey={mapOptions.tagKey}
          valueKey={mapOptions.tagValue}
          showNumbers={true}
        />
      </div>
    </div>
  )
}

const CbsaStats = ({ cbsaData, mapOptions }) => {
  const { displayDate, searchFilter, setSearchFilter } = useGeoState()

  const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])

  const mappedCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
    const filteredCbsa = usMsaAll.features.filter((c) => {
      return c.properties.cbsa.toString() === cbsa[0].toString()
    })

    // This will only take the name out from the name followed by state
    // Example: Minneapolis-St. Paul-Bloomington, MN-WI => Minneapolis-St. Paul-Bloomington

    const cityName =
      filteredCbsa[0] && filteredCbsa[0].properties.name.split(',')[0]

    return { ...cbsa[1], name: cityName, identifier: cbsa[0] }
  })

  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }

  let filteredCbsaData = mappedCbsaData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredCbsaData = mappedCbsaData.filter((cbsa) => {
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredCbsaData = filteredCbsaData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h3>Metro Areas</h3>
          <form>
            <input
              type="text"
              onChange={handleChange}
              placeholder="Filter Metro Areas"
            />
          </form>
        </div>
        <div style={{ padding: '0.5rem 0.5rem' }}>
          <div style={{ display: 'inline-flex', width: '65%' }}>
            <h4>Area</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '35%',
              justifyContent: 'flex-end',
            }}
          >
            <h4>Risk</h4>
          </div>
        </div>
      </div>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {filteredCbsaData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((cbsa) => {
            return (
              <CbsaStat
                cbsa={cbsa}
                key={cbsa.identifier}
                mapOptions={mapOptions}
              />
            )
          })}
      </div>
    </div>
  )
}

export default CbsaStats
