import React from 'react'

import styles from './ButtonList.module.scss'

const ButtonList = ({
  children = null,
  spacing = 1,
  vertical: isVertical,
  ...rest
}) => (
  <div
    {...rest}
    className={`${styles.base} ${rest.className ? rest.className : ''}`}
  >
    <div
      style={{
        margin: `-${spacing / 2}rem`,
        flexDirection: isVertical ? 'column' : 'row',
        marginLeft:'88%',        
        paddingBottom:'10px'
      }}
    >
      {React.Children.map(children, (child) => (
        //<span style={{ padding: `${spacing / 2}rem` }}>{child}</span>
        <span style={{ padding: `6px`, border: '1px solid black' }}>{child}</span>
      ))}
    </div>
  </div>
)

export default ButtonList
