import Icon from '../Icon'

const RiskIcon = ({ dataValue, levelOverride, size = '12px' }) => {
  let arrow_type
  let bgColor = levelOverride

  if (dataValue == 0) {
    bgColor = 'grey'
    arrow_type = 'arrow_wtail_bold_right'
  } else if (dataValue < 0) {
    bgColor = 'green'
    arrow_type = 'arrow_wtail_bold_down'
  } else {
    bgColor = 'red'
    arrow_type = 'arrow_wtail_bold_up'
  }

  return <Icon size={size} type={arrow_type} color={bgColor} />
}

export default RiskIcon
