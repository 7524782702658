import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'

import Spinner from '../Loader/Spinner'

import getBarChartOptions from './getBarChartOptions'

import styles from './BarChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const BarChartDisplay = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  data,
  dataDisplayOptions,
  chartName,
}) => {
 
  var categories = []
  var graph_female_data = []
  var graph_male_data = []
  const finalData = []
  if (chartName === 'chart1') {        
    var res = data    
    res.forEach((data_val) => {
      categories.push( data_val.Agegroup)
      graph_female_data.push(parseInt(data_val.Female_Count)) 
      graph_male_data.push(parseInt(data_val.Male_Count))
    })
  } else if (chartName === 'chart2') {
    
    var reschart2 = JSON.parse(data.response_data)
    reschart2.forEach((data_val) => {
      categories.push(data_val.Agegroup)
      graph_female_data.push(data_val.Female_Count)
      graph_male_data.push(data_val.Male_Count)
    })
  } else if (chartName === 'chart3') {
    
    var reschart3 = JSON.parse(data.response_data)
    reschart3.forEach((data_val) => {
      categories.push(data_val.Vac_Name)
      graph_female_data.push(data_val.Female_Count)
      graph_male_data.push(data_val.Male_Count)
    })
  }else if (chartName === 'sampeChart') {    
    var ressampeChart = data.response_data
    var sampechart= ressampeChart.filter(obj => obj.demographic !== "F" && obj.demographic !== "M" && obj.demographic !== "U" );
    sampechart.forEach((data_val) => {
    categories.push(data_val.demographic)
    graph_female_data.push(data_val.samplePerc)
      // graph_male_data.push(data_val.samplePerc)
    })
  }

  else if (chartName === 'populationChart') {
    
    var respopulationChart = data.response_data;
   var sampechart1= respopulationChart.filter(obj => obj.demographic !== "F" && obj.demographic !== "M" && obj.demographic !== "U" );
   sampechart1.forEach((data_val) => {
      categories.push(data_val.demographic)
      graph_female_data.push(data_val.populationPerc)
      // graph_male_data.push(data_val.populationPerc)
    })
  }

  
if (chartName === 'sampeChart' || chartName === 'populationChart')
{
  finalData.push({ name: 'category', data: categories }) 
  finalData.push({ name: 'male', data: 0 })
  finalData.push({ name: 'Age Distribution', data: graph_female_data })
}
else
{
  finalData.push({ name: 'category', data: categories })
  finalData.push({ name: 'Male', data: graph_male_data })
  finalData.push({ name: 'Female', data: graph_female_data })
}
 
 

  //dummy data
  var hydratedData = []
  const chartOptions = getBarChartOptions(
    toolTipFormatter,
    hydratedData,
    dataDisplayOptions[0].label,
    finalData
  )

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default BarChartDisplay
