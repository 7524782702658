import React from 'react'

import Highcharts from 'highcharts'

import Chart from '../../components/Highcharts/Chart'

import { CurrentEpiWeek } from './transformDataForGrid'

import getLineChartOptions from './lineChartOptions'
import weeks from './seasonChartWeeks.json'

import styles from './FluDetails.module.scss'

const FluSeasonsChart = ({ data, identifier, type }) => {
  let transformedData = new Map()
  Object.entries(data).forEach(([epiWeekDate, epiWeekData]) => {
    let weekMap = epiWeekData[identifier]
    let season = epiWeekData['epi_season']
    let week = epiWeekData['epi_period_week']
    transformedData[season] = {
      ...transformedData[season],
      [week]: { ...weekMap, epi_week: epiWeekDate },
    }
  })
  // TO-DO revisit the code if we can send seasons
  // for chart based on api response
  const data_2021_22 = transformedData['2021-22']
  const data_2020_21 = transformedData['2020-21']
  const data_2019_20 = transformedData['2019-20']
  const data_2018_19 = transformedData['2018-19']
  const data_2017_18 = transformedData['2017-18']
  const data_2016_17 = transformedData['2016-17']
  const data_2015_16 = transformedData['2015-16']
  const data_2014_15 = transformedData['2014-15']
  const currentWeekMinus3Weeks = new Date(CurrentEpiWeek())
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  const chart_2021_22 = []
  const chart_2020_21 = []
  const chart_2019_20 = []
  const chart_2018_19 = []
  const chart_2017_18 = []
  const chart_2016_17 = []
  const chart_2015_16 = []
  const chart_2014_15 = []
  const forecast_values = []
  const high_line = []
  weeks.forEach((week, i) => {
    high_line.push(
      data_2019_20[week]
        ? data_2019_20[week].high_point
        : high_line.length > 0
        ? high_line[i - 1]
        : null
    )
    chart_2020_21.push(
      data_2020_21[week]
        ? data_2020_21[week].actual_value
        : chart_2020_21.length > 0
        ? chart_2020_21[i - 1]
        : null
    )
    chart_2019_20.push(
      data_2019_20[week]
        ? data_2019_20[week].actual_value
        : chart_2019_20.length > 0
        ? chart_2019_20[i - 1]
        : null
    )
    chart_2018_19.push(
      data_2018_19[week]
        ? data_2018_19[week].actual_value
        : chart_2018_19.length > 0
        ? chart_2018_19[i - 1]
        : null
    )
    chart_2017_18.push(
      data_2017_18[week]
        ? data_2017_18[week].actual_value
        : chart_2017_18.length > 0
        ? chart_2017_18[i - 1]
        : null
    )
    chart_2016_17.push(
      data_2016_17[week]
        ? data_2016_17[week].actual_value
        : chart_2016_17.length > 0
        ? chart_2016_17[i - 1]
        : null
    )
    chart_2015_16.push(
      data_2015_16[week]
        ? data_2015_16[week].actual_value
        : chart_2015_16.length > 0
        ? chart_2015_16[i - 1]
        : null
    )
    chart_2014_15.push(
      data_2014_15[week]
        ? data_2014_15[week].actual_value
        : chart_2014_15.length > 0
        ? chart_2014_15[i - 1]
        : null
    )
    if (data_2021_22[week]) {
      if (new Date(data_2021_22[week].epi_week) <= currentWeekMinus3Weeks) {
        chart_2021_22.push(data_2021_22[week].actual_value)

        if (
          new Date(data_2021_22[week].epi_week).getDate() ===
          currentWeekMinus3Weeks.getDate()
        ) {
          forecast_values.push(data_2021_22[week].actual_value)
        } else {
          forecast_values.push(null)
        }
      } else if (
        new Date(data_2021_22[week].epi_week).getTime() ===
        currentWeekMinus3Weeks.getTime()
      ) {
        chart_2021_22.push(data_2021_22[week].actual_value)
        forecast_values.push(data_2021_22[week].actual_value)
      } else {
        forecast_values.push(data_2021_22[week].forecast_value)
        chart_2021_22.push(null)
      }
    } else {
      chart_2021_22.push(null)
      forecast_values.push(null)
    }
  })
  const lineChartData = [
    {
      name: 'forecast',
      data: forecast_values,
      dashStyle: 'dot',
      color: Highcharts.getOptions().colors[8],
    },
    {
      name: '2021-2022',
      data: chart_2021_22,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[7],
    },
    {
      name: '2020-2021',
      data: chart_2020_21,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[0],
    },
    {
      name: '2019-2020',
      data: chart_2019_20,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[1],
    },
    {
      name: '2018-2019',
      data: chart_2018_19,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[2],
    },
    {
      name: '2017-2018',
      data: chart_2017_18,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[3],
    },
    {
      name: '2016-2017',
      data: chart_2016_17,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[4],
    },
    {
      name: '2015-2016',
      data: chart_2015_16,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[5],
    },
    {
      name: '2014-2015',
      data: chart_2014_15,
      dashStyle: 'solid',
      color: Highcharts.getOptions().colors[6],
    },
    {
      name: 'Baseline',
      data: high_line,
      dashStyle: 'solid',
      lineWidth: 1,
      color: 'red',
    },
  ]

  const lineOptions = getLineChartOptions(
    lineChartData,
    weeks,
    identifier,
    type
  )

  return (
    lineOptions && (
      <div className={styles.chartDiv}>
        {/* <div style={{ display: 'flex' }}>
          <h4>Seasonal Influenza Pattern</h4>
          <BaseLine></BaseLine>
        </div> */}

        <div className={styles.container}>
          <div style={{ width: '100%' }}>
            <Chart chartOptions={lineOptions} height={'600px'} />
          </div>
        </div>
      </div>
    )
  )
}

export default FluSeasonsChart
