import moment from 'moment'

export const CurrentEpiWeek = () => {
  return moment()
    .startOf('week')
    .days(+6)
    .format('yyyy-MM-DD')
}
export const CurrentWeek = () => {
  return moment(CurrentEpiWeek(), 'yyyy-MM-DD').week()
}
export const PreviousEpiWeek = () => {
  return moment().startOf('week').days(-1).format('yyyy-MM-DD')
}

export const TransformData = (data) => {
  let transformedData = new Map()

  Object.entries(data).forEach(([epiWeek, epiWeekData]) => {
    const locationCodes = Object.keys(epiWeekData).filter(
      (key) =>
        !['epi_period_week', 'epi_period_year', 'epi_season'].includes(key)
    )
    const season = epiWeekData['epi_season']
    locationCodes.forEach((value) => {
      transformedData[value] = {
        ...transformedData[value],
        [epiWeekData.epi_period_week]: {
          ...epiWeekData[value],
          epi_period_year: epiWeekData.epi_period_year,
          epi_season: season,
          epi_week: epiWeek,
        },
        parent: epiWeekData[value].parent,
      }
    })
  })
  return transformedData
}

