import React from "react";
import DropDownList from "../DropDownList";

import styles from "./DropDownListFilter.module.scss";

const DropDownListFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <DropDownList
      className={styles.base}
      onChange={({ target }) => setFilter(target.value || undefined)}
      value={filterValue}
    >
      <DropDownList.Item value="">Show All</DropDownList.Item>
      {options.map(option => (
        <DropDownList.Item key={option} value={option}>
          {option}
        </DropDownList.Item>
      ))}
    </DropDownList>
  );
};

export default DropDownListFilter;
