import React from 'react'
import styles from './DashboardHeader.module.scss'
import countingMethod from './../../utils/getCountingMethod'
import { useGeoState } from '../../components/GeoProvider'
const CountPanel = ({data, getIstrue}) => {  
  //console.log(getIstrue)  
  var responseData =JSON.parse(data)
  
  var Member_Vaccinated
  var Pharmacy_Percentage
  var Total_Claims
  var Medical_Percentage
   responseData.forEach(items => {
  if (items.Key==='Total Claims')
  {
   Total_Claims =parseFloat(items.Value) 
  }
  if (items.Key==='Member')
  {
    Member_Vaccinated = parseFloat(items.Value )
  }
  if (items.Key==='Medical')
  {
    Medical_Percentage = parseFloat(items.Value)  
   

  }
  if (items.Key==='Pharmacy')
  {    
    Pharmacy_Percentage = parseFloat(items.Value )
    
   
  }
});



const { setVulCount, setClaimsFiled } = useGeoState('')
  
setVulCount(countingMethod(Member_Vaccinated, 'fixed'));
let medical_per, pharmacy_per
medical_per = Pharmacy_Percentage ==null || Pharmacy_Percentage == undefined ? 0:
  Medical_Percentage === 0 || Medical_Percentage === 100
    ? Medical_Percentage.toFixed(0) + '%'
    : Medical_Percentage.toFixed(2) + '%';

pharmacy_per =Pharmacy_Percentage ==null || Pharmacy_Percentage == undefined ? 0:
  Pharmacy_Percentage === 0 || Pharmacy_Percentage === 100
    ? Pharmacy_Percentage.toFixed(0) + '%'
    : Pharmacy_Percentage.toFixed(2) + '%';
    setClaimsFiled(Total_Claims)
  return (
    <div className={styles.countPanel}>
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.position}>Flu Season</span>
      </div>
      <span className={styles.headerSpan}>2024-25</span>
    </div>
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.position}>Vaccinated</span>
      </div>
      <span className={styles.headerSpan}>
        {countingMethod(Member_Vaccinated, 'fixed')}
      </span>
    </div>
    <div
      className={styles.dateWeek}
      style={{ paddingRight: 10, borderRight: '1px solid #c3c0c0' }}
    >
      <div>
        <span className={styles.position}>
          Out of{' '}
          <strong>
            {countingMethod(Total_Claims, 'fixed')}
          </strong>
        </span>
      </div>
      <span className={styles.headerSpan}>Claims Filed</span>
    </div>
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.headerSpan}>{medical_per}</span>
      </div>
      <span className={styles.position}>Medical</span>
    </div>
    <div>
    <span className={styles.positionvs}>VS</span>
    </div>
    <div className={styles.dateWeek}>
      <div>
        <span className={styles.headerSpan}>{pharmacy_per}</span>
      </div>
      <span className={styles.position}>Pharmacy</span>
    </div>
    </div>
    
    )
}

export default CountPanel


