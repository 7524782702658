import React from 'react'
import moment from 'moment'
import styles from './LineChart.module.scss'
import { useGeoState } from '../../../components/GeoProvider'
import LineChart from '../../../components/DateLineChart'
import dataDisplayOptions from './dataDisplayOptions'

const LineGraph = (data, nationalStats) => {
  const { indicatorName, locationName } = useGeoState()
  let updatedDate = null
  var natData = data.nationalStats
  let allForecastData = []
  let allForecastData1 = []
  const values = Object.values(natData)
  const keys = Object.keys(natData)

  const newValues = values.map((val, index) => {
    const valObj = Object.entries(val)
    return { data_val: valObj, data_dt: keys[index] }
  })

  newValues.forEach((dt) => {
    dt.data_val.forEach((f) => {
      allForecastData.push({
        identifier: f[0],
        ...f[1],
        week_dt: dt.data_dt,
      })
    })
  })

  allForecastData.map((val, index) => {
    const valObj = Object.entries(val)
    valObj.forEach((vl) => {
      if (vl[0] === indicatorName) {
        updatedDate = vl[1].loadDate
        allForecastData1.push({
          data_date: vl[1].weekDate,
          data_value: vl[1].pointValue,
        })
      }
    })
  })

  return (
    <div className={styles.base}>
      <div className={styles.chartPanel}>
        <span className={styles.headerSpan}>
          {' '}
          {indicatorName} - {locationName === 'NAT' ? 'US' : locationName}
          {/* {indicatorName} */}
        </span>
        <LineChart
          dataDisplayOptions={dataDisplayOptions}
          data={allForecastData1}
          yaxisName={indicatorName}
        />
        <div className={styles.sourceDiv}>
          <span className={styles.graphSource}>
            Last Data Update :{' '}
            <span>{moment(updatedDate).format('MMM DD, YYYY')}</span>
          </span>
          <span className={styles.graphSource}>
            Source : <span>Claims Data</span>
          </span>
        </div>
      </div>
    </div>
  )
}
export default LineGraph
