import React, {  useState } from 'react'
const CountUser = ({userCount}) => {
  const cardStyles = {
    container: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22.78px',
     line: '32px',
      color: '#323334',
    },
  }
  
  const [getUserCount, setUserCount] = useState(userCount)
  return getUserCount && (  
    <span style={cardStyles.container}>
      {getUserCount} Users
    </span>     
  )
}

export default CountUser
