import React from 'react'
import styles from './DashboardHeader.module.scss'

const DashboardHeader = () => {
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h4>Current Forecasts</h4>
        <p>
          Here are the latest <strong>updates</strong> for you,
        </p>
      </div>
    </div>
  )
}

export default DashboardHeader
