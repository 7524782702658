import React, { useState,useEffect } from 'react'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import styles from './FluVaccination.module.scss'
import DashboardHeader from './DashboardHeader'
import TopGraphPanel from './TopGraphPanel'
import PieChartPanel from './PieChartPanel'
import BottomGraphPanel from './BottomGraphPanel'
import { startDate, endDate, displayDate } from './dates'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
const FluVaccination = () => {
  const [getfilterts, filtersObj] = useState({
    type: 'All',
    lob: 'All',
    season: '12',
    month: 'All',
  })
  const [getMemberVaccinated, setMemberVaccinated] = useState({
    MemberVaccinated: '31.59M',
  })
  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Flu Vaccination',
    indicator_name: 'Flu Vaccination',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  
  useEffect(() => {
    activityLog(logObject)
  }, [1])

  const setMemberVaccinatedData = (e) => {
   
    setMemberVaccinated({ ...getMemberVaccinated, MemberVaccinated: e })
  }

  //#region header filter set as object
  const typeData = (e) => {
    filtersObj({ ...getfilterts, type: e === '' ? 'All' : e })
  }
  const lobData = (e) => {
    filtersObj({ ...getfilterts, lob: e === '' ? 'All' : e })
  }
  const seasonData = (e) => {
    filtersObj({ ...getfilterts, season: e === '' ? 'All' : e })
  }
  const monthData = (e) => {
    filtersObj({ ...getfilterts, month: e === '' ? 'All' : e })
  }

  //#endregion

  return (
    <Layout>
      <Content fontSize={'14px'}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.content}>
              <GeoProvider
                startDate={startDate}
                endDate={endDate}
                displayDate={displayDate}
                vulCount={0}
              >
                <DashboardHeader
                  getfilterts={getfilterts}
                  typeData={typeData}
                  lobData={lobData}
                  seasonData={seasonData}
                  monthData={monthData}
                  setMemberVaccinatedData={setMemberVaccinatedData}
                />
                <TopGraphPanel getfilterts={getfilterts} />
                <PieChartPanel getfilterts={getfilterts} />
                <BottomGraphPanel getfilterts={getfilterts} />
              </GeoProvider>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default FluVaccination
