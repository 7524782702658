import React from 'react'
import DatePicker from 'react-datepicker'
import styles from './DateFilter.module.scss'
import { useGeoState } from '../../../../components/GeoProvider'
import Select from 'react-select'

import Icon from '../../../../components/Icon'

const DateFilter = () => {
    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <div className={styles.calenderDiv} onClick={props.onClick} ref={ref}>
          <Icon size="18px" type="calendar" color="grey" />
          <label className={styles.calenderLabel}>
            {props.value || props.placeholder}
          </label>
          {/* <TodayIcon onClick={props.onClick} /> */}
        </div>
      )
    })
    const {
      setIndicatorName,
      fromDate,
      setFromDate,
      toDate,
      setToDate,     
    } = useGeoState()
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted pink',
        paddingLeft: 10,
        paddingTop: 2,
      }),
    }
    return (
      <div className={styles.base}>       
       <div className={styles.toPanel} >
       <div style={{display:'flex', flexDirection:'row'}}> 
        <div  style={{display:'flex', flexDirection:'row'}}>
        <span className={styles.todateFormat}>FROM DATE</span>
          <div className={styles.dateDiv}>
            <DatePicker
              selected={fromDate} 
              onChange={(date) => setFromDate(date)}
              customInput={<CustomInput />}
            />
          </div>
        </div>           
        <div style={{display:'flex', flexDirection:'row'}}>
          <span className={styles.fromdateFormat}>TO DATE</span>
          <div className={styles.dateDiv}>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              customInput={<CustomInput />}
            />
          </div>
        </div>  
        </div>
        
       </div>
       
        {/* <div className={styles.noPanel}></div> */}
      </div>
    )
  }
  export default DateFilter