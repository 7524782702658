import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import Spinner from '../../components/Loader/Spinner'

import FluSeasonsChart from './FluSeasonsChart'
import seasons from '../../utils/seasons.json'

import styles from './FluDetails.module.scss'
export const FluDetails = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const { loc, area } = useParams()
  
 // const [geoData, setGeoData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [seasonsData, setSeasonsData] = useState([])
  const [activeMetros, setActiveMetros] = useState([])

  let queryType = area

  switch (area) {
    case 'metro':
    case 'city':
      queryType = 'city'
      break
    default:
    // code block
  }

  const getLatestSeasonsData = (data) => {
    let filteredData = {}
    Object.entries(data).forEach(([week, weekData]) => {
      if ([seasons[0]].includes(weekData.epi_season)) {
        filteredData[week] = weekData
      }
    })
    return filteredData
  }

  const fetchData = useCallback(async () => {
    const seasonString = seasons.join(',')
    const res = await fetch(
      `${baseUrl}/flumeter1/hi?seasons=${seasonString}&identifier=${loc}`
    )
    const response = await res.json()
    return response
  }, [loc, baseUrl, queryType])

  const fetchActiveMetroData = useCallback(async () => {
    const res = await fetch(`${baseUrl}/flumeter1/flu/activecbsa`)
    const response = await res.json()
    return response
  }, [baseUrl])

  useEffect(() => {
    const transformGeoData = (data) => {
      const latestSeason = getLatestSeasonsData(data)
      let transformedData = []
      Object.entries(latestSeason).forEach(function (dateObj) {
        const key = dateObj[0]
        const value = dateObj[1][loc]
        transformedData.push({ date: key, ...value })
      })
      //setGeoData(transformedData)
    }

    setIsLoading(true)
    Promise.all([fetchData(), fetchActiveMetroData()]).then((response) => {
      transformGeoData(response[0])
      setActiveMetros(response[1])
      setSeasonsData(response[0])
      setIsLoading(false)
      setHasLoaded(true)
    })
  }, [fetchData, fetchActiveMetroData, loc])

  const [searchCriteria, setSearchCriteria] = React.useState([])

  return (
    <div className={styles.base}>
      {isLoading ? (
        <Spinner />
      ) : hasLoaded ? (
        <>
          <div className={styles.body}>
            <FluSeasonsChart data={seasonsData} identifier={loc} type={area} />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default FluDetails
