import GeoProvider from '../../components/GeoProvider'

import { startDate, endDate, displayDate } from './dates'
import dataDisplayOptions from './dataDisplayOptions'
import FluMap from './FluMap'
import styles from './FluMeter.module.scss'
// import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
const FluMeter = () => {
  // const { user } = useAuthState()

  var url = window.location.href
  var name = 'Flu Meter'
  if (url.indexOf('flumeter') !== -1) name = 'Flu Meter'

  var logObject = {
    dashboard_url: url,
    dashboard_name: name,
    indicator_name: name,
    geo_name: '',
    geo_type: '',
    user_name: 'Guest',
    email: 'guest@optum.com',
  }
  // activityLog(logObject)

  return (
    <div className={styles.base}>
      <div className={styles.heading}>
        <h4>Forecasted Flu Activity</h4>
        <p>1 week out from {displayDate}</p>
      </div>
      <GeoProvider
        startDate={startDate}
        endDate={endDate}
        displayDate={displayDate}
        mapOptions={dataDisplayOptions[0]['options'][0]}
      >
        <FluMap />
      </GeoProvider>
      <div className={styles.footer}>
        <p>
          Information displayed here is for general informational purposes only
          and should not be considered medical, or benefits advice or decisions.
          Always consult your healthcare professional to determine what may be
          right for you and please visit the member website or call the
          toll-free number on the back of your health ID card for information or
          questions regarding claims for benefits.
        </p>
      </div>
    </div>
  )
}

export default FluMeter
