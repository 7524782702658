const FormulaMASE = ({ color = '#000000' }) => {
  return (
    <>
      <p
        style={{
          marginBottom: '0px',
          'line-height': '18px',
          'padding-top': '10px',
        }}
      >
        <strong> MASE : </strong> MASE (Mean Absolute Scaled Error) is the mean
        absolute error of the forecast values, divided by the mean absolute
        error of the in-sample one-step naive forecast. The mean absolute scaled
        error is independent of the scale of the data, so can be used to compare
        forecasts across data sets with different scales. The mean absolute
        scaled error penalizes positive and negative forecast errors equally and
        penalizes errors in large forecasts and small forecasts equally.
        <svg viewBox="0 0 810.000000 183.000000" focusable="false">
          <g
            transform="translate(0.000000,183.000000) scale(0.100000,-0.100000)"
            fill={color}
            stroke="none"
          >
            <path
              d="M5109 1643 c-6 -87 2 -538 10 -546 5 -5 17 -7 25 -4 14 6 16 38 14
279 l-3 273 -22 3 c-13 2 -23 0 -24 -5z"
            />
            <path
              d="M5619 1643 c-5 -77 3 -539 10 -546 5 -5 17 -7 25 -4 14 6 16 38 14
279 l-3 273 -22 3 c-13 2 -23 0 -24 -5z"
            />
            <path
              d="M5297 1421 c-42 -27 -58 -56 -64 -115 -5 -49 -3 -54 22 -74 15 -12
42 -22 60 -22 35 0 75 28 75 54 0 18 -21 21 -37 5 -6 -6 -26 -9 -44 -7 -26 2
-34 8 -34 23 0 16 8 21 45 25 85 11 125 58 88 105 -24 31 -70 33 -111 6z m60
-29 c17 -11 -12 -52 -36 -52 -29 0 -32 12 -9 37 24 25 27 26 45 15z"
            />
            <path d="M5477 1336 c-8 -19 1 -26 35 -26 17 0 28 5 28 14 0 25 -54 36 -63 12z" />
            <path
              d="M5453 1274 c-3 -8 -1 -20 5 -26 7 -7 8 -16 3 -22 -5 -6 -13 -31 -18
-56 -7 -35 -15 -48 -36 -58 -33 -16 -33 -45 1 -50 28 -4 82 47 82 78 0 12 7
44 17 72 15 49 15 52 -2 64 -25 18 -44 18 -52 -2z"
            />
            <path
              d="M3204 1172 c-61 -54 -69 -76 -69 -196 0 -79 4 -111 13 -113 6 -3 12
-12 12 -20 0 -21 69 -83 92 -83 21 0 38 32 21 37 -22 8 -58 49 -75 86 -24 51
-23 143 1 199 17 39 28 52 70 77 18 12 7 41 -16 41 -10 0 -32 -13 -49 -28z"
            />
            <path
              d="M7491 1186 c-10 -12 -8 -17 11 -27 13 -7 34 -24 48 -38 24 -25 25
-32 25 -141 0 -109 -1 -116 -25 -141 -14 -14 -35 -31 -48 -38 -19 -10 -21 -15
-11 -27 20 -24 49 -16 89 27 45 49 69 121 65 199 -4 69 -24 115 -69 163 -36
39 -66 47 -85 23z"
            />
            <path
              d="M90 1161 c-12 -8 -12 -12 3 -29 21 -22 22 -75 3 -91 -8 -6 -17 -32
-21 -57 -3 -25 -16 -61 -27 -81 -25 -41 -14 -55 37 -51 l30 3 -3 75 c-1 41 2
82 7 90 18 27 41 -9 42 -68 2 -66 19 -83 48 -46 25 32 26 47 6 64 -8 7 -15 27
-15 44 -1 17 -6 58 -13 91 -12 59 -14 60 -47 63 -19 1 -42 -2 -50 -7z"
            />
            <path
              d="M311 1141 c-12 -16 -21 -33 -21 -39 0 -5 -7 -15 -15 -22 -8 -7 -15
-20 -15 -30 0 -10 -7 -23 -15 -30 -17 -14 -20 -50 -5 -50 6 0 15 11 20 25 5
14 14 25 20 25 5 0 10 9 10 20 0 11 5 20 10 20 12 0 13 -37 1 -44 -4 -3 -11
-26 -13 -51 -3 -25 -13 -54 -23 -65 -27 -30 -12 -50 35 -50 29 0 42 5 47 17 4
9 1 19 -5 21 -14 4 -16 78 -3 86 5 3 12 30 15 59 4 29 16 66 26 82 36 55 -31
80 -69 26z"
            />
            <path
              d="M582 1143 c-11 -16 -23 -38 -26 -50 -4 -13 -12 -23 -17 -23 -5 0 -9
-5 -9 -11 0 -17 -69 -130 -99 -162 -22 -24 -23 -29 -11 -37 17 -11 51 -13 74
-4 13 5 15 14 10 39 -5 27 -2 35 16 45 31 17 84 9 88 -13 2 -10 -4 -24 -14
-31 -11 -9 -15 -19 -10 -31 5 -14 14 -17 47 -12 51 7 55 10 35 39 -13 18 -16
49 -16 144 0 102 -3 123 -16 128 -26 10 -32 7 -52 -21z m21 -115 c6 -44 -26
-63 -63 -37 -12 8 -12 12 -2 16 7 3 17 16 22 29 14 35 37 30 43 -8z"
            />
            <path
              d="M789 1141 c-44 -45 -40 -81 16 -128 54 -44 63 -67 39 -94 -18 -20
-69 -26 -79 -9 -3 6 -17 10 -31 10 -19 0 -24 -5 -24 -25 0 -31 26 -45 82 -45
40 0 82 19 100 44 4 6 8 32 8 57 0 44 -3 50 -47 87 -41 35 -46 43 -37 59 14
23 50 28 80 13 30 -17 54 -6 54 24 0 27 -21 36 -88 36 -35 0 -49 -6 -73 -29z"
            />
            <path
              d="M1028 1163 c-22 -6 -23 -17 -2 -34 12 -10 14 -23 9 -58 -4 -25 -10
-50 -15 -56 -4 -5 -11 -33 -15 -62 -4 -30 -13 -54 -21 -57 -19 -7 -18 -32 2
-40 9 -3 51 -6 95 -6 70 0 80 2 93 23 43 66 19 112 -29 55 -20 -24 -35 -31
-63 -32 -36 -1 -37 0 -34 34 3 40 26 80 47 80 7 0 20 -7 29 -15 23 -23 33 -18
39 22 8 46 -6 63 -31 40 -20 -18 -48 -22 -56 -8 -11 18 16 71 39 77 37 9 60 1
63 -23 4 -26 24 -44 39 -35 13 8 20 72 9 90 -7 12 -157 16 -198 5z"
            />
            <path
              d="M1980 1060 c-11 -11 -20 -27 -20 -35 0 -18 31 -20 49 -2 12 10 13 8
6 -8 -4 -11 -13 -41 -19 -67 -6 -25 -14 -50 -19 -54 -13 -14 -7 -33 13 -39 29
-9 50 12 50 51 0 18 11 54 25 80 14 26 24 48 23 49 -2 1 -16 11 -32 23 -36 27
-50 28 -76 2z"
            />
            <path
              d="M2097 1073 c-13 -13 -7 -32 13 -38 11 -3 20 -14 20 -23 0 -19 -22
-106 -34 -133 -5 -12 -1 -18 15 -22 31 -8 49 9 49 48 0 35 20 87 45 120 14 17
17 18 31 4 13 -13 13 -23 4 -61 -6 -24 -10 -60 -8 -79 2 -28 7 -35 29 -37 20
-2 32 4 48 27 12 17 17 32 12 36 -5 3 -14 -3 -20 -12 -17 -29 -31 3 -19 41 21
63 22 109 2 123 -27 20 -65 16 -71 -7 -3 -11 -11 -20 -18 -20 -6 0 -20 9 -30
20 -18 19 -55 27 -68 13z"
            />
            <path
              d="M2450 1073 c-41 -15 -90 -95 -90 -147 0 -37 43 -76 84 -76 45 0 113
54 83 65 -9 4 -33 0 -53 -10 -28 -13 -38 -14 -50 -5 -31 26 -10 50 43 50 40 0
83 36 83 70 0 49 -45 73 -100 53z m55 -43 c8 -13 -31 -50 -53 -50 -32 0 -34
13 -6 37 29 24 49 29 59 13z"
            />
            <path
              d="M2641 1051 c-38 -37 -51 -69 -51 -122 0 -60 47 -97 89 -70 19 12 6
41 -18 41 -28 0 -35 34 -15 75 20 43 48 65 75 58 25 -7 24 -29 -2 -83 -18 -37
-19 -46 -8 -67 19 -36 58 -41 80 -12 23 29 24 49 4 49 -8 0 -15 -4 -15 -10 0
-5 -4 -10 -10 -10 -13 0 -13 25 0 48 8 15 31 115 27 118 -1 1 -30 5 -63 9 -59
6 -63 6 -93 -24z"
            />
            <path
              d="M2857 1062 c-33 -36 -12 -71 22 -39 12 10 13 8 6 -8 -4 -11 -11 -39
-16 -63 -5 -23 -13 -51 -19 -62 -8 -15 -6 -21 11 -30 29 -16 49 3 50 49 1 48
38 116 64 116 22 0 21 3 9 -107 -7 -56 -6 -57 20 -64 21 -5 33 -1 52 17 27 25
32 49 9 49 -8 0 -15 -5 -15 -11 0 -6 -6 -9 -12 -6 -14 4 -12 46 3 61 5 6 9 29
9 53 0 32 -5 45 -19 53 -31 16 -57 12 -73 -12 l-14 -22 -22 22 c-27 27 -44 28
-65 4z"
            />
            <path
              d="M1403 1034 c-10 -27 18 -34 147 -34 129 0 157 7 147 34 -5 14 -27 16
-147 16 -120 0 -142 -2 -147 -16z"
            />
            <path
              d="M3283 1004 c-3 -8 -1 -20 4 -25 5 -5 816 -8 2109 -7 2091 3 2099 3
2099 23 0 20 -8 20 -2103 23 -1903 2 -2103 1 -2109 -14z"
            />
            <path
              d="M1411 951 c-8 -5 -11 -16 -8 -25 5 -14 27 -16 147 -16 120 0 142 2
147 16 3 9 0 20 -8 25 -18 11 -260 11 -278 0z"
            />
            <path
              d="M3800 902 c-14 -9 -36 -27 -50 -40 -21 -19 -23 -24 -10 -32 10 -6 20
-4 32 7 10 9 21 13 24 10 4 -4 7 -46 7 -94 l0 -88 -31 -10 c-18 -5 -32 -14
-32 -21 0 -16 34 -24 113 -24 52 0 69 4 74 15 7 20 -1 28 -30 31 -12 1 -25 3
-29 3 -5 1 -8 60 -8 131 0 117 -2 130 -17 129 -10 0 -29 -8 -43 -17z"
            />
            <path
              d="M4808 709 c-19 -10 -25 -54 -9 -63 5 -3 14 1 21 9 14 17 50 21 50 6
0 -6 -4 -12 -9 -15 -5 -3 -12 -22 -16 -43 -4 -20 -15 -48 -26 -61 -23 -30 -11
-52 30 -52 36 0 53 18 40 42 -6 10 -7 29 -4 41 27 101 36 113 60 80 15 -20 31
-11 39 23 5 18 3 30 -6 35 -18 12 -149 10 -170 -2z"
            />
            <path
              d="M4478 683 c-10 -3 -18 -7 -18 -9 0 -5 69 -106 112 -165 23 -30 15
-55 -31 -109 -17 -19 -34 -40 -38 -45 -5 -6 -17 -21 -28 -34 -29 -34 -8 -41
122 -41 144 0 160 6 161 59 0 23 3 45 6 50 3 5 -1 14 -10 21 -19 16 -34 3 -50
-43 -10 -30 -14 -32 -75 -38 -78 -8 -97 -1 -72 27 54 60 93 115 93 131 0 11
-13 35 -30 53 -16 18 -30 37 -30 42 0 4 -9 17 -21 29 -31 31 -12 42 60 34 53
-6 62 -10 67 -31 5 -19 13 -24 36 -24 30 0 31 1 25 41 -3 22 -8 44 -12 47 -7
8 -240 12 -267 5z"
            />
            <path
              d="M5439 683 c0 -4 -1 -93 -1 -197 1 -190 5 -215 37 -203 13 6 15 31 13
204 -3 197 -3 198 -25 201 -13 2 -23 0 -24 -5z"
            />
            <path
              d="M7389 683 c0 -4 -1 -93 -1 -197 1 -190 5 -215 37 -203 13 6 15 31 13
204 -3 197 -3 198 -25 201 -13 2 -23 0 -24 -5z"
            />
            <path
              d="M5556 554 c-9 -8 -16 -25 -16 -36 0 -16 5 -19 24 -14 20 5 24 1 30
-25 3 -17 6 -44 6 -58 0 -15 5 -32 12 -39 17 -17 -33 -78 -72 -86 -43 -8 -38
-40 7 -44 28 -3 37 4 87 64 31 38 56 74 56 80 0 7 6 17 13 21 20 13 70 121 63
138 -6 17 -61 21 -72 4 -3 -6 -1 -22 7 -35 10 -19 10 -27 1 -36 -7 -7 -12 -25
-12 -40 0 -18 -5 -28 -14 -28 -20 0 -25 9 -36 72 -14 71 -49 97 -84 62z"
            />
            <path
              d="M6511 540 c-19 -27 -20 -31 -5 -37 9 -3 20 0 25 8 10 17 21 5 36 -41
26 -80 26 -114 0 -142 -14 -14 -35 -28 -48 -30 -16 -2 -25 -11 -27 -25 -3 -20
1 -23 30 -23 27 0 40 8 68 42 78 93 165 241 156 264 -6 17 -57 19 -73 3 -8 -8
-8 -19 0 -39 9 -23 7 -36 -9 -69 -10 -23 -23 -39 -29 -38 -5 2 -13 26 -16 53
-6 54 -17 85 -33 96 -23 16 -53 7 -75 -22z"
            />
            <path
              d="M3284 496 c-3 -8 -3 -19 0 -25 4 -5 216 -8 548 -7 536 1 543 1 543
21 0 20 -8 20 -543 23 -468 2 -543 0 -548 -12z"
            />
            <path
              d="M6063 495 c-11 -31 12 -36 153 -33 131 3 139 4 139 23 0 19 -8 20
-143 23 -123 2 -143 0 -149 -13z"
            />
            <path
              d="M7188 455 c-35 -30 -36 -49 -3 -43 24 5 25 4 25 -53 0 -51 -3 -59
-19 -59 -22 0 -45 -25 -36 -40 7 -11 113 -14 139 -4 24 9 19 31 -9 38 -23 6
-24 10 -27 94 -3 78 -5 87 -23 89 -11 2 -32 -8 -47 -22z"
            />
            <path
              d="M5220 430 c-35 -35 -17 -78 19 -46 23 21 47 20 55 0 7 -18 -6 -36
-62 -91 -50 -47 -41 -67 31 -71 63 -3 87 8 87 40 0 17 -5 19 -45 12 -25 -3
-45 -3 -45 0 0 3 17 22 39 41 52 47 59 99 17 127 -22 15 -76 8 -96 -12z"
            />
            <path
              d="M5801 419 c-18 -15 -21 -23 -13 -31 15 -15 16 -55 2 -63 -17 -11 -11
-54 9 -65 33 -17 72 -12 78 10 3 11 9 20 14 20 5 0 9 7 9 15 0 18 -12 19 -28
3 -17 -17 -35 3 -30 33 2 18 11 25 33 29 42 7 44 34 4 54 -41 20 -49 20 -78
-5z"
            />
            <path
              d="M6786 425 c-10 -8 -24 -16 -30 -18 -8 -3 -7 -11 2 -26 10 -17 11 -30
2 -56 -16 -49 1 -77 43 -73 22 2 36 11 46 30 19 32 11 44 -18 27 -26 -17 -33
-9 -25 29 4 22 12 32 24 32 51 0 69 35 25 46 -14 3 -25 10 -25 15 0 13 -22 10
-44 -6z"
            />
            <path
              d="M3326 413 c-9 -10 -36 -72 -36 -84 0 -21 40 -7 60 21 36 50 73 34 47
-20 -8 -16 -18 -48 -22 -71 -9 -53 -29 -102 -49 -118 -27 -22 -7 -41 43 -41
25 0 53 4 61 10 12 7 11 12 -5 30 -17 19 -18 26 -6 73 6 29 15 74 20 100 10
65 51 91 59 38 8 -54 62 -27 62 31 l0 28 -99 0 c-55 0 -106 2 -113 5 -8 3 -18
2 -22 -2z"
            />
            <path
              d="M4965 410 c-4 -6 -1 -18 6 -26 9 -11 31 -14 98 -12 78 3 86 5 86 23
0 18 -8 20 -92 23 -58 2 -94 -1 -98 -8z"
            />
            <path
              d="M4220 392 c-14 -9 -36 -27 -50 -40 -21 -19 -23 -24 -10 -32 10 -6 20
-4 32 7 10 9 21 13 24 10 4 -4 7 -46 7 -94 l0 -88 -31 -10 c-34 -10 -42 -29
-16 -39 9 -3 49 -6 90 -6 58 0 76 3 81 15 7 20 -1 28 -30 31 -12 1 -25 3 -29
3 -5 1 -8 59 -8 131 0 117 -2 130 -17 129 -10 0 -29 -8 -43 -17z"
            />
            <path
              d="M4828 389 c-23 -17 -26 -24 -18 -40 7 -12 8 -31 2 -52 -5 -18 -5 -43
-1 -55 13 -33 64 -27 87 10 19 32 12 44 -17 27 -26 -17 -35 -8 -26 29 5 24 13
31 41 36 40 8 45 31 9 40 -14 4 -25 11 -25 16 0 16 -23 11 -52 -11z"
            />
            <path
              d="M6914 375 c-11 -29 11 -36 102 -33 81 3 89 5 89 23 0 18 -8 20 -93
23 -77 2 -93 0 -98 -13z"
            />
            <path
              d="M4964 315 c-11 -29 11 -36 102 -33 81 3 89 5 89 23 0 18 -8 20 -93
23 -77 2 -93 0 -98 -13z"
            />
            <path
              d="M3693 255 c-11 -31 12 -36 153 -33 131 3 139 4 139 23 0 19 -8 20
-143 23 -123 2 -143 0 -149 -13z"
            />
          </g>
        </svg>
      </p>

      <p style={{ marginBottom: '0px' }}>
        where the numerator ej is the forecast error for a given period (with J,
        the number of forecasts), defined as the actual value (yj) minus the
        forecast value (Fj) for that period: ej = yj − Fj, and the denominator
        is the mean absolute error of the one-step” naïve forecast method” on
        the training set (here defined as t = 1..T), which uses the actual value
        from the prior period as the forecast: Ft = yt−1. The mean absolute
        scaled error can be easily interpreted, as values greater than one
        indicate that in-sample one-step forecasts from the naïve method perform
        better than the forecast values under consideration.
      </p>
    </>
  )
}

export default FormulaMASE
