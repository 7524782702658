import React, { useEffect, useState } from 'react'

import Flex from '../../../../components/Flex'
import { useAuthState } from '../../../../components/AuthProvider'
import Content from '../../../../components/Content'
import DataTable, { DropDownListFilter } from '../../../../components/DataTable'
import Spinner from '../../../../components/Loader/Spinner'
import moment from 'moment'
import styles from './UserActivityCount.module.scss'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'

const UserActivityCount = () => {
  const { fromDate, toDate } = useGeoState()
 
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const userApiUrl = `${clientConfigApiDomain}/admin/usersactivitystats`
  const { user } = useAuthState()

  const [activitydetails, setActivityDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const entity = 'admin'

  const { data: activityData, error: activityDataError } = useGeoFetch(
  moment(fromDate).format('YYYY-MM-DD'),
  moment(toDate).format('YYYY-MM-DD'),
  entity,
  'usersactivitystats'
)
useEffect(() => {
  if (activityData !== undefined) {
    setActivityDetails(activityData.stats_count)
  }
}, [activityData])

  // useEffect(() => {
  //   const getActivityDetails = async () => {
  //     const response = await fetch(`${userApiUrl}?endDate=${moment(fromDate).format('YYYY-MM-DD')}&startDate=${moment(toDate).format('YYYY-MM-DD') }`)
  //     const data = await response.json()
  //     setActivityDetails(data.stats_count)
  //   }
  //   if (!hasLoaded) {

  //     setIsLoading(true)
  //     getActivityDetails()
  //     setIsLoading(false)
  //     setHasLoaded(true)
  //   }
  // }, [
  //   userApiUrl,
  //   fromDate,
  //   toDate,
  //   setIsLoading,
  //   setHasLoaded,
  //   hasLoaded,
  //   user.clients,
  //   user.role,
  //   user.orgIds,
  // ])  

  return (
    <div className={styles.base}>
      <div className={`${styles.firstPanel}`}>
        <div className={styles.dateWeek}>
        <div>          
            <span className={styles.position}>Total Hits</span>
          </div>
          <h3 className={styles.valueFormat} style={{ padding: 0, marginBottom: 0 }}>
            {activitydetails.total_hit_count}
          </h3>          
        </div>
      </div>
      <div className={`${styles.secondPanel}`}>
        <div className={styles.dateWeek}>
        <div>
            <span className={styles.position}> Unique Hits</span>
          </div>
          <h3 className={styles.valueFormat} style={{ padding: 0, marginBottom: 0 }}>
            {activitydetails.unique_hit_count}
          </h3>          
        </div>
      </div>
      <div className={`${styles.thirdPanel}`}>
        <div className={styles.dateWeek}>
        <div>
            <span className={styles.position}>UHG User's Count</span>
          </div>
          <h3 className={styles.valueFormat} style={{ padding: 0, marginBottom: 0 }}>
            {activitydetails.uhg_users_count}
          </h3>          
        </div>
      </div>
      <div className={`${styles.fourthPanel}`}>
        <div className={styles.dateWeek}>
        <div>
            <span className={styles.position}>Other User's Count</span>
          </div>
          <h3 className={styles.valueFormat} style={{ padding: 0, marginBottom: 0 }}>
            {activitydetails.other_users_count}
          </h3>          
        </div>
      </div>      
    </div>
  )
}

export default UserActivityCount
