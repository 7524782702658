import React from 'react'
import styles from './DashboardHeader.module.scss'

const DashboardHeader = () => {
  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h2>About Models</h2>
        <span>
          Here you can find information on the Accuracy of the Flu and COVID-19
          Forecasting models.
        </span>
      </div>
    </div>
  )
}

export default DashboardHeader
