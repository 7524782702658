const colorAxisCasesWeeklyHrr = {
  tickInterval: 222,
  min: 0,
  max: 2000,
  // stops: [
  //   [0, '#aaa'],
  //   [0.0001, '#fff9bc'],
  //   [0.000757576        , '#fff9bc'],
  //   [0.001893939        , '#E0F500'],
  //   [0.003977273        , '#FCB100'],
  //   [0.007386364        , '#FC8200'],
  //   [0.01344697        , '#FA4F00'],
  //   [0.026136364        , '#CC0000'],
  //   [0.063636364        , '#960000'],
  //   [1, '#6E0000'],
  // ],
  stops: [
    [0, '#aaa'],
    [0.0001, '#E0E8F3'],
    [0.017508588, '#D4E2F4'],
    [0.037758588, '#BAD4F7'],
    [0.060474353, '#91BBF5'],
    [0.087417176, '#75ABF5'],
    [0.122272529, '#4C93F3'],
    [0.175138529, '#2F7AE1'],
    [0.251526353, '#226CD3'],
    [0.391565118, '#08397E'],
    [1, '#04224C'],
  ],
}

export default colorAxisCasesWeeklyHrr
